import React, { useState } from "react";
import { FaQuestionCircle } from "react-icons/fa";

const InfoTooltip = ({ message, className }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <span
            className={`relative cursor-pointer ${className}`}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
        >
            <FaQuestionCircle className="w-4 h-4" />
            {showTooltip && (
                <div className="absolute left-[-10px] top-5 w-[300px] rounded-lg bg-[#19183A] p-3 text-xs text-white shadow-lg z-10">
                    <p>{message}</p>
                </div>
            )}
        </span>
    );
};

export default InfoTooltip;
