export const ENDPOINTS = {
    getAllBanks: (currency = "NGN") => `/v1/wallets/get_all_banks/all?currency=${currency}`,
    verifyAccount: () => `/v1/wallets/verifyBankDetail`,
    getTransactionFee: "/v1/wallets/transferFees",
    transferFunds: "/v1/wallets/transfer",
    internationalTransfer: "/v1/wallets/transfer_request",
    verifyPin: "/v1/users/verify-pin",
    saveBeneficiary: "/v1/beneficiary",
    getBeneficiary: (currency) => `/v1/beneficiary${currency ? `?currency=${currency}` : ""}`,
    getInvoiceSummary: `/v1/invoice/summary`,
    getAllInvoice: `/v1/invoice/all`,
    getAllUsers: (type = "all") => `/v1/flex/users${type === "all" ? "" : `/${type}`}`,
    getSingleUser: (id) => `v1/flex/list-user/${id}`,
    getStats: "v1/flex/order/stat",
    updateLinkOrders: "/v1/flex/order/update/link",
    createBusiness: `/v1/business/create`,
    uploadBusinessDocuments: (businessId, businessType) =>
        `/v1/business/upload-business-documents/${businessId}?businessType=${businessType}`,
};
