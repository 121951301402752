import Modal from "react-bootstrap/Modal";
import { countrycodelist } from "../constants/countryList";
import successmark from "../asset/images/Checkbox.svg";
import searchIcon from "../asset/images/search-normal.svg";

import { useEffect, useState } from "react";
import debounce from "lodash.debounce";
import axios from "axios";

const AddressModal = (props) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [search, setSearch] = useState("");
    const [allAddress, setAlldress] = useState([]);
    const checkclick = (data, index) => {
        props.setselectedcounty(data);
        setSelectedIndex(index);
        props.setshow(false);
    };
    const getAddress = debounce(async () => {
        const data = { input: search };
        await axios
            .post(`v1/utility/get-address-autocomplete`, data)
            .then((res) => {
                setAlldress(res.data.data.predictions);
            })
            .catch((e) => {
                console.log(e);
            });
    }, 500);

    return (
        <Modal
            show={props.show}
            animation={false}
            className="modal_new_lg"
            onHide={() => props.setshow(false)}
        >
            <Modal.Header className="border-0">
                <span
                    className="material-icons"
                    onClick={() => props.setshow(false)}
                    style={{
                        cursor: "pointer",
                    }}
                >
                    close
                </span>
            </Modal.Header>
            <Modal.Body>
                <div className="h-100 ">
                    <h4 style={{ fontSize: "1rem" }}>Choose Address</h4>
                    <div
                        className=" position-relative mt-4"
                        style={{
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                            padding: "8px 16px",
                            background: "#ECEFF3",
                            borderRadius: "8px",
                            height: "56px",
                        }}
                    >
                        <img src={searchIcon} alt="" width={24} height={24} />
                        <input
                            type="search"
                            className="w-100  border-0 !shadow-none"
                            style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                lineHeight: "19.6px",
                                color: "#667085",
                                borderRadius: "8px",
                                background: "inherit",
                                border: "none",
                                width: "100%",
                                outline: "none",
                            }}
                            value={search}
                            onChange={(e) => {
                                getAddress();
                                setSearch(e.target.value);
                            }}
                            placeholder="Search Address"
                        />
                    </div>
                    <div
                        className="card  my-3 border-0 mycardscroll "
                        style={{
                            background: "#ffff",
                            overflow: "auto",
                            //  height: "calc(50vh - 200px)",
                        }}
                    >
                        {" "}
                        {allAddress.map((countrycode, index) => (
                            <div className="card-body p-1 " key={index}>
                                <div
                                    className="p-2  d-flex gap-3 align-items-center"
                                    onClick={() => checkclick(countrycode, index)}
                                    style={{
                                        flexDirection: "row",
                                        flexShrink: "1",
                                        alignItems: "center",
                                        background: selectedIndex === index ? "#F4F0FF" : "",
                                        width: "100%",
                                        borderRadius: "8px",
                                    }}
                                >
                                    <img
                                        src={countrycode.image}
                                        style={{ borderRadius: "100%" }}
                                        alt=""
                                        width={20}
                                    />
                                    <div
                                        className="d-flex justify-content-between w-100"
                                        style={{
                                            overflow: "hidden",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div className="d-grid">
                                            <span
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: "600px",
                                                    lineHeight: "24px",
                                                    letterSpacing: "2%",
                                                }}
                                            >
                                                {" "}
                                                {countrycode.description}
                                            </span>
                                        </div>
                                        {selectedIndex === index && (
                                            <img src={successmark} alt="" />
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}{" "}
                        {allAddress.length === 0 && (
                            <div
                                style={{
                                    textAlign: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    padding: "40px",
                                }}
                            >
                                Please search your address...
                            </div>
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AddressModal;
