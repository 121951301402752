import { Fragment, useState } from "react";
import search from "../asset/images/search-normal.svg";
import invoice from "../asset/images/invoice.svg";
import addrecrecipt from "../asset/images/orderlogobox.svg";
import InvoiceDetails from "../component/invoicedetails";

import longinvoice from "../asset/images/orderlogom.svg";
import { useNavigate } from "react-router-dom";
import DeleteFlexInvoice from "../component/DeleteFlexInvoice";
import axios from "axios";
import { useEffect } from "react";
import ComponentLoader from "../component/componentloader";
import NGNcurrency from "../asset/images/Currency.svg";
import US from "../asset/images/US.png";
import CurrencyModal from "../component/currencyModal";
import euro from "../asset/images/EU.svg";
import pounds from "../asset/images/GB.svg";
import currencyPairCheck from "../utilities/currencyPairCheck";
import AmountCheck from "../component/amountCheck";
import { useSelector } from "react-redux";
const OrderInvoice = (props) => {
    const [searchbank, setsearchbank] = useState("");
    const [totalinvoice, settotalinvoice] = useState(0);
    const [inputSearch, setinputsearch] = useState("");
    const [show, setshow] = useState(false);
    const [showModal3, setshowModal3] = useState(false);
    const [loader, setload] = useState(false);
    const navigate = useNavigate();
    const [deletes, setdelete] = useState(false);
    const [tenureday, settenureday] = useState("NGN");
    const [selectedindex, setselectedindex] = useState(0);
    const [showcurrencymodal, setshowcurrencymodal] = useState(false);
    const selectorbusinesss = useSelector((state) => state.business);

    const businessIdHeaderConfig = {
        headers: { "x-business-id": selectorbusinesss?._id },
    };

    const filteredData = props.invoice.filter(
        (item) =>
            (item.totalAmount && item.totalAmount.toString().includes(inputSearch)) ||
            (item.title && item.title.toLowerCase().includes(inputSearch.toLowerCase()))
    );

    const filterBuyer = filteredData.filter(
        (item) =>
            item.status?.toLowerCase()?.includes(searchbank?.toLowerCase()) ||
            item.orderStatus?.toLowerCase()?.includes(searchbank?.toLowerCase())
    );

    // console.log(filterBuyer);
    const orderstatus = ["All", "Pending", "Processing", "Completed", "Decline"];

    filterBuyer.sort((a, b) =>
        a.createdAt.localeCompare(b.createdAt, "es", { sensitivity: "base" })
    );
    let data = filterBuyer.reduce((r, e) => {
        let alphabet = new Date(e.createdAt).toDateString();

        if (!r[alphabet])
            r[alphabet] = {
                alphabet,
                record: [],
            };

        r[alphabet].record.push(e);
        return r;
    }, {});
    let result = Object.values(data);
    useEffect(() => {
        const fetchSummary = () => {
            axios
                .get(`/v1/flex/order/summary?currency=${tenureday}`, businessIdHeaderConfig)
                .then((res) => {
                    settotalinvoice(res.data.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        };
        selectorbusinesss?._id && fetchSummary();
    }, [selectorbusinesss]);

    const [selecteditem, setselecteditem] = useState([]);
    const deleteInvoiceFlex = async () => {
        setload(true);
        await axios
            .delete(`/v1/invoice/${selecteditem._id}`, businessIdHeaderConfig)
            .then((res) => {
                const updatedHero = props.invoice.filter((item) => item._id !== selecteditem._id);
                props.setinvoices(updatedHero);
                setdelete(true);
                setshowModal3(false);
                setload(false);
                setshow(false);
            })
            .catch((e) => {
                console.log(e);
                setload(false);
                setshowModal3(false);
            });
    };
    const getInvoiceSummary = async (value) => {
        await axios
            .get(`/v1/flex/order/summary?currency=${value}`)
            .then((res) => {
                settotalinvoice(res.data.data);
            })
            .catch((e) => {
                console.log(e);
            });
    };
    return (
        <div
            className="flex-m-invoice"
            style={{
                display: "flex",
                gap: "136px",
            }}
        >
            <InvoiceDetails
                show={show}
                setshow={setshow}
                selecteditem={selecteditem}
                setshow3={setshowModal3}
                deletes={deletes}
            />
            <DeleteFlexInvoice
                loader={loader}
                show={showModal3}
                setshow={setshowModal3}
                deletedBuyer={deleteInvoiceFlex}
            />
            <CurrencyModal
                show={showcurrencymodal}
                setshow={setshowcurrencymodal}
                tenureday={tenureday}
                settenureday={settenureday}
                getInvoiceSummary={getInvoiceSummary}
            />
            <div
                style={{
                    height: "100%",
                    background: "#fff",
                    minHeight: "977px",
                    padding: "32px",
                    borderRight: "1px solid #E7E9FB",
                    borderRadius: "24px 0px 0px 0px",
                }}
            >
                <div
                    className="long-flex-f"
                    style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "56px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "24px",
                        }}
                    >
                        <div className="currency_switch" onClick={() => setshowcurrencymodal(true)}>
                            <div className="currency_board">
                                <img
                                    src={
                                        tenureday === "NGN"
                                            ? NGNcurrency
                                            : tenureday === "GBP"
                                            ? pounds
                                            : tenureday === "USD"
                                            ? US
                                            : euro
                                    }
                                    alt=""
                                    style={{
                                        width: "20px",
                                        height: "20%",
                                    }}
                                />
                                <span className="currency_title">{tenureday}</span>
                                <span className="currency_title">
                                    {tenureday === "NGN"
                                        ? "Naira"
                                        : tenureday === "GBP"
                                        ? "British Pounds"
                                        : tenureday === "USD"
                                        ? "Dollar"
                                        : "Euro"}
                                </span>
                            </div>
                            <span className="material-icons">expand_more</span>
                        </div>
                        <div
                            style={{
                                borderRadius: "8px",
                                padding: "10px",
                                display: "flex",
                                background: "#EBE4FF",
                                justifyContent: "space-between",
                                flexDirection: "column",
                                height: "144px",
                                minWidth: "384px",
                            }}
                        >
                            <div
                                className="text-c-flex"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "4px",
                                }}
                            >
                                <span
                                    className="small-c"
                                    style={{
                                        color: "#7A7A87",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        lineHeight: "19.6px",
                                        letterSpacing: "2%",
                                    }}
                                >
                                    Incoming
                                </span>
                                <h1
                                    style={{
                                        fontWeight: "700",
                                        fontSize: "2rem",
                                        lineHeight: "40px",
                                    }}
                                >
                                    {currencyPairCheck(tenureday)}
                                    {AmountCheck(
                                        totalinvoice?.incomingOrders
                                            ? totalinvoice?.incomingOrders?.totalAmount
                                            : 0
                                    )}
                                </h1>
                            </div>
                            <span
                                style={{
                                    color: "#344054",
                                    fontWeight: "500",
                                    lineHeight: "19.6px",
                                    fontSize: "14px",
                                }}
                            >
                                {totalinvoice?.incomingOrders?.count === 0
                                    ? "No Order"
                                    : totalinvoice?.incomingOrders?.count + " " + "Order(s)"}
                            </span>
                        </div>
                        <div
                            style={{
                                borderRadius: "8px",
                                padding: "10px",
                                display: "flex",
                                background: "#FFE7D5",
                                justifyContent: "space-between",
                                flexDirection: "column",
                                height: "144px",
                                minWidth: "384px",
                            }}
                        >
                            <div
                                className="text-c-flex"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "4px",
                                }}
                            >
                                <span
                                    className="small-c"
                                    style={{
                                        color: "#7A7A87",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        lineHeight: "19.6px",
                                        letterSpacing: "2%",
                                    }}
                                >
                                    Outgoing
                                </span>
                                <h1
                                    style={{
                                        fontWeight: "700",
                                        fontSize: "2rem",
                                        lineHeight: "40px",
                                    }}
                                >
                                    {currencyPairCheck(tenureday)}
                                    {AmountCheck(
                                        totalinvoice?.normalOrders
                                            ? totalinvoice?.normalOrders?.totalAmount
                                            : 0
                                    )}
                                </h1>
                            </div>
                            <span
                                style={{
                                    color: "#344054",
                                    fontWeight: "500",
                                    lineHeight: "19.6px",
                                    fontSize: "14px",
                                }}
                            >
                                {totalinvoice?.normalOrders?.count === 0
                                    ? "No Order"
                                    : totalinvoice?.normalOrders?.count + " " + "Order(s)"}
                            </span>
                        </div>
                    </div>
                    {props.invoice.length > 0 && (
                        <button
                            style={{
                                background: "#6F00FF",
                                color: "#fff",
                                borderRadius: "8px",
                                padding: "16px 24px 16px 24px",
                                width: "100%",
                                border: "none",
                                maxWidth: "176px",
                                outline: "none",
                                display: "flex",
                                gap: "8px",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            // onClick={() => {
                            //     props.ss(true);
                            // }}
                            onClick={() => navigate("/flex/create-po")}
                        >
                            <img src={addrecrecipt} alt="" /> Create Order
                        </button>
                    )}
                </div>
            </div>

            <div
                className="main-c-flex"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                    width: "70%",
                    padding: "32px",
                }}
            >
                <div
                    className="tab-search"
                    style={{
                        gap: "16px",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            gap: "16px",
                            alignItems: "center",
                        }}
                    >
                        {orderstatus?.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => {
                                    // settab(item);
                                    setselectedindex(index);
                                    setsearchbank(item === "All" ? "" : item);
                                }}
                                style={{
                                    fontWeight: selectedindex === index ? "700" : "400",
                                    fontSize: "14px",
                                    ///   color:'#667085',
                                    lineHeight: "19.6px",
                                    background: selectedindex === index && "#EBE4FF",
                                    borderRadius: "4px",
                                    color: selectedindex === index ? "#6F00FF" : "#667085",
                                    padding: "4px 8px",
                                    cursor: "pointer",
                                }}
                            >
                                {item}
                            </div>
                        ))}
                    </div>

                    <div
                        style={{
                            background: "#ECEFF3",
                            display: "flex",
                            gap: "8px",
                            height: "48px",
                            borderRadius: "8px",
                            padding: "0px 8px 0px 8px",
                            alignItems: "center",
                        }}
                    >
                        <img src={search} alt="" />
                        <input
                            type="text"
                            style={{
                                color: "#667085",
                                fontSize: "14px",
                                fontWeight: "500",
                                lineHeight: "19.6px",
                                width: "100%",
                                outline: "none",
                                background: "#ECEFF3",
                                border: "none",
                            }}
                            onChange={(e) => {
                                setinputsearch(e.target.value);
                            }}
                            placeholder="Search by name or business"
                        />
                    </div>
                </div>

                {props.loaderset === false ? (
                    <>
                        {filterBuyer.length === 0 && (
                            <div
                                className="no-flex-c"
                                style={{
                                    minHeight: "460px",
                                    overflow: "auto",
                                    padding: "16px 24px 24px 24px",
                                    borderRadius: "16px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <div
                                    style={{
                                        width: "100%",
                                        alignItems: "center",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        gap: "24px",
                                    }}
                                >
                                    <img src={invoice} alt="" />
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "8px",
                                        }}
                                    >
                                        <h3
                                            style={{
                                                fontWeight: "700",
                                                color: "#101828",
                                                fontSize: "18px",
                                                lineHeight: "24.3px",
                                                textAlign: "center",
                                            }}
                                        >
                                            There are no Procurement yet
                                        </h3>
                                        <span
                                            style={{
                                                color: "#667085",
                                                fontSize: "14px",
                                                lineHeight: "19.6px",
                                                fontWeight: "500",
                                                textAlign: "center",
                                                maxWidth: "229px",
                                            }}
                                        >
                                            Start adding your invoices to Tradevu to see them here.
                                        </span>
                                    </div>
                                    <button
                                        style={{
                                            background: "#6F00FF",
                                            color: "#fff",
                                            borderRadius: "8px",
                                            padding: "16px 24px 16px 24px",
                                            maxWidth: "229px",
                                            width: "100%",
                                            border: "none",
                                            outline: "none",
                                            display: "flex",
                                            gap: "8px",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                        // onClick={() => {
                                        //     props.ss(true);
                                        // }}
                                        onClick={() => navigate("/flex/create-po")}
                                    >
                                        <img src={addrecrecipt} alt="" /> Create Order
                                    </button>
                                </div>
                            </div>
                        )}

                        {props.invoice.length >= 1 && (
                            <div
                                style={{
                                    height: "100%",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "16px",
                                    }}
                                >
                                    {result.reverse()?.map((invoce, index) => (
                                        <Fragment key={invoice.alphabet}>
                                            <label
                                                key={`keyindex-${index}`}
                                                htmlFor=""
                                                style={{
                                                    color: "#98A2B3",
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                    lineHeight: "16.2px",
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                {new Date(invoce.alphabet).toDateString() ===
                                                new Date().toDateString()
                                                    ? "Today"
                                                    : new Date().getMonth() - 1 ===
                                                      new Date(invoce.alphabet).getMonth()
                                                    ? "Last Month"
                                                    : new Date(invoce.alphabet).toDateString()}
                                            </label>
                                            {invoce.record?.reverse()?.map((item, indexs) => (
                                                <>
                                                    {item.title !== "" &&
                                                        item.title !== undefined && (
                                                            <div
                                                                key={`no-key${indexs}`}
                                                                style={{
                                                                    border: "1px solid #E7E9FB",
                                                                    borderRadius: "8px",
                                                                    padding: "16px",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "space-between",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => {
                                                                    props.os(true);
                                                                    props.setselectedbuyer(item);
                                                                    props?.selectedorderinvoice(
                                                                        item?.invoice
                                                                    );
                                                                    props?.selectedorderdocument(
                                                                        item?.document
                                                                    );
                                                                }}
                                                            >
                                                                <div className="flex !gap-2 items-center">
                                                                    <img src={longinvoice} alt="" />
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                            gap: "4px",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                color: "#101828",
                                                                                fontWeight: "600",
                                                                                fontSize: "14px",
                                                                                lineHeight:
                                                                                    "19.6px",
                                                                            }}
                                                                        >
                                                                            {item.title}
                                                                        </div>
                                                                        {/* <span
                                                                            style={{
                                                                                color:
                                                                                    item?.status ===
                                                                                    "decline"
                                                                                        ? "crimson"
                                                                                        : item?.orderStatus ===
                                                                                          "DELAYED"
                                                                                        ? "#DC6803"
                                                                                        : item?.orderStatus ===
                                                                                          "COMPLETED"
                                                                                        ? "#12B76A"
                                                                                        : item?.orderStatus ===
                                                                                          "DECLINED"
                                                                                        ? "#F04438"
                                                                                        : item?.orderStatus ===
                                                                                          "PROCESSING"
                                                                                        ? "#6F00FF"
                                                                                        : "#F79009",
                                                                                letterSpacing: "2%",
                                                                                fontWeight: "400",
                                                                                lineHeight:
                                                                                    "19.6px",
                                                                                fontSize: "14px",
                                                                                textTransform:
                                                                                    "uppercase",
                                                                            }}
                                                                        >
                                                                            {item?.status ===
                                                                            "decline"
                                                                                ? "DECLINED"
                                                                                : item.orderStatus}
                                                                            <span
                                                                                style={{
                                                                                    color: "#12B76A",
                                                                                    fontWeight:
                                                                                        "400",
                                                                                }}
                                                                            >
                                                                                {item.paymentStatus}
                                                                            </span>
                                                                        </span> */}
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-col !gap-1 items-end">
                                                                    <div
                                                                        style={{
                                                                            color: "#101828",
                                                                            fontWeight: "500",
                                                                            fontSize: "14px",
                                                                            lineHeight: "19.6px",
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        {currencyPairCheck(
                                                                            item.currency
                                                                        )}
                                                                        {AmountCheck(
                                                                            item?.totalAmount
                                                                                ? item.totalAmount
                                                                                : 0
                                                                        )}
                                                                    </div>
                                                                    <span
                                                                        style={{
                                                                            color: "#667085",
                                                                            letterSpacing: "2%",
                                                                            fontWeight: "400",
                                                                            fontSize: "14px",

                                                                            lineHeight: "19.6px",
                                                                        }}
                                                                    >
                                                                        {new Date(
                                                                            item.createdAt
                                                                        ).toLocaleTimeString()}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )}
                                                </>
                                            ))}
                                        </Fragment>
                                    ))}
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <ComponentLoader />
                )}
            </div>
        </div>
    );
};

export default OrderInvoice;
