import { handleGetAllInvoice, handleGetAllUsers } from "api/fetchers";
import axios from "axios";
import PartnerInvoice from "flex/PartnerInvoice";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import UserNetworkModal from "./UserNetworkModal";

const Network = () => {
    const [invoices, setinvoices] = useState([]);
    const [showupload, setshowupload] = useState(false);
    const [supplierloader, setsupplierloader] = useState(true);
    const [showrequestStatement, setshowrequestStatement] = useState(true);
    const selectorBusinessId = useSelector((state) => state.businessid);
    const [active, setactive] = useState([]);
    const [selectedBuyer, SetSelectedBuyer] = useState(null);
    const [tab, settab] = useState("all");
    const [showUserDetails, setShowUserDetails] = useState(false);
    const [details, setDetails] = useState(null);

    const { data, isLoading: loadingInvoice } = useQuery({
        queryKey: ["alInvoice", selectorBusinessId],
        queryFn: () =>
            selectorBusinessId &&
            handleGetAllInvoice({ businessId: selectorBusinessId, currency: "NGN" }),
    });

    const {
        data: userList,
        isLoading: loadingAllUsers,
        isError,
        refetch,
    } = useQuery({
        queryKey: ["users", selectorBusinessId, tab],
        queryFn: () =>
            selectorBusinessId && handleGetAllUsers({ businessId: selectorBusinessId, type: tab }),
        refetchOnMount: true,
    });

    const result =
        !loadingAllUsers && Array.isArray(userList)
            ? userList?.reduce((acc, item) => {
                  const alphabet = item.firstName.charAt(0).toUpperCase();
                  if (!acc[alphabet]) {
                      acc[alphabet] = [];
                  }
                  acc[alphabet].push(item);
                  return acc;
              }, {})
            : [];

    const allInvoice =
        Object?.keys(result)?.map((alphabet) => ({
            alphabet,
            record: result[alphabet],
        })) ?? [];

    const newarray = [];
    newarray?.sort((a, b) => a.firstName.localeCompare(b.firstName, "es", { sensitivity: "base" }));

    let datas = newarray?.reduce((r, e) => {
        let alphabet = e.firstName[0];

        if (!r[alphabet])
            r[alphabet] = {
                alphabet,
                record: [],
            };

        r[alphabet].record.push(e);
        return r;
    }, {});

    function filterRecordsByStatus(data, status) {
        const dataCopy = {
            ...data,
            record: data?.record?.filter((item) => item?.status === status),
        };
        return Object.values(dataCopy);
    }

    const handleShowDetails = (item) => {
        setShowUserDetails(true);
        setDetails(item);
    };

    return (
        <>
            <div className="!px-7">
                <h2 className="font-[450] text-[22px] text-main-dark !mt-9">Network</h2>
                <PartnerInvoice
                    invoice={allInvoice ?? []}
                    setinvoices={setinvoices}
                    s={showupload}
                    ss={setshowupload}
                    loaderset={loadingAllUsers}
                    active={allInvoice ?? []}
                    setActive={setactive}
                    setshowdetail={setshowrequestStatement}
                    sb={SetSelectedBuyer}
                    pending={allInvoice ?? []}
                    incoming={allInvoice ?? []}
                    tab={tab}
                    settab={settab}
                    activePartnersCount={userList?.length}
                    refetchUser={refetch}
                    handleShowDetails={handleShowDetails}
                />
            </div>
            <UserNetworkModal
                show={showUserDetails}
                setShow={setShowUserDetails}
                details={details}
            />
        </>
    );
};

export default Network;
