import LoginHeader from "../../../component/loginheader";
import "../../../style/index.css";
import { useEffect, useState } from "react";
import ArrowBack from "../../../component/arrowback";
import { useLocation, useNavigate } from "react-router-dom/dist";
import { Helmet } from "react-helmet";
import Loaders from "../../../component/loader";
import { ThreeDots } from "react-loader-spinner";
import CountryModal from "../../../component/countrymodal";
import AddressModal from "../../../component/addressModal";
import FailHandler from "../../../component/failhandler";
import SuccessHodler from "../../../component/successholder";
import BusinessTypeModal from "../../../component/businesstypemodal";
import { useSelector } from "react-redux";
import { handleCreateBusiness } from "api/fetchers";

const BusinessInformation = () => {
    const [businessCountry, setBusinessCountry] = useState("Nigeria");
    const [changecolorforlabelhousenumebr, setchangecolorforlabelhousenumber] = useState(false);
    const [rcNumber, setRcNumber] = useState("");
    const [businessName, setBusinesssName] = useState("");
    const [address, setAddress] = useState([]);
    const [loader, setLoader] = useState(false);
    const [showfirstnamelabe, setshowFirstnamelabel] = useState(false);

    const [addresslabel, setaddreesslabel] = useState(false);
    const [countrylabel, setcountrylabel] = useState(false);
    const [changecolorforlabelpassword, setchangecolorforlabelpassword] = useState(false);
    const [changecolorforlabelemail, setchangecolorforlabelemail] = useState(false);
    const [showPasswordLabel, setShowlabelPassword] = useState(false);
    const [changecolorforlabeladdress, setchangecolorforlabeladdress] = useState(false);
    const business = [
        {
            type: "LLC",
            title: "CAC registered corporations",
        },
        {
            type: "SoleProprietorship",
            title: "CAC registered businesses or partnerships",
        },
    ];
    const [changecolorforlabellegalname, setchangecolorforlabellegalname] = useState(false);
    const [changecolorfortrade, setchangecolorfortrade] = useState(false);
    const [showlegalnamelabel, setshowlegalname] = useState(false);
    const [showtradenamelabel, setshowtradename] = useState(false);

    const [showhouselabel, setshowhousenumber] = useState(false);
    const [legalname, setlegalname] = useState("");
    const [tradename, settradename] = useState("");
    const [businessType, setBusinesssType] = useState("LLC");
    const [houseNumber, setHouseNumber] = useState("");
    const [loaderrc, setloaderrc] = useState(false);
    const [show, setshow] = useState(false);
    const [show2, setshow2] = useState(false);
    const [fails, setfails] = useState("");
    const [success, setsuccess] = useState("");
    const [message, setmessage] = useState("");

    const [closes, setcloses] = useState(false);
    const [showtype, setshowtype] = useState(false);
    const selector = useSelector((state) => state.users);
    const navigate = useNavigate();
    // const getRCDetails = () => {
    //     const data = {
    //         RcNumber: rcNumber,
    //     };
    //     if (rcNumber.length === 7) {
    //         setloaderrc(true);

    //         axios
    //             .post("/v1/utility/get-business-name", data)
    //             .then((res) => {
    //                 // console.log(res);
    //                 setBusinesssName(res.data.data.company_name);
    //                 setloaderrc(false);
    //                 setshowbusinessname(true);
    //             })
    //             .catch((e) => {
    //                 console.log(e);
    //             });
    //     } else {
    //         setBusinesssName("");
    //     }
    // };
    useEffect(() => {
        if (fails) {
            let timerr = setTimeout(() => {
                setfails(false);
            }, 3000);

            return () => {
                clearTimeout(timerr);
            };
        }
    });

    const submitDocument = async (e) => {
        e.preventDefault();
        setLoader(true);

        const data = {
            country: businessCountry,
            registrationNumber: rcNumber,
            name: businessName,
            address: address.description,
            businessType: businessType === "LLC" ? "LLC" : "SoleProprietorship",
            placeId: address.place_id,
            email: selector.email,
        };

        const datas = {
            country: businessCountry,
            registrationNumber: houseNumber,
            address: address.description,
            name: tradename,
            legalName: legalname,
            placeId: address.place_id,
            businessType: businessType === "LLC" ? "LLC" : "SoleProprietorship",
            email: selector.email,
        };

        try {
            const result = await handleCreateBusiness(businessCountry === "Nigeria" ? data : datas);
            setsuccess(true);
            setmessage(result.message);
            setLoader(false);

            if (businessCountry === "Nigeria") {
                navigate("/business-address-verification", {
                    state: {
                        ...data,
                        businessId: result._id,
                    },
                });
            } else {
                navigate("/business/vatnumber", {
                    state: {
                        ...datas,
                        businessId: result._id,
                    },
                });
            }
        } catch (error) {
            setmessage(error?.message || "An error occurred, couldn't create business");
            setfails(true);
            setLoader(false);
        }
    };

    return (
        <div className="bg-[#F6F8FA] pr-0 max-w-[2000px] w-full mx-auto">
            <Helmet>
                <title>Tradevu — registration</title>
            </Helmet>
            <LoginHeader width={12} />
            <CountryModal
                show={show}
                setselectedcounty={setBusinessCountry}
                setshow={setshow}
                setclose={setcloses}
                closes={closes}
            />
            <AddressModal show={show2} setselectedcounty={setAddress} setshow={setshow2} />
            <BusinessTypeModal
                show={showtype}
                setshow={setshowtype}
                tenure={business}
                settenureday={setBusinesssType}
            />
            <div
                className="p-2 justify-self-center align-items-center d-flex"
                style={{ height: "calc(100% - 200px)" }}
            >
                <div
                    className="form-general-layout"
                    style={{
                        position: "relative",
                    }}
                >
                    <FailHandler success={fails} message={message} />
                    <SuccessHodler success={success} message={message} />
                    <div className="card-body flex flex-col gap-8">
                        <div className="flex justify-between items-center">
                            <ArrowBack />
                            <span
                                className="text-sm font-semibold text-[#344054] cursor-pointer leading-[19.6px]"
                                onClick={() => {
                                    navigate("/dashboard");
                                }}
                            >
                                Skip
                            </span>
                        </div>

                        <div className="flex flex-col gap-1">
                            <h3 className="text-4xl font-semibold text-[#101828] leading-[40px] m-0 p-0">
                                Tell us about your business
                            </h3>
                            <span className="text-[#667085] text-base leading-6 tracking-[2%]">
                                Enter your business information
                            </span>
                        </div>

                        <form
                            onSubmit={submitDocument}
                            className="flex flex-col justify-between gap-8"
                        >
                            <div className="flex flex-col gap-4">
                                <div className="relative flex flex-col gap-1 h-14 px-4 py-2 bg-[#ECEFF3] justify-center rounded-lg">
                                    <label
                                        htmlFor="firstname"
                                        className="text-xs font-medium text-[#667085]"
                                    >
                                        Business Country
                                    </label>

                                    <input
                                        type="text"
                                        className="w-full shadow-none bg-inherit border-none outline-none text-sm placeholder-gray-400"
                                        aria-describedby="emailHelp"
                                        placeholder="Select Country"
                                        readOnly
                                        value={businessCountry}
                                        onFocus={() => setcountrylabel(true)}
                                        onClick={() => setshow(true)}
                                    />
                                    <span className="material-icons absolute right-4 text-[#101828] text-2xl">
                                        expand_more
                                    </span>
                                </div>

                                {businessCountry !== "Nigeria" && (
                                    <>
                                        {/* Legal Name */}
                                        <div
                                            className={`w-full h-14 flex flex-col justify-center gap-1 px-4 rounded-lg ${
                                                changecolorforlabellegalname
                                                    ? "bg-[#D7DDEA]"
                                                    : "bg-[#ECEFF3]"
                                            }`}
                                        >
                                            {showlegalnamelabel && (
                                                <label
                                                    htmlFor="legalname"
                                                    className={`text-xs font-medium leading-[16.2px] ${
                                                        changecolorforlabellegalname
                                                            ? "text-[#6F00FF]"
                                                            : "text-[#667085]"
                                                    }`}
                                                >
                                                    Legal name
                                                </label>
                                            )}
                                            <input
                                                type="text"
                                                id="legalname"
                                                className="w-full border-0 shadow-none outline-none text-[#667085] text-base bg-inherit rounded-lg"
                                                onKeyPress={() =>
                                                    setchangecolorforlabellegalname(true)
                                                }
                                                onBlur={() => {
                                                    setchangecolorforlabellegalname(false);
                                                    setshowlegalname(false);
                                                }}
                                                value={legalname}
                                                onChange={(e) => setlegalname(e.target.value)}
                                                onFocus={() => setshowlegalname(true)}
                                                placeholder="Legal name"
                                                required
                                            />
                                        </div>

                                        {/* House Registration Number */}
                                        <div
                                            className={`w-full h-14 flex flex-col justify-center gap-1 px-4 rounded-lg ${
                                                changecolorforlabelhousenumebr
                                                    ? "bg-[#D7DDEA]"
                                                    : "bg-[#ECEFF3]"
                                            }`}
                                        >
                                            {showhouselabel && (
                                                <label
                                                    htmlFor="housenumber"
                                                    className={`text-xs font-medium leading-[16.2px] ${
                                                        changecolorforlabelhousenumebr
                                                            ? "text-[#6F00FF]"
                                                            : "text-[#667085]"
                                                    }`}
                                                >
                                                    House registration number
                                                </label>
                                            )}
                                            <input
                                                type="text"
                                                id="housenumber"
                                                className="w-full border-0 shadow-none outline-none text-[#667085] text-base bg-inherit rounded-lg"
                                                onKeyPress={() =>
                                                    setchangecolorforlabelhousenumber(true)
                                                }
                                                onBlur={() => {
                                                    setchangecolorforlabelhousenumber(false);
                                                    setshowhousenumber(false);
                                                }}
                                                onKeyDown={(e) => {
                                                    const allowedKeys = [
                                                        "Backspace",
                                                        "Delete",
                                                        "ArrowLeft",
                                                        "ArrowRight",
                                                        "Tab",
                                                    ];
                                                    if (
                                                        !/^[0-9]$/.test(e.key) &&
                                                        !allowedKeys.includes(e.key)
                                                    ) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                value={houseNumber}
                                                onChange={(e) => setHouseNumber(e.target.value)}
                                                onFocus={() => setshowhousenumber(true)}
                                                placeholder="House registration number"
                                                required
                                            />
                                        </div>

                                        {/* Trading Name */}
                                        <div
                                            className={`w-full h-14 flex flex-col justify-center gap-1 px-4 rounded-lg ${
                                                changecolorfortrade
                                                    ? "bg-[#D7DDEA]"
                                                    : "bg-[#ECEFF3]"
                                            }`}
                                        >
                                            {showtradenamelabel && (
                                                <label
                                                    htmlFor="tradename"
                                                    className={`text-xs font-medium leading-[16.2px] ${
                                                        changecolorfortrade
                                                            ? "text-[#6F00FF]"
                                                            : "text-[#667085]"
                                                    }`}
                                                >
                                                    Trading name
                                                </label>
                                            )}
                                            <input
                                                type="text"
                                                id="tradename"
                                                className="w-full border-0 shadow-none outline-none text-[#667085] text-base bg-inherit rounded-lg"
                                                onKeyPress={() => setchangecolorfortrade(true)}
                                                onBlur={() => {
                                                    setchangecolorfortrade(false);
                                                    setshowtradename(false);
                                                }}
                                                value={tradename}
                                                onChange={(e) => settradename(e.target.value)}
                                                onFocus={() => setshowtradename(true)}
                                                placeholder="Trading name"
                                                required
                                            />
                                        </div>
                                    </>
                                )}
                                <div
                                    className={`w-full relative flex flex-col justify-center gap-1 h-14 px-4 rounded-lg ${
                                        changecolorforlabelemail ? "bg-[#D7DDEA]" : "bg-[#ECEFF3]"
                                    }`}
                                    onClick={() => setshowtype(true)}
                                >
                                    {showfirstnamelabe && (
                                        <label
                                            htmlFor="Email"
                                            className={`text-xs font-medium leading-[16.2px] ${
                                                changecolorforlabelemail
                                                    ? "text-[#6F00FF]"
                                                    : "text-[#667085]"
                                            }`}
                                        >
                                            Business Type
                                        </label>
                                    )}
                                    <input
                                        type="text"
                                        className="w-full border-0 shadow-none outline-none text-base bg-inherit rounded-lg"
                                        onKeyPress={() => setchangecolorforlabelemail(true)}
                                        onBlur={() => {
                                            setchangecolorforlabelemail(false);
                                            setshowFirstnamelabel(false);
                                        }}
                                        value={
                                            businessType === "SoleProprietorship"
                                                ? "Sole Proprietorship"
                                                : businessType
                                        }
                                        required
                                        readOnly
                                        placeholder="Business Type"
                                    />
                                </div>

                                {businessCountry === "Nigeria" && (
                                    <>
                                        {/* RC Number */}
                                        <div
                                            className={`w-full relative flex flex-col justify-center gap-1 h-14 px-4 rounded-lg ${
                                                changecolorforlabelpassword
                                                    ? "bg-[#D7DDEA]"
                                                    : "bg-[#ECEFF3]"
                                            }`}
                                        >
                                            {showPasswordLabel && (
                                                <label
                                                    htmlFor="rcNumber"
                                                    className={`text-xs font-medium leading-[16.2px] ${
                                                        changecolorforlabelpassword
                                                            ? "text-[#6F00FF]"
                                                            : "text-[#667085]"
                                                    }`}
                                                >
                                                    RC number
                                                </label>
                                            )}
                                            <input
                                                type="text"
                                                id="rcNumber"
                                                className="w-full border-0 shadow-none outline-none text-base bg-inherit rounded-lg"
                                                maxLength={8}
                                                minLength={6}
                                                onKeyPress={() =>
                                                    setchangecolorforlabelpassword(true)
                                                }
                                                onBlur={() => {
                                                    setchangecolorforlabelpassword(false);
                                                    setShowlabelPassword(true);
                                                }}
                                                value={rcNumber}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*$/.test(value)) {
                                                        setRcNumber(value);
                                                    }
                                                }}
                                                onFocus={() => setShowlabelPassword(true)}
                                                placeholder="RC number"
                                                required
                                            />
                                        </div>

                                        {/* Loader */}
                                        {loaderrc && (
                                            <ThreeDots
                                                height="30"
                                                width="30"
                                                radius="9"
                                                color="#6F00FF"
                                                ariaLabel="three-dots-loading"
                                                wrapperClassName="flex items-center justify-center mt-[-20px]"
                                                visible={true}
                                            />
                                        )}

                                        {/* Business Name */}
                                        <div
                                            className={`w-full relative flex flex-col justify-center gap-1 h-14 px-4 rounded-lg ${
                                                changecolorforlabelemail
                                                    ? "bg-[#D7DDEA]"
                                                    : "bg-[#ECEFF3]"
                                            }`}
                                        >
                                            {showfirstnamelabe && (
                                                <label
                                                    htmlFor="businessName"
                                                    className={`text-xs font-medium leading-[16.2px] ${
                                                        changecolorforlabelemail
                                                            ? "text-[#6F00FF]"
                                                            : "text-[#667085]"
                                                    }`}
                                                >
                                                    Business name
                                                </label>
                                            )}
                                            <input
                                                type="text"
                                                id="businessName"
                                                className="w-full border-0 shadow-none outline-none text-base bg-inherit rounded-lg"
                                                onKeyPress={() => setchangecolorforlabelemail(true)}
                                                onBlur={() => {
                                                    setchangecolorforlabelemail(false);
                                                    setshowFirstnamelabel(true);
                                                }}
                                                value={businessName}
                                                onChange={(e) => setBusinesssName(e.target.value)}
                                                onFocus={() => setshowFirstnamelabel(true)}
                                                placeholder="Business name"
                                                required
                                            />
                                        </div>
                                    </>
                                )}

                                <div
                                    className={`w-full relative flex flex-col justify-center gap-1 h-14 px-4 rounded-lg ${
                                        changecolorforlabeladdress ? "bg-[#D7DDEA]" : "bg-[#ECEFF3]"
                                    }`}
                                >
                                    {addresslabel && (
                                        <label
                                            htmlFor="address"
                                            className={`text-xs font-medium leading-[16.2px] ${
                                                changecolorforlabeladdress
                                                    ? "text-[#6F00FF]"
                                                    : "text-[#667085]"
                                            }`}
                                        >
                                            Address
                                        </label>
                                    )}

                                    <input
                                        type="text"
                                        id="address"
                                        className="w-full border-0 shadow-none outline-none text-base bg-inherit rounded-lg"
                                        onKeyPress={() => setchangecolorforlabeladdress(true)}
                                        onBlur={() => {
                                            setchangecolorforlabeladdress(false);
                                            setaddreesslabel(true);
                                        }}
                                        value={address?.description}
                                        required
                                        onChange={(e) => setAddress(e.target.value)}
                                        readOnly
                                        onFocus={() => setaddreesslabel(true)}
                                        onClick={() => setshow2(true)}
                                        placeholder="Address"
                                    />
                                </div>

                                <button
                                    disabled={loader}
                                    type="submit"
                                    className={`flex justify-center items-center text-white text-base font-semibold leading-6 tracking-[2%] bg-[#6F00FF] w-full max-w-[231px] h-12 rounded-lg px-6 ${
                                        loader
                                            ? "opacity-50 cursor-not-allowed"
                                            : "hover:bg-[#5800CC] cursor-pointer"
                                    }`}
                                >
                                    {loader ? <Loaders data={loader} /> : "Continue"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>{" "}
        </div>
    );
};

export default BusinessInformation;
