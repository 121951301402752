import world from "../../../asset/images/worldtradevu.svg";
import info from "../../../asset/images/info-circle.svg";
import logotr from "../../../asset/images/tr.svg";

import { Link, useNavigate } from "react-router-dom";
import "../../../style/index.css";

import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import Loaders from "../../../component/loader";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT_USER } from "../../../store/action";
import AuthNav from "component/layout/AuthNav";
import { handleCheckEmail } from "api/fetchers";

const SignUp = () => {
    const [showEmailLabel, setShowlabelEmail] = useState(false);
    const [showPasswordLabel, setShowlabelPassword] = useState(false);
    const [showdefaultcoloremail, setshowdefaultcolor] = useState(false);
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [loader, setLoader] = useState(false);
    const [isNumber, setisNumber] = useState(false);
    const [isUppercase, setisuppercase] = useState(false);
    const [isLowercase, setislowercase] = useState(false);
    const [isupTo8, setIsupto8] = useState(false);
    const [isSpecialCharacter, setIssepecialCharactes] = useState(false);
    const selector = useSelector((state) => state.users);
    const [message, setmessage] = useState("");
    const [error, seterror] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                seterror(false);
            }, 3000);
        }
    });
    const navigate = useNavigate();

    const SubmitHandler = async (e) => {
        e.preventDefault();
        setLoader(true);

        if (isLowercase && isUppercase && isNumber && isSpecialCharacter && isupTo8) {
            if (selector?.isLoggedIn) {
                LOGOUT_USER("", dispatch);
            }

            try {
                const result = await handleCheckEmail(email);

                // Handle email not found
                if (result?.statusCode === 200) {
                    navigate("/register/personal-information", {
                        state: { email, password },
                    });
                }
            } catch (error) {
                // Handle email already exists
                seterror(true);
                setmessage(
                    error?.message ||
                        "Couldn't check email, an error occurred. Please try again later."
                );
            } finally {
                setLoader(false);
            }
        } else {
            setLoader(false);
            setmessage("Ensure your password meets all requirements.");
        }
    };

    const checkPassword = (e) => {
        const password = e.target.value;

        const rules = [
            {
                regex: /[A-Z]/,
                setter: setisuppercase,
                message: "Password must contain an uppercase letter",
            },
            {
                regex: /[a-z]/,
                setter: setislowercase,
                message: "Password must contain a lowercase letter",
            },
            { regex: /[0-9]/, setter: setisNumber, message: "Password must contain a number" },
            {
                regex: /[!@#$%^&*\-><|!)(#+_`~]/,
                setter: setIssepecialCharactes,
                message: "Password must contain a special character",
            },
            {
                regex: /.{8,}/,
                setter: setIsupto8,
                message: "Password must be at least 8 characters long",
            },
        ];

        let isValid = true;

        for (const rule of rules) {
            if (rule.regex.test(password)) {
                rule.setter(true);
            } else {
                rule.setter(false);
                setmessage(rule.message);
                isValid = false;
                setLoader(false);
            }
        }

        if (isValid) {
            setmessage("");
        }
    };

    return (
        <div className="main-login">
            <Helmet>
                {" "}
                <title>Tradevu — register</title>
            </Helmet>
            <div
                className="containers-lo"
                style={{
                    height: "100%",
                    position: "relative",
                }}
            >
                <AuthNav logo={logotr} logoMargin="ml-10" />
                <div className="world-containers">
                    {error && (
                        <div className="max-w-[624px] p-4 rounded-lg border border-[#FDA29B] flex gap-3 items-center ml-9 bg-[#FFFBFA]">
                            <div className="px-2 py-1 rounded-2xl flex gap-3 bg-[#FEF3F2] mix-blend-multiply">
                                <div className="px-2.5 py-0.5 rounded-2xl text-[#B42318] bg-white font-semibold text-sm">
                                    Error
                                </div>
                                <div className="px-2.5 py-0.5 rounded-2xl text-[#B42318] font-semibold text-sm">
                                    {message}
                                </div>
                            </div>
                        </div>
                    )}
                    <div
                        className="la-card"
                        style={{
                            zIndex: "9999",
                            marginLeft: "40px",
                            marginTop: "20px",
                            position: "relative",
                        }}
                    >
                        <form className="form-con" onSubmit={SubmitHandler}>
                            <div className="flex flex-col !gap-4">
                                <h1 className="text-welcome p-0 m-0 tracking-normal">
                                    You only need one supply chain platform.
                                </h1>
                                <span className="text-lgs text-base font-medium mt-0">
                                    Manage the finance and operations stack of your supply chain
                                    with Tradevu.
                                </span>
                            </div>

                            <div className="flex flex-col !gap-4">
                                <div className="input-form">
                                    <div className="each-form-input">
                                        {showEmailLabel && (
                                            <label
                                                htmlFor="Email"
                                                className={`text-sm font-medium ${
                                                    showEmailLabel
                                                        ? "text-[#6F00FF]"
                                                        : showdefaultcoloremail
                                                        ? "text-[#667085]"
                                                        : "text-[#667085]"
                                                }`}
                                            >
                                                Email Address
                                            </label>
                                        )}

                                        <input
                                            type="email"
                                            className="rounded-md w-full placeholder-gray-400"
                                            placeholder="Email Address"
                                            value={email}
                                            onBlur={() => {
                                                if (email !== "") {
                                                    setshowdefaultcolor(true);
                                                    //setShowlabelEmail(false);
                                                } else {
                                                    setshowdefaultcolor(false);
                                                    setShowlabelEmail(false);
                                                }
                                            }}
                                            onFocus={() => setShowlabelEmail(true)}
                                            onChange={(e) => {
                                                setemail(e.target.value);
                                            }}
                                        />
                                    </div>

                                    <div className="each-form-input">
                                        {showPasswordLabel && (
                                            <label
                                                htmlFor="Password"
                                                className={`text-sm font-medium ${
                                                    showPasswordLabel
                                                        ? "text-[#6F00FF]"
                                                        : "text-[#667085]"
                                                }`}
                                            >
                                                Password
                                            </label>
                                        )}
                                        <input
                                            type="password"
                                            className="bg-inherit rounded-md w-full placeholder-gray-400"
                                            placeholder="Password"
                                            value={password}
                                            onFocus={() => setShowlabelPassword(true)}
                                            onKeyUp={(e) => {
                                                checkPassword(e);
                                                setShowlabelPassword(true);
                                            }}
                                            onChange={(e) => {
                                                setpassword(e.target.value);
                                            }}
                                            autoComplete="off"
                                        />
                                    </div>

                                    <div
                                        className={`${showPasswordLabel ? "flex gap-2" : "hidden"}`}
                                    >
                                        <img src={info} alt="" className="h-4 w-4" />
                                        <div className="flex !gap-2">
                                            {isLowercase ? (
                                                <div className="flex items-center bg-[#A173FF] text-[10px] text-white rounded-full !px-2 font-medium">
                                                    <span className="material-icons text-sm">
                                                        done
                                                    </span>
                                                    <span>Lowercase</span>
                                                </div>
                                            ) : (
                                                <div className="flex items-center bg-[#ECEFF3] text-[10px] text-[#344054] rounded-full !px-2 font-medium">
                                                    <span>Lowercase</span>
                                                </div>
                                            )}

                                            {isUppercase ? (
                                                <div className="flex items-center bg-[#A173FF] text-[10px] text-white rounded-full !px-2 font-medium">
                                                    <span className="material-icons text-sm">
                                                        done
                                                    </span>
                                                    <span>Uppercase</span>
                                                </div>
                                            ) : (
                                                <div className="flex items-center bg-[#ECEFF3] text-[10px] text-[#344054] rounded-full !px-2 font-medium">
                                                    <span>Uppercase</span>
                                                </div>
                                            )}

                                            {isNumber ? (
                                                <div className="flex items-center bg-[#A173FF] text-[10px] text-white rounded-full !px-2 font-medium">
                                                    <span className="material-icons text-sm">
                                                        done
                                                    </span>
                                                    <span>Number</span>
                                                </div>
                                            ) : (
                                                <div className="flex items-center bg-[#ECEFF3] text-[10px] text-[#344054] rounded-full !px-2 font-medium">
                                                    <span>Number</span>
                                                </div>
                                            )}

                                            {isSpecialCharacter ? (
                                                <div className="flex items-center bg-[#A173FF] text-[10px] text-white rounded-full !px-2 font-medium">
                                                    <span className="material-icons text-sm">
                                                        done
                                                    </span>
                                                    <span>Special character</span>
                                                </div>
                                            ) : (
                                                <div className="flex items-center bg-[#ECEFF3] text-[10px] text-[#344054] rounded-full !px-2 font-medium">
                                                    <span>Special character</span>
                                                </div>
                                            )}

                                            {isupTo8 ? (
                                                <div className="flex items-center bg-[#A173FF] text-[10px] text-white rounded-full !px-2 font-medium">
                                                    <span className="material-icons text-sm">
                                                        done
                                                    </span>
                                                    <span>8 characters</span>
                                                </div>
                                            ) : (
                                                <div className="flex items-center bg-[#ECEFF3] text-[10px] text-[#344054] rounded-full !px-2 font-medium">
                                                    <span>8 characters</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="confirm_term">
                                    By signing up, I agree to Tradevu’s{" "}
                                    <Link to={"/"} className="tems-privacy">
                                        Terms of Service
                                    </Link>{" "}
                                    and{" "}
                                    <Link to={"/"} className="tems-privacy">
                                        Privacy Policy
                                    </Link>
                                </div>
                            </div>
                            <div className="lgn-btn-lg">
                                <button
                                    className="login-btn"
                                    type="submit"
                                    disabled={loader}
                                    style={{
                                        minWidth: "151px",
                                        fontWeight: "700",
                                    }}
                                >
                                    {loader ? <Loaders /> : "Sign up"}
                                </button>
                                <span className="dont-have">
                                    I already have an account,{" "}
                                    <Link
                                        className="!text-[#6F00FF] !underline !font-semibold"
                                        to={"/"}
                                    >
                                        {" "}
                                        Sign In
                                    </Link>{" "}
                                </span>
                            </div>
                        </form>
                    </div>
                    <img src={world} alt="" className="world" />
                </div>
            </div>
        </div>
    );
};

export default SignUp;
