//import {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import "../style/rightside.css";
import "../style/main.css";
import step from "../asset/images/step.svg";
import Loader from "./loader";
import { useEffect, useState } from "react";
import axios from "axios";
import SuccessHodler from "./successholder";
import FailHandler from "./failhandler";
import currencyPairCheck from "../utilities/currencyPairCheck";
import AmountCheck from "./amountCheck";

function TransactionDetailsModal(props) {
    const [loader, setloader] = useState(false);
    const [loaderaccept, setloaderaccept] = useState(false);
    const [success, setsuccess] = useState(false);
    const [message, setmessage] = useState("");
    const [fail, setfail] = useState(false);
    useEffect(() => {
        if (fail) {
            let timer = setTimeout(() => {
                setfail(false);
            }, 3000);
            return () => {
                clearTimeout(timer);
            };
        } else if (success) {
            let timer = setTimeout(() => {
                setsuccess(false);
            }, 3000);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [fail, success]);
    // console.log(props.data);
    const AcceptAdminAproval = async () => {
        setloaderaccept(true);
        if (props?.data?.financeType === "creditLine") {
            await axios
                .post(`/v1/finance/accept_credit_line/${props?.data?._id}`)
                .then((res) => {
                    setloaderaccept(false);
                    setmessage(res.data?.message);
                    setsuccess(true);
                })
                .catch((e) => {
                    console.log(e);
                    setloaderaccept(false);
                    setmessage(e.response?.data ? e.response?.data?.message : "An error occur");
                });
        } else if (props?.data?.financeType === "tradeCredit") {
            await axios
                .post(`/v1/finance/accept_trade_credit/${props?.data?._id}`)
                .then((res) => {
                    setloaderaccept(false);
                    setmessage(res.data?.message);
                    setsuccess(true);
                    setfail(false);
                })
                .catch((e) => {
                    console.log(e);
                    setloaderaccept(false);
                    setmessage(e.response?.data ? e.response?.data?.message : "An error occur");
                    setfail(true);
                    setsuccess(false);
                });
        }
    };
    const RejectAdminAproval = async () => {
        setloader(true);
        if (props?.data?.financeType === "creditLine") {
            await axios
                .post(`/v1/finance/reject_credit_line/${props?.data?._id}`)
                .then((res) => {
                    setloader(false);

                    setmessage(res.data?.message);
                    setsuccess(true);
                    setfail(false);
                })
                .catch((e) => {
                    setfail(true);
                    setmessage(e.response?.data ? e.response?.data?.message : "An error occur");
                    setloader(false);
                });
        } else if (props?.data?.financeType === "tradeCredit") {
            await axios
                .post(`/v1/finance/reject_trade_credit/${props?.data?._id}`)
                .then((res) => {
                    setloader(false);
                    setmessage(res.data?.message);
                    setsuccess(true);
                    setfail(false);
                })
                .catch((e) => {
                    console.log(e);
                    setloader(false);
                    setmessage(e.response?.data ? e.response?.data?.message : "An error occur");
                    setsuccess(false);
                    setfail(true);
                });
        }
    };
    return (
        <>
            <Modal
                show={props.show}
                backdrop="false"
                className="rightsidemodal"
                tabIndex="-1"
                keyboard={false}
                onHide={() => props.setshow(false)}
            >
                <div
                    className="container_line_stepper"
                    style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                    }}
                >
                    <div
                        style={{
                            height: "calc(100% - 100px)",
                            width: "100%",
                            padding: "20px",
                        }}
                    >
                        <div
                            className="w-100"
                            style={{ height: "100%", marginTop: "40px", marginLeft: "20px" }}
                        >
                            <div
                                className="w-100 "
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "16px",
                                }}
                            >
                                <div className="gap-3 d-flex align-items-center">
                                    <span
                                        className="material-icons"
                                        style={{
                                            color: "#667085",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            props.setshow(false);
                                        }}
                                    >
                                        arrow_back
                                    </span>
                                    <span
                                        style={{
                                            fontSize: "14px",
                                            color: "#667085",
                                            lineHeight: "18.9px",
                                            fontWeight: "500",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        back to home
                                    </span>
                                </div>
                                <div
                                    className=""
                                    style={{
                                        fontSize: "32px",
                                        lineHeight: "40px",
                                        fontWeight: "500",
                                        color: "#101828",
                                        position: "relative",
                                    }}
                                >
                                    Transaction details
                                    <SuccessHodler success={success} message={message} />
                                    <FailHandler success={fail} message={message} />
                                </div>
                            </div>

                            <div className="w-100 d-flex">
                                <div
                                    className=""
                                    style={{
                                        marginTop: "30px",
                                        minWidth: "416px",
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "32px",
                                    }}
                                >
                                    <div className="bankstatement-holder">
                                        <span
                                            className="statement-head-title"
                                            style={{
                                                fontWeight: "500",
                                            }}
                                        >
                                            {props?.datatype === "tradeCredit"
                                                ? "Working  Capital"
                                                : props?.datatype === "creditLine"
                                                ? "Credit Line"
                                                : props?.datatype === "Transaction" &&
                                                  props.data?.TransactionType === "WalletDebit"
                                                ? `${props.data.currency} Transfer`
                                                : props.data?.TransactionType === "WalletCredit"
                                                ? `Wallet Topup`
                                                : "Finance Invoice"}{" "}
                                            details
                                        </span>
                                        <div className="list-item-details">
                                            {props?.datatype === "Transaction" &&
                                                props?.data?.PaymentType === "credit" && (
                                                    <>
                                                        <div className="each-list-item-details">
                                                            <span className="title-col-span">
                                                                Amount
                                                            </span>
                                                            <span className="amount-title-gl">
                                                                {currencyPairCheck(
                                                                    props?.data?.currency
                                                                )}
                                                                {AmountCheck(props?.data?.amount)}
                                                            </span>
                                                        </div>
                                                        <div className="each-list-item-details">
                                                            <span className="title-col-span">
                                                                Sender Name
                                                            </span>
                                                            <span className="amount-title-gl">
                                                                {props?.data?.accountName}
                                                            </span>
                                                        </div>
                                                        <div className="each-list-item-details">
                                                            <span className="title-col-span">
                                                                Sender Bank
                                                            </span>
                                                            <span className="amount-title-gl">
                                                                {props?.data?.bankName}
                                                            </span>
                                                        </div>
                                                        <div className="each-list-item-details">
                                                            <span className="title-col-span">
                                                                Sender Account
                                                            </span>
                                                            <span className="amount-title-gl">
                                                                {props?.data?.accountNumber
                                                                    ? props?.data?.accountNumber
                                                                    : "Tradevu"}
                                                            </span>
                                                        </div>
                                                        <div className="each-list-item-details">
                                                            <span className="title-col-span">
                                                                Transaction ID
                                                            </span>
                                                            <span
                                                                className="amount-title-gl"
                                                                style={{
                                                                    overflow: "hidden",
                                                                    textAlign: "right",
                                                                    textOverflow: "ellipsis",
                                                                    width: "200px",
                                                                }}
                                                            >
                                                                {props?.data?.TransactionReference}
                                                            </span>
                                                        </div>
                                                        <div className="each-list-item-details">
                                                            <span className="title-col-span">
                                                                Completion Time
                                                            </span>
                                                            <span className="amount-title-gl">
                                                                {new Date(
                                                                    props?.data?.createdAt
                                                                ).toDateString()}{" "}
                                                                {new Date(
                                                                    props?.data?.createdAt
                                                                ).toLocaleTimeString()}
                                                            </span>
                                                        </div>
                                                        <div className="each-list-item-details">
                                                            <span className="title-col-span">
                                                                Payment Type
                                                            </span>
                                                            <span className="amount-title-gl">
                                                                {props?.data?.PaymentType}
                                                            </span>
                                                        </div>
                                                    </>
                                                )}
                                            {props?.datatype === "Transaction" &&
                                                props?.data?.PaymentType === "debit" && (
                                                    <>
                                                        <div className="each-list-item-details">
                                                            <span className="title-col-span">
                                                                Amount
                                                            </span>
                                                            <span className="amount-title-gl">
                                                                {currencyPairCheck(
                                                                    props?.data?.currency
                                                                )}
                                                                {AmountCheck(props?.data?.amount)}
                                                            </span>
                                                        </div>
                                                        <div className="each-list-item-details">
                                                            <span className="title-col-span">
                                                                Receiver Name
                                                            </span>
                                                            <span className="amount-title-gl">
                                                                {props?.data?.accountName
                                                                    ? props?.data?.accountName
                                                                    : "Tradevu"}
                                                            </span>
                                                        </div>
                                                        <div className="each-list-item-details">
                                                            <span className="title-col-span">
                                                                Receiver Bank
                                                            </span>
                                                            <span className="amount-title-gl">
                                                                {props?.data?.bankName
                                                                    ? props?.data?.bankName
                                                                    : "Kuda"}
                                                            </span>
                                                        </div>
                                                        <div className="each-list-item-details">
                                                            <span className="title-col-span">
                                                                Receiver Account
                                                            </span>
                                                            <span className="amount-title-gl">
                                                                {props?.data?.accountNumber
                                                                    ? props?.data?.accountNumber
                                                                    : "Tradevu"}
                                                            </span>
                                                        </div>
                                                        {
                                                            props?.data?.narration && (
                                                                <div className="each-list-item-details">
                                                                    <span className="title-col-span">
                                                                        Narration
                                                                    </span>
                                                                            <span className="amount-title-gl">
                                                                        {props?.data?.narration}
                                                                    </span>
                                                                </div>
                                                            )
                                                        }
                                                        <div className="each-list-item-details">
                                                            <span className="title-col-span">
                                                                Transaction ID
                                                            </span>
                                                            <span className="amount-title-gl">
                                                                {props?.data?.TransactionReference
                                                                    ? props?.data
                                                                          ?.TransactionReference
                                                                    : props?.data?._id}
                                                            </span>
                                                        </div>
                                                        <div className="each-list-item-details">
                                                            <span className="title-col-span">
                                                                Completion Time
                                                            </span>
                                                            <span className="amount-title-gl">
                                                                {new Date(
                                                                    props?.data?.createdAt
                                                                ).toDateString()}{" "}
                                                                {new Date(
                                                                    props?.data?.createdAt
                                                                ).toLocaleTimeString()}
                                                            </span>
                                                        </div>
                                                        <div className="each-list-item-details">
                                                            <span className="title-col-span">
                                                                Payment Type
                                                            </span>
                                                            <span className="amount-title-gl">
                                                                {props?.data?.PaymentType}
                                                            </span>
                                                        </div>
                                                    </>
                                                )}

                                            {props?.datatype === "Invoice" && (
                                                <>
                                                    <div className="each-list-item-details">
                                                        <span className="title-col-span">
                                                            Invoice Title
                                                        </span>
                                                        <span className="amount-title-gl">
                                                            {props?.data?.invoiceTitle}
                                                        </span>
                                                    </div>
                                                    <div className="each-list-item-details">
                                                        <span className="title-col-span">
                                                            Requested amount
                                                        </span>
                                                        <span className="amount-title-gl">
                                                            {currencyPairCheck(
                                                                props?.data?.currency
                                                            )}
                                                            {AmountCheck(props?.data?.totalAmount)}
                                                        </span>
                                                    </div>
                                                    <div className="each-list-item-details">
                                                        <span className="title-col-span">
                                                            Paid amount
                                                        </span>
                                                        <span className="amount-title-gl">
                                                            {currencyPairCheck(
                                                                props?.data?.currency
                                                            )}
                                                            {AmountCheck(props?.data?.paidAmount)}
                                                        </span>
                                                    </div>

                                                    <div className="each-list-item-details">
                                                        <span className="title-col-span">
                                                            Issue Date
                                                        </span>
                                                        <span className="amount-title-gl">
                                                            {new Date(
                                                                props?.data?.issueDate
                                                            ).toDateString()}
                                                        </span>
                                                    </div>
                                                    <div className="each-list-item-details">
                                                        <span className="title-col-span">
                                                            Due Date
                                                        </span>
                                                        <span className="amount-title-gl">
                                                            {new Date(
                                                                props?.data?.dueDate
                                                            ).toDateString()}
                                                        </span>
                                                    </div>
                                                    <div className="each-list-item-details">
                                                        <span className="title-col-span">VAT</span>
                                                        <span className="amount-title-gl">
                                                            {props?.data?.vat}
                                                        </span>
                                                    </div>
                                                    <div className="each-list-item-details">
                                                        <span className="title-col-span">
                                                            Payment Status
                                                        </span>
                                                        <span className="amount-title-gl">
                                                            {props?.data?.paymentStatus}
                                                        </span>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        {props?.datatype !== "Transaction" && (
                                            <ul className="list-item-step">
                                                <li className="step-processing-title ">
                                                    <span className="top-step-tiele">
                                                        {new Date(
                                                            props?.data?.createdAt
                                                        ).toDateString()}{" "}
                                                        {new Date(
                                                            props?.data?.createdAt
                                                        ).toLocaleTimeString()}
                                                    </span>
                                                    <span className="main-request">
                                                        Request received
                                                    </span>
                                                    <span className="top-step-tiele">
                                                        Waiting for your request to be viewed
                                                    </span>
                                                    <span className="cicle_stepper first-step">
                                                        <img
                                                            src={step}
                                                            alt=""
                                                            style={{
                                                                boxSizing: "border-box",
                                                                width: "100%",
                                                                height: "100%",
                                                                overflow: "hidden",
                                                            }}
                                                        />
                                                    </span>
                                                </li>
                                                <li className="step-processing-title">
                                                    <span className="main-request">
                                                        {props?.data?.status === "PENDING"
                                                            ? "PROCESSING"
                                                            : "PROCESSING"}
                                                    </span>

                                                    <span className="cicle_stepper first-step">
                                                        {props?.data?.status && (
                                                            <img
                                                                src={step}
                                                                alt=""
                                                                style={{
                                                                    boxSizing: "border-box",
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    overflow: "hidden",
                                                                }}
                                                            />
                                                        )}
                                                    </span>
                                                </li>
                                                <li className="step-processing-title">
                                                    {props?.data?.paymentDate && (
                                                        <span className="top-step-tiele">
                                                            {new Date(
                                                                props?.data?.paymentDate
                                                            ).toDateString()}{" "}
                                                            {new Date(
                                                                props?.data?.paymentDate
                                                            ).toLocaleTimeString()}
                                                        </span>
                                                    )}
                                                    <span
                                                        className="main-request"
                                                        style={{
                                                            color:
                                                                props?.data?.status === "COMPLETED"
                                                                    ? "#6f00f"
                                                                    : "#667085",
                                                        }}
                                                    >
                                                        Completed
                                                    </span>
                                                    <span className="cicle_stepper third-step">
                                                        {props?.data?.status === "COMPLETED" && (
                                                            <img
                                                                src={step}
                                                                alt=""
                                                                style={{
                                                                    boxSizing: "border-box",
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    overflow: "hidden",
                                                                }}
                                                            />
                                                        )}
                                                    </span>
                                                    <span className="top-step-tiele">
                                                        {props?.data?.status === "COMPLETED"
                                                            ? "Payment Completed"
                                                            : "Awaiting invoice payment to be paid"}
                                                    </span>
                                                </li>
                                            </ul>
                                        )}
                                        {props?.datatype === "Transaction" && (
                                            <ul className="list-item-step">
                                                <li className="step-processing-title ">
                                                    <span className="top-step-tiele">
                                                        {new Date(
                                                            props?.data?.createdAt
                                                        ).toDateString()}{" "}
                                                        {new Date(
                                                            props?.data?.createdAt
                                                        ).toLocaleTimeString()}
                                                    </span>
                                                    <span className="main-request">
                                                        Request received
                                                    </span>
                                                    <span className="top-step-tiele">
                                                        Waiting for your request to be viewed
                                                    </span>
                                                    <span className="cicle_stepper first-step">
                                                        <img
                                                            src={step}
                                                            alt=""
                                                            style={{
                                                                boxSizing: "border-box",
                                                                width: "100%",
                                                                height: "100%",
                                                                overflow: "hidden",
                                                            }}
                                                        />
                                                    </span>
                                                </li>
                                                <li className="step-processing-title">
                                                    <span className="top-step-tiele">
                                                        {new Date(
                                                            props?.data?.createdAt
                                                        ).toDateString()}{" "}
                                                        {new Date(
                                                            props?.data?.createdAt
                                                        ).toLocaleTimeString()}
                                                    </span>

                                                    <span
                                                        className="main-request"
                                                        style={{
                                                            color:
                                                                props?.data?.status !== "Success" &&
                                                                "#667085",
                                                        }}
                                                    >
                                                        Processing
                                                    </span>
                                                    <span className="top-step-tiele">
                                                        Waiting for bank to receive funds
                                                    </span>
                                                    <span className="cicle_stepper first-step">
                                                        {props?.data.status === "Success" && (
                                                            <img
                                                                src={step}
                                                                alt=""
                                                                style={{
                                                                    boxSizing: "border-box",
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    overflow: "hidden",
                                                                }}
                                                            />
                                                        )}
                                                    </span>
                                                </li>
                                                <li className="step-processing-title">
                                                    <span className="top-step-tiele">
                                                        {new Date(
                                                            props?.data?.createdAt
                                                        ).toDateString()}{" "}
                                                        {new Date(
                                                            props?.data?.createdAt
                                                        ).toLocaleTimeString()}
                                                    </span>

                                                    <span
                                                        className="main-request"
                                                        style={{
                                                            color:
                                                                props?.data?.status === "Success"
                                                                    ? "#6f00f"
                                                                    : "#667085",
                                                        }}
                                                    >
                                                        Completed
                                                    </span>
                                                    <span className="cicle_stepper third-step">
                                                        {props?.data?.status === "Success" && (
                                                            <img
                                                                src={step}
                                                                alt=""
                                                                style={{
                                                                    boxSizing: "border-box",
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    overflow: "hidden",
                                                                }}
                                                            />
                                                        )}
                                                    </span>
                                                    <span className="top-step-tiele">
                                                        {props?.data?.status === "Success" &&
                                                        props?.data?.PaymentType === "credit"
                                                            ? "Payment Received"
                                                            : "Payment Sent"}
                                                    </span>
                                                </li>
                                            </ul>
                                        )}
                                    </div>
                                    {props?.data?.isAdminApproved?.status !== "APPROVED" &&
                                        props?.datatype !== "Transaction" && (
                                            <>
                                                {/**
                      <button
                        className="btn-accept-list"
                        style={{
                          marginBottom: "0px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background:'#6F00FF'
                        }}
                        onClick={() => AcceptAdminAproval()}
                      >
                        {loaderaccept ? <Loader /> : "Accept"}
                      </button>
                       */}
                                                <button
                                                    className="btn-accept-list"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                    // onClick={() => RejectAdminAproval()}
                                                >
                                                    {loader ? <Loader /> : "View Receipt"}
                                                </button>
                                            </>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default TransactionDetailsModal;
