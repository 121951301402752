import axios from "axios";

export const useApi = () => {
    const sendEmailVerification = async (email) => {
        const data = { email };
        return axios.post("/v1/users/send-verification-email", data);
    };

    const sendSmsVerification = async (phoneNumber) => {
        const data = { phoneNumber };
        return axios.post("/v1/users/send-verification-sms", data);
    };

    const verifyEmail = async (email, code) => {
        const data = { email, code };
        return axios.post("/v1/users/verify-email", data);
    };

    const verifySms = async (phoneNumber, code) => {
        const data = { phoneNumber, code };
        return axios.post("/v1/users/verify-sms", data);
    };

    return { sendEmailVerification, sendSmsVerification, verifyEmail, verifySms };
};
