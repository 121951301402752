import { useState } from "react";
import LoginHeader from "component/loginheader";
import PINSubmission from "component/auth/onboarding/PINSubmission";
import ArrowBack from "component/arrowback";
import PinInput from "component/form/PinInput";

const SetPin = () => {
    const [pins, setPins] = useState(["", "", "", ""]);

    return (
        <div className="bg-[#F6F8FA] w-full max-w-[2000px] mx-auto">
            <LoginHeader width={105} />
            <div className="flex justify-center items-end" style={{ height: "calc(100% - 200px)" }}>
                <div className="form-general-layout flex justify-center items-center">
                    <div className="card-body">
                        <ArrowBack />
                        <div className="flex flex-col gap-1 pt-2">
                            <h2 className="font-semibold text-[#101828] text-[32px]">
                                Create Your PIN
                            </h2>
                            <p className="text-base text-[#667085] font-medium">
                                Enter the 4-digit PIN
                            </p>
                        </div>

                        <form className="flex flex-col gap-8">
                            <PinInput pins={pins} setPins={setPins} />
                            <PINSubmission pinCode={pins} />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SetPin;
