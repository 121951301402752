import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { useEffect } from "react";
import upload from "../asset/images/colorupload.svg";
import Loaders from "../asset/images/l.gif";

function UpdateBusinessModal(props) {
    const [isdisabled, setisDisable] = useState(true);
    const [files, setfiles] = useState(null);
    useEffect(() => {
        if (files !== null) {
            setisDisable(false);
        }
    }, [files]);
    const closeModal = () => {
        var datashow = props.show;
        props.setshow(!datashow);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        props.setshow(false);
    };
    useEffect(() => {
        if (props.filename.length !== 0) {
            props.updateBusinessDetails();
        }
    }, [props.filename]);
    return (
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="border-0 "
            onHide={() => props.setshow(false)}
        >
            <Modal.Body
                style={{
                    borderRadius: "16px",
                    padding: "0px",
                    border: "none",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                    }}
                >
                    <div
                        className=""
                        style={{
                            borderBottom: "1px solid #E7E9FB",
                            padding: "8px 24px 8px 24px",
                        }}
                    >
                        <span
                            className="material-icons"
                            style={{ color: "#667085" }}
                            onClick={closeModal}
                        >
                            close
                        </span>
                    </div>
                    <div style={{ padding: "8px 24px 8px 24px" }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                            }}
                        >
                            <h3
                                style={{
                                    fontWeight: "700",
                                    color: "#101828",
                                    lineHeight: "24.3px",
                                    fontSize: "18px",
                                }}
                            >
                                {" "}
                                {props.title ? props.title : "Invoice Details"}
                            </h3>
                        </div>
                        <form
                            className=""
                            onSubmit={handleSubmit}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "8px",
                                height: "266px",
                                justifyContent: "space-between",
                            }}
                        >
                            <div>
                                {" "}
                                {props.filename.length === 0 && (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "32px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "4px",
                                                color: "#6F00FF",
                                                fontSize: "14px",
                                                lineHeight: "19.6px",
                                                alignItems: "center",
                                            }}
                                        >
                                            <span>See a sample</span>
                                            <span className="material-icons">chevron_right</span>
                                        </div>

                                        <div
                                            style={{
                                                borderRadius: "8px",
                                                padding: "24px 16px 24px 16px",
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "16px",
                                                background: "#ECEFF3",
                                                justifyContent: "center",
                                                position: "relative",
                                            }}
                                        >
                                            <input
                                                type="file"
                                                accept=".png, .jpg, .jpeg, .pdf"
                                                style={{
                                                    position: "absolute",
                                                    height: "100%",
                                                    width: "100%",
                                                    left: "-5px",
                                                    opacity: "0",
                                                }}
                                                id="cacfile"
                                                onChange={(e) => {
                                                    setfiles(e.target.files[0]);
                                                    props.setfilename(e.target.files[0]);
                                                    props.setfilesize(e.target.files[0]?.size);
                                                }}
                                            />
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <img
                                                    src={upload}
                                                    alt=""
                                                    width={40}
                                                    height={40}
                                                    style={{ margin: "0px auto" }}
                                                />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            color: "#101828",
                                                            lineHeight: "24px",
                                                            fontWeight: "600",
                                                            letterSpacing: "2%",
                                                            fontSize: "16px",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        Drag or Upload {props?.title} here
                                                    </span>
                                                    <span
                                                        style={{
                                                            lineHeight: "16.2px",
                                                            fontSize: "12px",
                                                            letterSpacing: "2%",
                                                            fontWeight: "500",
                                                            textAlign: "center",
                                                            color: "#667085",
                                                        }}
                                                    >
                                                        JPEG, PNG, JPG, PDF
                                                    </span>
                                                    <span
                                                        style={{
                                                            lineHeight: "16.2px",
                                                            fontSize: "12px",
                                                            letterSpacing: "2%",
                                                            fontWeight: "500",
                                                            textAlign: "center",
                                                            color: "#667085",
                                                        }}
                                                    >
                                                        Max. file size: 20.0 MB
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {props.loading && props.loaderrc === false && (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: "30px",
                                        }}
                                    >
                                        <img src={Loaders} alt="" />
                                    </div>
                                )}
                                {props.filename.length !== 0 && props.loaderrc === true && (
                                    <div
                                        style={{
                                            background: "#C0CAD8",
                                            position: "relative",
                                            gap: "8px",
                                            borderRadius: "8px",
                                            padding: "16px",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <input
                                            type="file"
                                            accept=".png, .jpg, .jpeg, .pdf"
                                            style={{
                                                position: "absolute",
                                                height: "100%",
                                                width: "100%",
                                                left: "-5px",
                                                opacity: "0",
                                            }}
                                            onChange={(e) => {
                                                setfiles(e.target.files[0]);
                                                props.setfilename(e.target.files[0]);
                                                props.setfilesize(e.target.files[0]?.size);
                                            }}
                                        />
                                        <span
                                            style={{
                                                textAlign: "center",
                                                color: "#6F00FF",
                                                letterSpacing: "2%",
                                                fontWeight: "600",
                                                lineHeight: "24px",
                                            }}
                                        >
                                            {" "}
                                            {props.filename.name}-{" "}
                                            {parseFloat(
                                                parseFloat(props?.filename?.size) / 1024
                                            ).toFixed(2)}{" "}
                                        </span>
                                        <span style={{ color: "#6F00FF" }}>
                                            {" "}
                                            {parseFloat(parseFloat(props.filesize) / 1024) >= 1
                                                ? "MB"
                                                : "KB"}{" "}
                                        </span>
                                    </div>
                                )}{" "}
                            </div>
                            <div className="mb-2">
                                <button
                                    style={{
                                        width: "100%",
                                        background: isdisabled ? "#EBE4FF" : "#6F00FF",
                                        padding: "8px 12px 8px 12px",
                                        border: "none",
                                        height: "48px",

                                        outline: "none",
                                        borderRadius: "8px",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                        letterSpacing: "2%",
                                        fontWeight: "600",
                                        color: isdisabled ? "#BFA6FF" : "#fff",
                                    }}
                                    disabled={isdisabled}
                                >
                                    Continue
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default UpdateBusinessModal;
