import axios from "axios";

/**
 * Fetches the user's location using Geolocation API and Google Maps API.
 * @returns {Promise<string | null>} A formatted address or null if unavailable.
 */
const fetchUserLocation = async () => {
    if ("geolocation" in navigator) {
        return new Promise((resolve) => {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    try {
                        const response = await axios.get(
                            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyANCcAa09J_M6N_tRRd6_K8cF8mIthUPBw`
                        );
                        resolve(response.data.results[0]?.formatted_address || null);
                    } catch (error) {
                        console.warn("Error fetching address:", error.message);
                        resolve(null);
                    }
                },
                (error) => {
                    // Handle Geolocation errors
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            console.warn("Geolocation permission denied.");
                            break;
                        case error.POSITION_UNAVAILABLE:
                            console.warn("Geolocation position unavailable.");
                            break;
                        case error.TIMEOUT:
                            console.warn("Geolocation request timed out.");
                            break;
                        default:
                            console.warn("An unknown geolocation error occurred.");
                            break;
                    }
                    resolve(null);
                }
            );
        });
    } else {
        console.warn("Geolocation is not supported by your browser.");
        return Promise.resolve(null);
    }
};

export default fetchUserLocation;
