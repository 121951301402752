import LoginHeader from "../../../component/loginheader";
import "../../../style/index.css";
import ArrowBack from "../../../component/arrowback";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import document from "../../../asset/images/document.png";
import { useEffect, useState } from "react";
import axios from "axios";
import marksuccess from "../../../asset/images/marksuccess.svg";
import FailHandler from "../../../component/failhandler";
import { Circles } from "react-loader-spinner";
import { handleUploadBusinessDocuments } from "api/fetchers";

const BusinessDocuments = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [fileUploadedTwo, setFileUpladedTwo] = useState(false);
    const [fileUploadedThree, setFileUploadedThree] = useState(false);
    const [fileUploadedFour, setFileUploadedFour] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [loader1, setloader1] = useState(false);
    const [loader2, setloader2] = useState(false);
    const [loader3, setloader3] = useState(false);
    const [fails, setfails] = useState("");
    const [isUploaded1, setisUploaded1] = useState(false);
    const [isUploaded2, setisUploaded2] = useState(false);

    useEffect(() => {
        if (fails) {
            let timerr = setTimeout(() => {
                setfails(false);
            }, 3000);

            return () => {
                clearTimeout(timerr);
            };
        }
    });

    const MAX_FILE_SIZE = 5 * 1024 * 1024;
    const formData = new FormData();

    const validateDocument = (file) => {
        if (!file) {
            setErrorMessage("No file selected.");
            return false;
        }
        if (file.size > MAX_FILE_SIZE) {
            setErrorMessage(`File size exceeds the limit of 5MB.`);
            setfails(true);
            return false;
        }
        setfails(false);
        return true;
    };

    const handleFileUpload = async (e, fileKey, loaderSetter, uploadedSetter, errorSetter) => {
        if (validateDocument(e.target.files[0])) {
            const file = e.target.files[0];
            setError(true);
            errorSetter(false);
            loaderSetter(true);

            try {
                const result = await handleUploadBusinessDocuments(
                    state?.businessId,
                    state?.businessType,
                    fileKey,
                    file
                );

                setError(false);
                loaderSetter(false);
                uploadedSetter(true);
            } catch (error) {
                setfails(true);
                setErrorMessage(error?.message || "An error occurred while uploading the file");
                loaderSetter(false);
                console.error(error);
            }
        } else {
            setErrorMessage("File size exceeds the limit of 5MB.");
            setError(false);
            loaderSetter(false);
        }
    };

    // Usage
    const handleFileTwo = (e) =>
        handleFileUpload(e, "CAC", setloader1, setFileUpladedTwo, setisUploaded1);

    const handleFileThree = (e) =>
        handleFileUpload(e, "CAC_STATUS_REPORT", setloader2, setFileUploadedThree, () => {});

    const handleFileFour = (e) =>
        handleFileUpload(e, "VALID_ID", setloader3, setFileUploadedFour, setisUploaded2);

    const skipNextPage = () => {
        if (state?.businessType == "SoleProprietorship") {
            navigate("/business/soleproprietor", {
                state: {
                    ...state,
                },
            });
        } else {
            navigate(`/upload-business-documents-LLC`, {
                state: {
                    ...state,
                },
            });
        }
    };
    const goNextPage = () => {
        if (fileUploadedTwo && fileUploadedFour) {
            // Adjust this condition based on required files
            if (state?.businessType == "SoleProprietorship") {
                navigate("/business/soleproprietor", {
                    state: {
                        ...state,
                    },
                });
            } else {
                navigate(`/upload-business-documents-LLC`, {
                    state: {
                        ...state,
                    },
                });
            }
        } else {
            setfails(true);
            setErrorMessage("Upload the required documents");
        }
    };

    return (
        <div className="bg-[#F6F8FA] pr-0 !max-w-[2000px] w-full mx-auto container-xxl">
            <Helmet>
                <title>Business Document</title>
            </Helmet>

            <LoginHeader width={55} />

            <div className="p-2 flex items-center justify-center h-screen">
                <div className="form-general-layout max-w-[591px] w-full">
                    <div className="flex flex-col gap-8">
                        <div className="flex justify-between items-center">
                            <ArrowBack />
                            <span
                                className="text-sm font-normal text-[#344054] cursor-pointer leading-[19.6px]"
                                onClick={skipNextPage}
                            >
                                Skip
                            </span>
                        </div>

                        <span className="text-sm text-[#667085] font-normal leading-[19.6px] relative block">
                            1 of 2
                        </span>
                        <div className="flex flex-col gap-1 relative">
                            <FailHandler success={fails} message={errorMessage} />

                            <div className="text-3xl font-normal text-[#101828] leading-[40px]">
                                Upload business documents
                            </div>
                            <span className="text-base text-[#667085] font-normal leading-6 tracking-[2%]">
                                We’ll take some time to verify your information.
                            </span>
                        </div>

                        <div className="bg-white rounded-lg">
                            <div className="relative !px-4 border-b !py-4 !border-[#E7E9FB]">
                                <input
                                    type="file"
                                    name="file1"
                                    id="file1"
                                    accept=".png, .jpg, .jpeg, .pdf"
                                    onChange={handleFileTwo}
                                    className="absolute inset-0 z-20 opacity-0 w-full"
                                />
                                <div className="flex items-center cursor-pointer gap-2 w-full">
                                    <img src={document} alt="" />
                                    <div className="flex justify-between w-full items-center">
                                        <div className="flex flex-col">
                                            <span className="font-semibold text-base text-[#101828] tracking-[2%] leading-6">
                                                Certificate of Incorporation
                                            </span>
                                            <span className="text-xs font-medium text-[#667085] leading-[16.2px]">
                                                From your country
                                            </span>
                                        </div>
                                        {loader1 === false ? (
                                            <div className="flex items-center gap-2">
                                                <div>
                                                    <p className="text-xs font-light text-gray-500">
                                                        JPEG, PNG, PDF
                                                    </p>
                                                    <p className="text-xs font-light text-gray-500">
                                                        File size: 5.0 MB
                                                    </p>
                                                </div>
                                                <span className="material-icons text-[#98A2B3]">
                                                    {fileUploadedTwo ? (
                                                        <img src={marksuccess} alt="" />
                                                    ) : (
                                                        "chevron_right"
                                                    )}
                                                </span>
                                            </div>
                                        ) : (
                                            <Circles
                                                height="15"
                                                width="15"
                                                color="rgb(111, 0, 255)"
                                                ariaLabel="circles-loading"
                                                visible={true}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="relative !px-4 !py-4 border-b border-[#E7E9FB] flex justify-center">
                                <input
                                    type="file"
                                    name="file1"
                                    accept=".png, .jpg, .jpeg, .pdf"
                                    id="file1"
                                    onChange={handleFileThree}
                                    className="absolute w-full h-full opacity-0 z-20"
                                />
                                <div className="flex items-center cursor-pointer gap-2 w-full">
                                    <img src={document} alt="" />
                                    <div className="flex justify-between items-center w-full">
                                        <div className="flex flex-col">
                                            <span className="font-semibold text-base text-[#101828] tracking-[2%] leading-6">
                                                CAC Status Report (Optional)
                                            </span>
                                            <div className="block text-xs text-[#667085] font-medium leading-[16.2px] mt-0">
                                                Within the last 3 years
                                            </div>
                                        </div>
                                        {loader2 === false ? (
                                            <div className="flex items-center gap-2">
                                                <div>
                                                    <p className="text-sm font-light text-gray-500">
                                                        JPEG, PNG, PDF
                                                    </p>
                                                    <p className="text-sm font-light text-gray-500">
                                                        File size: 5.0 MB
                                                    </p>
                                                </div>
                                                {fileUploadedThree ? (
                                                    <img src={marksuccess} alt="" />
                                                ) : (
                                                    <span className="material-icons text-[#98A2B3]">
                                                        chevron_right
                                                    </span>
                                                )}
                                            </div>
                                        ) : (
                                            <Circles
                                                height="15"
                                                width="15"
                                                color="rgb(111, 0, 255)"
                                                ariaLabel="circles-loading"
                                                visible={true}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="relative !px-4 !py-4 flex justify-center">
                                <input
                                    type="file"
                                    name="file1"
                                    accept=".png, .jpg, .jpeg, .pdf"
                                    id="file1"
                                    onChange={handleFileFour}
                                    className="absolute w-full h-full opacity-0 z-20"
                                />
                                <div className="flex items-center cursor-pointer gap-2 w-full">
                                    <img src={document} alt="" />
                                    <div className="flex justify-between items-center w-full">
                                        <div className="flex flex-col">
                                            <span className="font-semibold text-base text-[#101828] tracking-[2%] leading-6">
                                                Valid means of ID
                                            </span>
                                            <div className="block text-xs text-[#667085] font-medium leading-[16.2px] mt-0">
                                                Government-issued e.g. NIN
                                            </div>
                                        </div>
                                        {loader3 === false ? (
                                            <div className="flex items-center gap-2">
                                                <div>
                                                    <p className="text-sm font-light text-gray-500">
                                                        JPEG, PNG, PDF
                                                    </p>
                                                    <p className="text-sm font-light text-gray-500">
                                                        File size: 5.0 MB
                                                    </p>
                                                </div>
                                                {fileUploadedFour ? (
                                                    <img src={marksuccess} alt="" />
                                                ) : (
                                                    <span className="material-icons text-[#98A2B3]">
                                                        chevron_right
                                                    </span>
                                                )}
                                            </div>
                                        ) : (
                                            <Circles
                                                height="15"
                                                width="15"
                                                color="rgb(111, 0, 255)"
                                                ariaLabel="circles-loading"
                                                visible={true}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button
                            className="mt-4 bg-[#6F00FF] text-white text-base font-normal rounded-md max-w-[279px] w-full h-12 px-6 flex items-center justify-center"
                            onClick={goNextPage}
                        >
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BusinessDocuments;
