import { AddIcon, ArrowFilledIcon, ArrowIcon, LogoutIcon, UserIcon } from "asset/icons";
import axios from "axios";
import Avatar from "component/avatar";
import useGetRequest from "hooks/useGetRequest";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { LOGOUT_USER } from "store/action";
import { getFromStorage } from "utilities/localStorageUtils";

const ProfileDropdown = ({ hasBusinesses, tourStep, accountName, accountImg, accountNameArr }) => {
    const [openProfileDropdown, setOpenProfileDropdown] = useState(false);
    const [openAccountInfo, setOpenAccountInfo] = useState(false);
    const [switchInProgress, setSwitchInProgress] = useState(false); // Track switch progress
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [fetchBusinesses, businesses, _, __, setBusinesses] = useGetRequest();
    const [selectedBizId, setSelectedBizId] = useState();

    const businessesData = useMemo(() => {
        const _businesses = businesses?.filter(({ isDeleted }) => !isDeleted);

        if (selectedBizId) {
            const updatedBusinesses = [..._businesses];

            const findNewBiz = (biz) => biz._id === selectedBizId;
            const findOldBiz = (biz) => biz.isCurrent === true;

            const oldSelectedBizIdx = updatedBusinesses.findIndex(findOldBiz);
            const oldSelectedBiz = { ...updatedBusinesses.find(findOldBiz), isCurrent: false };

            const newSelectedBizIdx = updatedBusinesses.findIndex(findNewBiz);
            const newSelectedBiz = { ...updatedBusinesses.find(findNewBiz), isCurrent: true };

            updatedBusinesses[oldSelectedBizIdx] = oldSelectedBiz;
            updatedBusinesses[newSelectedBizIdx] = newSelectedBiz;
            setSwitchInProgress(true); // Start tracking the switch progress

            // Update the current business on the server
            axios.put(`/v1/business/current/${selectedBizId}`).then(() => {
                setBusinesses(updatedBusinesses);
                setSwitchInProgress(false); // Mark switch as complete
                setSelectedBizId(null);
            });

            setBusinesses(updatedBusinesses);
            setSelectedBizId(null);

            return {
                businesses: updatedBusinesses.filter(({ isCurrent }) => !isCurrent),
                selected: newSelectedBiz,
            };
        } else {
            return {
                businesses: _businesses?.filter(({ isCurrent }) => !isCurrent),
                selected: _businesses?.filter(({ isCurrent }) => isCurrent)[0],
            };
        }
    }, [selectedBizId, businesses]);

    useEffect(() => {
        if (!openProfileDropdown) {
            setOpenAccountInfo(false);
        } else {
            hasBusinesses && fetchBusinesses("/v1/business/user", null, false);
        }
    }, [openProfileDropdown, hasBusinesses]);

    useEffect(() => {
        if (!switchInProgress && selectedBizId === null) {
            // Reload the page only after switching is complete
            window.location.reload();
        }
    }, [switchInProgress, selectedBizId]);

    return (
        <div
            className={`flex items-center cursor-pointer *:cursor-default max-lg:px-6 max-lg:w-max ml-auto lg:ml-8 relative ${
                tourStep === 6 &&
                !getFromStorage("tdv-takenTour") &&
                "max-lg:mr-6 !bg-grey-bg !py-2 !px-4 rounded-lg"
            } tradevu-tour-step-6`}
            onClick={() => setOpenProfileDropdown((prev) => !prev)}
        >
            <Avatar
                img={accountImg}
                className={`${accountNameArr?.length > 3 && "text-[10px] "} size-[42px]`}
                fullName={accountName}
            />

            <span
                className={`text-main-dark font-[450] pointer-events-none ${
                    accountNameArr?.length > 2 && "text-sm"
                } ${
                    accountNameArr?.length > 2 && "max-w-[192px] 420:max-w-[250px] lg:max-w-[186px]"
                }`}
            >
                {accountName}
            </span>
            <ArrowIcon className="ml-3 pointer-events-none" />

            {/* Dropdown */}
            <div
                className={`bg-white w-[277px] absolute z-[100] right-6 lg:-right-1 rounded-lg py-2 px-[16px] shadow-dropdown2 transition-all duration-300 ${
                    openProfileDropdown ? "top-[62px] opacity-100" : "top-[50%] opacity-0 invisible"
                }`}
                onClick={(evt) => evt.stopPropagation()}
            >
                <div
                    className="bg-[#F2F4F7] py-2 px-3 rounded-lg flex gap-3 items-center mb-1 cursor-pointer"
                    onClick={() => setOpenAccountInfo((prev) => !prev)}
                >
                    <p className="text-main-dark">
                        Switch account
                        <span className="block text-xs text-main-grey mt-[3px]">
                            Access your other businesses
                        </span>
                    </p>
                    <ArrowFilledIcon className="size-7 translate-x-3" />
                </div>

                <Link
                    className="flex items-center gap-4 text-main-dark h-12 border-b"
                    to="/account/home"
                >
                    <UserIcon /> Manage account
                </Link>
                <button
                    className="flex items-center gap-4 text-main-dark h-12 cursor-pointer w-full"
                    onClick={() => LOGOUT_USER("", dispatch)}
                >
                    <LogoutIcon /> Logout
                </button>

                <AccountsInfo
                    openAccountInfo={openProfileDropdown && openAccountInfo}
                    businesses={businessesData.businesses}
                    selectedBiz={businessesData.selected}
                    setSelectedBizId={setSelectedBizId}
                />
            </div>
        </div>
    );
};

const AccountsInfo = ({ openAccountInfo, businesses, selectedBiz, setSelectedBizId }) => {
    const infoListRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        infoListRef?.current?.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        selectedBiz && dispatch({ type: "BUSINESS", payload: selectedBiz });
    }, [selectedBiz]);

    return (
        <div
            className={`py-2 bg-white absolute lg:right-[100.88%] w-[238px] rounded-lg shadow-dropdown2 transition-all duration-300 ${
                openAccountInfo ? "top-0 opacity-100" : "top-[50%] opacity-0 invisible"
            }`}
        >
            <div className="max-h-[200px] overflow-y-auto px-[16px]" ref={infoListRef}>
                <>
                    {selectedBiz && (
                        <BusinessInfo
                            name={selectedBiz?.name?.toLowerCase()}
                            className="mb-[7px] !py-1"
                            selected
                        />
                    )}
                    {businesses?.map((biz) => (
                        <BusinessInfo
                            key={biz._id}
                            name={biz.name.toLowerCase()}
                            className={`!py-2.5 border-t cursor-pointer`}
                            selected={biz?.isCurrent}
                            onClick={() => {
                                setSelectedBizId(biz._id); // Set the selected business ID
                            }}
                        />
                    ))}
                </>
            </div>

            <Link
                className={`!border !border-[#D9CDFF] rounded-lg flex items-center gap-2 w-[calc(100%-32px)] mx-[16px] py-2 justify-center !text-[#5E01D6] ${
                    selectedBiz ? "mt-2" : "mt-1"
                } ${businesses?.length && "mt-[11px]"}`}
                to={"/business-information"}
            >
                <AddIcon /> Add business
            </Link>
        </div>
    );
};

const BusinessInfo = ({ selected, img, name, onClick, className }) => {
    return (
        <div
            className={`items-center grid grid-cols-[42px_1fr] gap-1.5 ${className}`}
            onClick={onClick}
        >
            <Avatar
                img={img}
                className={`${
                    name?.split(" ")?.length > 3 && "text-[10px]"
                } size-[42px] self-start`}
                fullName={name}
            />

            {selected ? (
                <p className="capitalize text-sm">
                    {name}
                    <span className="block text-xs font-medium text-[#11A811]">Logged in</span>
                </p>
            ) : (
                <p className="capitalize text-sm text-[#475467]">{name}</p>
            )}
        </div>
    );
};

export default ProfileDropdown;
