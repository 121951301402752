/**
 * Checks if the user has logged in within the last month.
 * @returns {boolean} True if the last login was within the last 30 days, false otherwise.
 */
const hasLoggedInWithinLastMonth = () => {
    const lastLoginTimestamp = sessionStorage.getItem("lastLoginTimestamp");
    if (lastLoginTimestamp) {
        const lastLoginDate = new Date(lastLoginTimestamp);
        const oneMonthInMillis = 30 * 24 * 60 * 60 * 1000;
        return new Date() - lastLoginDate <= oneMonthInMillis;
    }
    return false;
};

export default hasLoggedInWithinLastMonth;
