import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import logotr from "../../../asset/images/tr.svg";
import world from "../../../asset/images/worldtradevu.svg";
import Loader from "../../../component/loader";
import FailHandler from "../../../component/failhandler";
import { LOGIN_USER } from "../../../store/action";
import "../../../style/index.css";
import hasLoggedInWithinLastMonth from "utilities/hasLoggedInWithinLastMonth";
import fetchUserLocation from "utilities/fetchUserLocation";
import AuthNav from "component/layout/AuthNav";
import { Bounce, toast } from "react-toastify";

const Login = () => {
    const dispatch = useDispatch();

    // State Variables
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loader, setLoader] = useState(false);
    const [showEmailLabel, setShowEmailLabel] = useState(false);
    const [showPasswordLabel, setShowPasswordLabel] = useState(false);
    const [sendAlert, setSendAlert] = useState(false);
    const [deviceType, setDeviceType] = useState("");
    const [address, setAddress] = useState(null);
    const [message, setMessage] = useState("");
    const [fails, setFails] = useState(false);

    useEffect(() => {
        const successMessage = sessionStorage.getItem("successMessage");
        if (successMessage) {
            toast.success(successMessage, {
                position: "top-center",
                autoClose: 5000,
                theme: "light",
                pauseOnHover: true,
                transition: Bounce,
            });
            sessionStorage.removeItem("successMessage");
        }
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoader(true);

        const data = {
            email,
            password,
            sendAlert: sendAlert.toString(),
            device: deviceType,
            location: address,
        };

        try {
            const res = await LOGIN_USER(data, dispatch);
            sessionStorage.setItem("lastLoginTimestamp", new Date().getTime());

            const user = res?.data?.data?.user;

            if (!user?.verification?.emailVerification?.isVerified) {
                return window.location.replace(
                    `/verify?email=${encodeURIComponent(user.email)}&code=${encodeURIComponent(
                        user.verification.emailVerification.code
                    )}&phoneNumber=${encodeURIComponent(user.phoneNumber)}`
                );
            }

            if (!user?.verification?.smsVerification?.isVerified) {
                return window.location.replace(
                    `/verify-number?phoneNumber=${
                        encodeURIComponent(user.phoneNumber) ||
                        encodeURIComponent(user.business?.registrationNumber)
                    }`
                );
            }

            if (user?.pinStatus === "NOT_VERIFIED") {
                return window.location.replace("/setpin");
            }

            window.location.replace("/dashboard");
        } catch (error) {
            console.error(error);
            setFails(true);
            setMessage(error.response?.data?.message || "An error occurred. Please try again.");
        } finally {
            setLoader(false);
        }
    };

    // Effects
    useEffect(() => {
        setSendAlert(!hasLoggedInWithinLastMonth());
    }, []);

    useEffect(() => {
        const getUserLocation = async () => {
            const location = await fetchUserLocation();
            setAddress(location);
        };
        getUserLocation();
        setDeviceType(navigator.userAgent);
    }, []);

    useEffect(() => {
        if (fails) {
            const timer = setTimeout(() => setFails(false), 3000);
            return () => clearTimeout(timer);
        }
    }, [fails]);

    return (
        <div className="main-login">
            <Helmet>
                <title>Tradevu — Login</title>
            </Helmet>

            <div className="containers-lo">
                {/* Header */}
                <AuthNav logo={logotr} />

                <div className="world-containers">
                    <div
                        className="la-card"
                        style={{ zIndex: "9999", position: "relative", marginLeft: "30px" }}
                    >
                        <FailHandler success={fails} message={message} />

                        <form className="form-con" onSubmit={handleLogin}>
                            <div className="text-welcome">Welcome back</div>
                            <div className="text-lgs">
                                Manage the finance and operations stack of your supply chain with
                                Tradevu.
                            </div>

                            {/* Input Fields */}
                            <div className="input-form">
                                <div className="each-form-input">
                                    {showEmailLabel && <label>Email Address</label>}
                                    <input
                                        type="email"
                                        placeholder="Email Address"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onFocus={() => setShowEmailLabel(true)}
                                    />
                                </div>

                                <div className="each-form-input">
                                    {showPasswordLabel && <label>Password</label>}
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        onFocus={() => setShowPasswordLabel(true)}
                                        autoComplete="off"
                                    />
                                </div>

                                <div className="keep_me_in_lg">
                                    <div className="box_sign_checkbox">
                                        <input type="checkbox" className="isChecked" />
                                        <span>Keep me signed in</span>
                                    </div>
                                    <Link
                                        to="/recovery/email_verify"
                                        className="forgotten_password"
                                    >
                                        Forgot password?
                                    </Link>
                                </div>
                            </div>

                            {/* Submit Button */}
                            <div className="lgn-btn-lg">
                                <button className="login-btn" type="submit" disabled={loader}>
                                    {loader ? <Loader /> : "Sign In"}
                                </button>
                                <span className="dont-have">
                                    I don’t have an account, <Link to="/register">Create one</Link>
                                </span>
                            </div>
                        </form>
                    </div>

                    <img
                        src={world}
                        alt="World Background"
                        className="world"
                        style={{ zIndex: "0" }}
                    />
                </div>
            </div>
        </div>
    );
};

export default Login;
