import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useApi } from "../../../hooks/useApi";
import SuccessHodler from "../../../component/successholder";
import FailHandler from "../../../component/failhandler";
import PinInput from "component/form/PinInput";
import LoginHeader from "component/loginheader";
import Loaders from "../../../component/loader";
import ArrowBack from "component/arrowback";
import { toast } from "react-toastify";

const VerifyNumber = () => {
    const { state } = useLocation();
    const [searchParams] = useSearchParams();
    const phoneNumberFromUrl = decodeURIComponent(searchParams.get("phoneNumber"));

    const [pins, setPins] = useState(["", "", "", ""]);
    const [loader, setLoader] = useState(false);
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false);
    const [message, setMessage] = useState("");

    const navigate = useNavigate();
    const { sendSmsVerification, verifySms } = useApi();

    useEffect(() => {
        if (success || fail) {
            const timer = setTimeout(() => {
                setSuccess(false);
                setFail(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [success, fail]);

    // Sanitize phone number to remove leading 0 after country code
    const sanitizePhoneNumber = (phoneNumber) => {
        if (phoneNumber.startsWith("+2340")) {
            return phoneNumber.replace("+2340", "+234");
        }
        return phoneNumber;
    };

    const sanitizedPhoneNumber = sanitizePhoneNumber(state?.phoneNumber || phoneNumberFromUrl);

    const handleVerify = async (e) => {
        e.preventDefault();
        setLoader(true);

        try {
            await verifySms(sanitizedPhoneNumber, pins.join(""));
            sessionStorage.setItem(
                "successMessage",
                "Registration successful! You can now log in to continue."
            );
            navigate("/", {
                state: {
                    ...state,
                    phoneNumber: sanitizedPhoneNumber,
                },
            });
        } catch (error) {
            console.error("Verification error:", error);
            setMessage(error.response?.data?.message || "An error occurred during verification.");
            setFail(true);
        } finally {
            setLoader(false);
        }
    };

    const ResendLink = async () => {
        setLoader(true);
        try {
            const response = await sendSmsVerification(sanitizedPhoneNumber);
            setMessage(response.data.message);
            setSuccess(true);
        } catch (error) {
            console.error("Resend error:", error);
            setMessage(
                error.response?.data?.message || "An error occurred while resending the code."
            );
            setFail(true);
        } finally {
            setLoader(false);
        }
    };

    return (
        <div
            className="container"
            style={{
                background: "#F6F8FA",
                paddingRight: "0px",
                maxWidth: "2000px",
                width: "100%",
                margin: "0px auto",
            }}
        >
            <LoginHeader width={55} />
            <div
                className="p-2 justify-self-center align-items-center d-flex"
                style={{ height: "calc(100% - 200px)" }}
            >
                <div
                    className="mt-20 form-general-layout"
                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        position: "relative",
                    }}
                >
                    <SuccessHodler message={message} success={success} />
                    <FailHandler message={message} success={fail} />
                    <div className="card-body">
                        <ArrowBack />
                        <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                            <div
                                style={{
                                    fontWeight: "600",
                                    color: "#101828",
                                    fontSize: "32px",
                                    lineHeight: "40px",
                                    marginTop: "20px",
                                }}
                            >
                                Verify Phone Number
                            </div>
                            <div
                                style={{
                                    fontSize: "16px",
                                    color: "#667085",
                                    letterSpacing: "2%",
                                    lineHeight: "24px",
                                    fontWeight: "400",
                                }}
                            >
                                Enter the 4-digit code sent to:{" "}
                                <span
                                    style={{
                                        color: "#6F00FF",
                                        fontWeight: "600",
                                        letterSpacing: "2%",
                                        lineHeight: "24px",
                                    }}
                                >
                                    {state?.phoneNumber || phoneNumberFromUrl}
                                </span>
                            </div>
                        </div>
                        <form
                            onSubmit={handleVerify}
                            style={{ display: "flex", flexDirection: "column", gap: "32px" }}
                        >
                            <PinInput pins={pins} setPins={setPins} />
                            <div
                                className=""
                                style={{
                                    fontSize: "14px",
                                    color: "#344054",
                                    fontWeight: "400",
                                    lineHeight: "19.6px",
                                }}
                            >
                                <span>
                                    Can’t find the code?{" "}
                                    <span
                                        type="button"
                                        style={{
                                            color: "#6F00FF",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            lineHeight: "19.6px",
                                            marginLeft: "5px",
                                            cursor: "pointer",
                                        }}
                                        onClick={ResendLink}
                                    >
                                        Resend
                                    </span>
                                </span>
                            </div>
                            <button
                                disabled={loader}
                                type="submit"
                                className="mr-2 btn"
                                style={{
                                    background: "#6F00FF",
                                    color: "#fff",
                                    width: "50%",
                                    fontSize: "1rem",
                                    borderRadius: "8px",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "2%",
                                    padding: "16px 24px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                }}
                            >
                                {loader ? <Loaders data={loader} /> : "Verify Number"}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyNumber;
