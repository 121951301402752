import LoginHeader from "../../../component/loginheader";
import "../../../style/index.css";
import { useState } from "react";
import ArrowBack from "../../../component/arrowback";
import Proff from "../../../asset/images/proof.png";
import Avater from "../../../asset/images/newavater.png";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import Loaders from "../../../component/loader";
import successmark from "../../../asset/images/Checkbox.svg";
import FailHandler from "../../../component/failhandler";
import SuccessHodler from "../../../component/successholder";

const ProofofAddress = () => {
    const [fileName, setfileName] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadfinish, setUploadFinish] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [billType, setBillType] = useState("Utility Bill");
    const [isChecked, setisChecked] = useState(false);
    const [selected, setSelected] = useState(0);
    const [loader, setLoader] = useState(false);
    const [fails, setfails] = useState("");
    const [success, setsuccess] = useState("");
    const [message, setmessage] = useState("");
    const { state } = useLocation();

    const navigate = useNavigate();
    const data = [
        {
            name: "Utility Bill",
            id: "1",
        },
        {
            name: "Electricity Bill",
            id: "2",
        },
        {
            name: "Tax Bill",
            id: "3",
        },
    ];

    const handleUpload = (e) => {
        const file = e.target.files[0];
        const maxSize = 5 * 1024 * 1024;

        if (!file) return;

        if (file.size > maxSize) {
            setfails(true);
            setmessage("File size exceeds the maximum limit of 5MB.");
            setfileName(null);
            setUploadFinish(false);
            return;
        }

        setfails(false);
        setmessage("");
        setfileName(file);
        setUploadFinish(true);
    };

    const checkclick = (bill, index) => {
        setSelected(index);
        setBillType(bill.name);
        setModalShow(false);

        isChecked ? setisChecked(false) : setisChecked(true);
    };

    const submitDocument = async (e) => {
        e.preventDefault();
        setLoader(true);
        setUploading(true);

        const formData = new FormData();
        formData.append("file", fileName, fileName.name);
        formData.append("uploadType", billType);

        axios
            .post(`/v1/business/upload-proof-of-address/${state.businessId}`, formData)
            .then(() => {
                setUploadFinish(true);

                navigate(`/upload-business-document`, {
                    state: {
                        ...state,
                    },
                });
            })
            .catch((e) => {
                setmessage(e.response?.data.message ? e.response?.data.message : "An error occur");
                setfails(true);
                setLoader(false);
            });
    };

    return (
        <div className="bg-[#F6F8FA] pr-0 max-w-[2000px] w-full mx-auto">
            <LoginHeader width={45} />
            <Helmet>
                <title>Proof of Address</title>
            </Helmet>
            <div className="p-2 self-center items-center flex h-[calc(100%-100px)] bg-[#F6F8FA]">
                <div className="form-general-layout relative">
                    <FailHandler success={fails} message={message} />
                    <SuccessHodler success={success} message={message} />
                    <div className="card-body flex flex-col gap-8">
                        <div className="flex justify-between items-center">
                            <ArrowBack />
                            <span
                                className="text-[13px] font-semibold text-[#344054] !pt-1 cursor-pointer"
                                onClick={() => {
                                    navigate(`/dashboard`);
                                }}
                            >
                                Skip
                            </span>
                        </div>
                        <div className="flex flex-col gap-1">
                            <h1 className="text-3xl font-semibold leading-[40px] text-[#101828] m-0">
                                Upload proof of address
                            </h1>
                            <span className="text-[#667085]text-lg">
                                Select a document type and upload below
                            </span>
                        </div>

                        <form
                            onSubmit={submitDocument}
                            encType="multipart/form-data"
                            className="flex flex-col gap-8"
                        >
                            <div className="flex flex-col gap-8">
                                <div className="relative rounded-lg">
                                    <input
                                        className="py-2 h-14 bg-[#ECEFF3] rounded-lg border-0 form-control"
                                        onClick={() => {
                                            setModalShow(true);
                                        }}
                                        value={billType}
                                    />
                                    <span className="material-icons absolute right-5 top-5 text-[#101828]">
                                        expand_more
                                    </span>
                                    <Modal
                                        show={modalShow}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                        className="myBilltype"
                                    >
                                        <Modal.Header>
                                            <span
                                                className="material-icons cursor-pointer"
                                                onClick={() => setModalShow(false)}
                                            >
                                                close
                                            </span>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="!font-bold !text-lg !pt-2 !pb-5 text-start">
                                                Select document type
                                            </div>
                                            <div className="card border-0 bg-white">
                                                {data.map((bill, index) => (
                                                    <div
                                                        className="card-body p-0"
                                                        key={index}
                                                        onClick={() => {
                                                            checkclick(bill, index);
                                                        }}
                                                    >
                                                        <div className="flex gap-3 p-2 items-center">
                                                            <div
                                                                className={`flex justify-between w-full overflow-hidden items-center p-[6px_8px] rounded-lg ${
                                                                    selected === index
                                                                        ? "bg-[#F4F0FF]"
                                                                        : ""
                                                                }`}
                                                            >
                                                                <div>
                                                                    <span className="!font-semibold !text-md text-[#101828]">
                                                                        {bill.name}
                                                                    </span>
                                                                </div>
                                                                {selected === index && (
                                                                    <img src={successmark} alt="" />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </div>
                                <div className="flex flex-col gap-4">
                                    <div className="flex flex-col gap-1">
                                        <div className="card border-0">
                                            <div className="flex items-center h-14 !p-4 rounded-lg !gap-3">
                                                <img src={Proff.toString()} alt="" width={20} />
                                                <span className="overflow-hidden text-sm leading-[19.6px] font-normal text-[#667085]">
                                                    Your proof of address should clearly show your
                                                    business name, and address.
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    {uploading === false && uploadfinish === false && (
                                        <div className="card !bg-[#ECEFF3] !h-[172px] !p-6 !border !border-dashed !border-[#C0CAD8] !relative">
                                            <input
                                                type="file"
                                                accept=".png, .jpg, .jpeg, .pdf"
                                                className="absolute inset-0 w-full h-full opacity-0 z-10 cursor-pointer"
                                                required
                                                onChange={handleUpload}
                                            />
                                            <div className="card-body absolute inset-0 flex flex-col justify-center items-center">
                                                <div className="flex justify-center items-center">
                                                    <img
                                                        src={Avater}
                                                        alt=""
                                                        width={50}
                                                        className="mx-auto"
                                                    />
                                                </div>
                                                <div className="text-center text-[#101828] !text-md !font-medium">
                                                    Drag or Upload file here
                                                </div>
                                                <div className="flex flex-col items-center !mt-1">
                                                    <span className="text-center text-xs text-[#667085] leading-[16.2px] tracking-[2%] font-normal">
                                                        JPEG, PNG, JPG, PDF
                                                    </span>
                                                    <span className="text-center text-xs text-[#667085] leading-[16.2px] tracking-[2%] font-normal">
                                                        Max. file size: 5.0 MB
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {uploading && uploadfinish === false && (
                                        <div className="!mt-2 !bg-[#ECEFF3]">
                                            <div className="card-body">
                                                <div className="flex justify-center items-center">
                                                    <img src={loader} width={20} alt="Loading..." />
                                                    <span className="!text-sm !ml-2">
                                                        Uploading....
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {uploadfinish && (
                                        <div className="card !mt-2 !bg-[#ECEFF3] !border !border-dashed !border-[#C0CAD8] !p-4">
                                            <div className="relative card-body !p-0">
                                                <input
                                                    type="file"
                                                    accept=".png, .jpg, .jpeg, .pdf"
                                                    className="absolute inset-0 w-full h-full opacity-0 z-10 cursor-pointer"
                                                    onChange={handleUpload}
                                                />
                                                <div className="flex justify-center items-center">
                                                    <span className="!text-[#6F00FF] !font-semibold !ext-lg">
                                                        {fileName.name} -{" "}
                                                        {parseFloat(fileName.size / 1025).toFixed(
                                                            2
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <button
                                disabled={loader}
                                type="submit"
                                className={`flex justify-center items-center w-full rounded-md !max-w-[279px] bg-[#6F00FF] text-white text-base font-semibold !py-4 !px-6 !mb-7 ${
                                    loader ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
                                }`}
                            >
                                {loader === true ? <Loaders data={loader} /> : "Continue"}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProofofAddress;
