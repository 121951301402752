import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../loader";
import yay from "../../../asset/images/yay.svg";
import "../../../style/main.css";
import "../../../style/rightside.css";

const PINSubmission = ({ pinCode }) => {
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const user = useSelector((state) => state.users);
    const navigate = useNavigate();

    const handlePINSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        const pin = pinCode.join("");

        try {
            await axios.post("/v1/users/create-pin", { pin });
            setShowModal(true);
        } catch (error) {
            Swal.fire({
                title: "Error!",
                text: error.response?.data?.message || "An error occurred",
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#6F00FF",
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <button
                disabled={isLoading}
                onClick={handlePINSubmit}
                type="submit"
                className="btn btn-primary w-3/5"
                style={{
                    background: "#6F00FF",
                    color: "#fff",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    padding: "16px 24px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {isLoading ? <Loader /> : "Set PIN"}
            </button>

            <Modal className="primaryModal" show={showModal} backdrop="static" keyboard={false}>
                <Modal.Body style={{ border: "none !important" }}>
                    <div>
                        <div className="w-100 ">
                            <img src={yay} alt="" />
                        </div>{" "}
                        <div
                            className="w-100 !py-10"
                            style={{
                                boxSizing: "border-box",
                                overflow: "hidden",
                                left: "0",
                                padding: "24px",
                            }}
                        >
                            <p
                                style={{
                                    color: "#D9CDFF",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                }}
                            >
                                Hi {user?.firstName || "User"},
                            </p>
                            <h4
                                style={{
                                    color: "#FFFFFF",
                                    fontSize: "24px",
                                    lineHeight: "32.4px",
                                }}
                            >
                                Welcome to Tradevu!
                            </h4>
                            <p
                                style={{
                                    color: "#EBE4FF",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    lineHeight: "19.6px",
                                }}
                            >
                                Before you continue, please complete your KYC information.
                            </p>
                            <button
                                onClick={() => navigate("/business-information")}
                                className="btn btn-light w-100 mt-2"
                                style={{
                                    color: "#6F00FF",
                                    background: "#F4F0FF",
                                    padding: "16px 24px",
                                    fontWeight: "700",
                                }}
                            >
                                Continue
                            </button>
                            <button
                                onClick={() => navigate("/dashboard")}
                                className="btn btn-dark w-100 mt-2"
                                style={{
                                    color: "#EBE4FF",
                                    background: "#5E01D6",
                                    padding: "16px 24px",
                                    fontWeight: "700",
                                }}
                            >
                                Enter Tradevu
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PINSubmission;
