import React, { useRef } from "react";

const PinInput = ({ pins, setPins }) => {
    const inputs = useRef([]);

    const handleInputChange = (event, index) => {
        const { value } = event.target;
        if (/^\d$/.test(value)) {
            const newPins = [...pins];
            newPins[index] = value;
            setPins(newPins);

            if (index < pins.length - 1) {
                inputs.current[index + 1]?.focus();
            }
        }
    };

    const handleKeyDown = (event, index) => {
        if (event.key === "ArrowRight" && index < pins.length - 1) {
            inputs.current[index + 1]?.focus();
        } else if (event.key === "ArrowLeft" && index > 0) {
            inputs.current[index - 1]?.focus();
        } else if (event.key === "Backspace") {
            if (pins[index] === "") {
                if (index > 0) {
                    inputs.current[index - 1]?.focus();
                }
            } else {
                const newPins = [...pins];
                newPins[index] = "";
                setPins(newPins);
            }
        }
    };

    return (
        <div className="mt-4 row" style={{ maxWidth: "350px", width: "100%" }}>
            {pins.map((pin, index) => (
                <div className="col" key={index}>
                    <div className="form-group">
                        <input
                            type="text"
                            className="border-0 form-control"
                            style={{
                                background: "#ECEFF3",
                                color: "#667085",
                                padding: "8px 16px",
                                textAlign: "center",
                                height: "53px",
                                minWidth: "56px",
                                fontWeight: "400",
                            }}
                            maxLength="1"
                            placeholder="-"
                            value={pin}
                            ref={(el) => (inputs.current[index] = el)}
                            onChange={(event) => handleInputChange(event, index)}
                            onKeyDown={(event) => handleKeyDown(event, index)}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default PinInput;
