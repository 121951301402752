import axios from "axios";
import { ENDPOINTS } from "constants/endpoints";

export const handleFetchAllBanks = async (currency) => {
    try {
        const resp = await axios.get(ENDPOINTS.getAllBanks(currency));
        return resp.data;
    } catch (error) {
        return error;
    }
};

export const handleVerifyAccount = async (accountNumber, bankCode) => {
    try {
        const resp = await axios.post(ENDPOINTS.verifyAccount(accountNumber, bankCode), {
            accountNumber,
            bankCode,
        });
        return resp.data;
    } catch (error) {
        return error;
    }
};

// Ask for the update on the transaction fee
export const getTransactionFee = async () => {
    try {
        const response = await axios.get(ENDPOINTS.getTransactionFee);
        return response?.data;
    } catch (error) {
        return error;
    }
};

export const handleTransferFunds = async (isLocalTransfer, data) => {
    try {
        const response = await axios.post(
            isLocalTransfer ? ENDPOINTS.transferFunds : ENDPOINTS.internationalTransfer,
            data
        );
        return response?.data;
    } catch (error) {
        return error;
    }
};

export const handleVerifyPin = async (data) => {
    try {
        const response = await axios.post(ENDPOINTS.verifyPin, data);
        return response?.data;
    } catch (error) {
        return error;
    }
};

export const handleSaveBeneficiary = async (data) => {
    try {
        const response = await axios.post(ENDPOINTS.saveBeneficiary, data);
        return response?.data;
    } catch (error) {
        return error;
    }
};

export const handleGetBeneficiary = async (currency) => {
    try {
        const response = await axios.get(ENDPOINTS.getBeneficiary(currency));
        return response?.data;
    } catch (error) {
        return error;
    }
};

export const handleGetInvoiceSummary = async (data) => {
    try {
        const response = await axios.get(ENDPOINTS.getInvoiceSummary, {
            headers: {
                "x-business-id": data.businessId,
            },
            params: {
                currency: data.currency,
            },
        });
        return response?.data?.data;
    } catch (error) {
        return error;
    }
};

export const handleGetAllInvoice = async (data) => {
    try {
        const response = await axios.get(ENDPOINTS.getAllInvoice, {
            headers: {
                "x-business-id": data.businessId,
            },
            params: {
                currency: data.currency,
            },
        });
        return response?.data?.data;
    } catch (error) {
        return error;
    }
};
export const handleGetAllUsers = async (data) => {
    try {
        const response = await axios.get(ENDPOINTS.getAllUsers(data?.type), {
            headers: {
                "x-business-id": data.businessId,
            },
        });
        return response?.data?.data;
    } catch (error) {
        return error;
    }
};

export const handleGetSingleUsers = async (data) => {
    try {
        const response = await axios.get(ENDPOINTS.getSingleUser(data?.id), {
            headers: {
                "x-business-id": data.businessId,
            },
        });
        return response?.data?.data;
    } catch (error) {
        return error;
    }
};

export const handleFetchStats = async (data) => {
    try {
        const response = await axios.get(ENDPOINTS.getStats, {
            headers: {
                "x-business-id": data.businessId,
            },
        });
        return response?.data;
    } catch (error) {
        return error;
    }
};

export const handleCreateBusiness = async (data) => {
    try {
        const response = await axios.post(ENDPOINTS.createBusiness, data);
        return response?.data;
    } catch (error) {
        throw error?.response?.data || { message: "An error occurred" };
    }
};

export const handleUploadBusinessDocuments = async (businessId, businessType, fileKey, file) => {
    try {
        const formData = new FormData();
        formData.append(fileKey, file, file.name);

        const response = await axios.post(
            ENDPOINTS.uploadBusinessDocuments(businessId, businessType),
            formData
        );
        return response?.data;
    } catch (error) {
        throw error?.response?.data || { message: "An error occurred while uploading the file" };
    }
};

export const handleCheckEmail = async (email) => {
    try {
        const response = await axios.post(`/v1/users/check-email`, { email });
        return response?.data;
    } catch (error) {
        throw error?.response?.data || { message: "An error occurred while checking the email" };
    }
};

// Function to register a user
export const handleRegisterUser = async (data) => {
    try {
        const response = await axios.post("/v1/users/register", data);
        return response?.data;
    } catch (error) {
        throw error?.response?.data || { message: "An error occurred during registration" };
    }
};

// Function to send verification email
export const handleSendVerificationEmail = async (email) => {
    try {
        const response = await axios.post("/v1/users/send-verification-email", { email });
        return response?.data;
    } catch (error) {
        throw error?.response?.data || { message: "An error occurred while sending the email" };
    }
};

export const handleUploadFile = async (businessId, businessType, fileKey, file) => {
    try {
        const formData = new FormData();
        formData.append(fileKey, file, file.name);

        const response = await axios.post(
            ENDPOINTS.uploadBusinessDocuments(businessId, businessType),
            formData
        );

        return response?.data;
    } catch (error) {
        throw error?.response?.data || { message: "An error occurred while uploading the file" };
    }
};

export const handleUpdateBusiness = async (businessId) => {
    try {
        const response = await axios.put(`/v1/business/current/${businessId}`);
        return response?.data;
    } catch (error) {
        throw error?.response?.data || { message: "An error occurred while updating the business" };
    }
};

export const handleRequestVerification = async () => {
    try {
        const response = await axios.post(`/v1/business/request-verification`);
        return response?.data;
    } catch (error) {
        throw error?.response?.data || { message: "An error occurred during verification request" };
    }
};
