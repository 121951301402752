import { Helmet } from "react-helmet";
import DashHeader from "../component/dashheader";
import homefinance from "../asset/images/accountsvg.svg";
import invoice from "../asset/images/invoice.svg";
import copy from "../asset/images/copy.svg";
import edit from "../asset/images/edit-2.svg";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CardDetails from "../component/carddetails";
import imageDelete from "../asset/images/delete_icon.svg";
//import imageDelete from "../asset/images/de";
import Card from "../component/cards";
import checkbox from "../asset/images/account/checkbox.png";
import search from "../asset/images/search-normal.svg";
import busiesssuccess from "../asset/businessmage/marksuccess.svg";
import busiessscancel from "../asset/businessmage/cancelsuccess.svg";
import loadingbusiness from "../asset/businessmage/load.svg";

import personal from "../asset/images/account/personal.svg";
import business from "../asset/images/account/business.svg";
import beneficiary from "../asset/images/account/beneficiary.svg";
import balance from "../asset/images/account/balance.svg";
import { useDispatch, useSelector } from "react-redux";
import upload from "../asset/images/account/upload.png";
import closeaccount from "../asset/images/apikeys.svg";
import redcheckbox from "../asset/images/account/redcheckbox.png";
import logout from "../asset/images/account/logout.png";
import CloseAccount from "../component/closeaccountmodal";
import Swal from "sweetalert2";
import { CLOSE_ACCOUNT, LOGOUT_USER } from "../store/action";
import dot from "../asset/images/point.svg";
import axios from "axios";
import Loading from "../component/loading";
import SuccessHodler from "../component/successholder";
import Loader from "../component/loader";
import FailHandler from "../component/failhandler";
import Security from "../component/security";
import Help from "../component/help";
import UpdateBusinessModal from "../component/updatebusinessmodal";
import check from "../asset/images/marksuccess.svg";
import UtitlityDocument from "../component/utitlityDocument";
import UtitltyType from "../component/utilitytype";
import DirectorModalBvn from "../component/directorbvn";
import ApiModal from "../component/apiModal";
import ApiModalSuccess from "../component/apisuccessmodal";
import BankRequest from "../component/bankrequestmodal";
import CloseAccountModals from "../component/closeAccountModals";
import CloseAccountSuccess from "../component/closeAccountSuccess";
import ProfileUpdateModal from "../component/profileUpdatesModal";
import DeleteAccounts from "../component/DeleteAccount";
import Navbar from "component/layout/navbar";

const AccountHome = () => {
    // const navigate = useNavigate()

    const [mydate, setmydate] = useState(new Date());
    const [isDashboard, setisDashboard] = useState(false);
    const [isActivities, setisActivities] = useState(false);
    const [isInvoice, setisInvoice] = useState(true);
    const [isTermination, setisTermination] = useState(false);
    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [buyers] = useState([]);
    const [suppliers] = useState([]);
    const [searchbanks, setsearchbanks] = useState("");
    const [profiletab, setprofiletab] = useState(0);
    const [ischecked, setischecked] = useState(false);
    const [loader, setloader] = useState(false);
    const [showModal3, setshowModal3] = useState(false);
    const [mode, setmode] = useState("");
    const selector = useSelector((state) => state.users);
    const selectorbusiness = useSelector((state) => state.business);
    const [allBeneficiary, setAllBeneficiary] = useState([]);
    const [benloader, setbenloader] = useState(true);
    const [show, setshow] = useState(false);
    const [searchbank, setsearchbank] = useState("");
    const [showtype, setshowtype] = useState(false);
    const [showsuccess, setshowsuccess] = useState(false);
    const [fail, setfail] = useState(false);
    const [userModal, setuserModal] = useState(false);
    const toggledetail = [
        "Personal details",
        "Business details",
        "Saved beneficiary",
        "Hide balance",
    ];
    const terminations = ["API Keys"];
    const [selectedtoggle, setselectedtoggle] = useState(0);
    const [selectedtermination, setselectedtermination] = useState(0);
    const [password, setpassword] = useState("");
    const [reason, setreason] = useState("");
    const [searchBen, setsearchBen] = useState("");
    const [showapi, setshowapi] = useState(false);
    const [successholder, setsuccessholder] = useState(false);
    const [message, setmessage] = useState("");
    const [state, setstate] = useState(selector.state);
    const [document, setdocument] = useState([]);
    const [means, setmeans] = useState("NATIONAL_ID");
    const [address, setaddress] = useState(selector.address);
    const dispatch = useDispatch();
    const [loaderupdate, setloaderupdate] = useState(false);
    const [failhandler, setfailhandler] = useState(false);
    const [filename, setfilename] = useState([]);
    const [filesize, setfilesize] = useState(0);
    const [showmodalbusiness, setshowmodalbusiness] = useState(false);
    const [title, settitle] = useState("");
    const [businessindex, setbusinesindex] = useState(0);
    const [loaderrc, setloadercc] = useState(false);
    const [loading, setloading] = useState(false);
    const [loadeddocument, setlodaedocument] = useState([]);
    const [businessDocument, setBusinessDocument] = useState([]);
    const [showdoucment, setshowdocument] = useState(false);
    const [name, setname] = useState("Utility Bill");
    const [director, setDirector] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [order, setOrder] = useState("ASC");
    const [selectedIndex, setselectedIndex] = useState(0);
    const [showrequeststatment, setshowrequestStatement] = useState(false);
    const [datasss, setData] = useState([]);

    const [fileUploadedBvn, setfileUploadedBvn] = useState(false);
    const [showwallet, setshowallet] = useState(false);
    const [api, setapi] = useState(selector?.apiKey);
    const [showModal6, setshowModal6] = useState(false);
    const [url, seturl] = useState(selector?.webhookURL);
    const [loading2, setloading2] = useState(false);
    const [showModal5, setshowModal5] = useState(false);
    //console.log(selectorbusiness?.type);

    const documenttype = ["NATIONAL_ID", "DRIVERS_LICENSE", "INTERNATIONAL_PASSPORT"];
    const sorting = (col, index) => {
        setselectedIndex(index);
        if (order === "ASC") {
            const sorted = [...datasss].sort((a, b) =>
                a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
            );
            //    console.log(sorted);
            setData(sorted);
            setOrder("DSC");
        }
        if (order === "DSC") {
            const sorted = [...datasss].sort((a, b) =>
                a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
            );
            setData(sorted);
            setOrder("ASC");
        }
    };

    useEffect(() => {
        const fetchBusiness = async () => {
            await axios
                .get(`/v1/business/${selectorbusiness._id}`)
                .then((res) => {
                    setDirector(
                        selectorbusiness.type === "LLC"
                            ? res.data.data.business?.LLC?.BVN_OF_DIRECTOR
                            : selectorbusiness.type === "SoleProprietorship"
                            ? res.data.data.business?.SoleProprietorship?.BVN_OF_PARTNER
                            : []
                    );
                    setBusinessDocument(res.data.data.business);
                })
                .catch((e) => {
                    console.log(e);
                });
        };
        fetchBusiness();
    }, []);

    // console.log(document);
    const Logout = async (value) => {
        if (value === 1) {
            LOGOUT_USER("", dispatch)
                .then((res) => {
                    navigate("/");
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    };
    useEffect(() => {
        let timer = setTimeout(() => {
            setsuccessholder(false);
        }, 3000);

        return () => {
            clearTimeout(timer);
        };
    });
    useEffect(() => {
        let timer = setTimeout(() => {
            setfailhandler(false);
        }, 3000);

        return () => {
            clearTimeout(timer);
        };
    });
    const uploadImage = async (value) => {
        const formdata = new FormData();
        formdata.append("profileImage", value, value.name);
        await axios
            .put("/v1/users/update?uploadType=profileImage", formdata)
            .then((res) => {
                // console.log(res);
                setmessage(res.data.message);
                setsuccessholder(true);
            })
            .catch((e) => {
                console.log(e);
                setmessage(e.response.data.message);
                setfailhandler(true);
            });
    };
    const uploadImageBusiness = async (value) => {
        const formdata = new FormData();
        formdata.append("file", value, value.name);
        await axios
            .post(`/v1/business/upload-business-image/${selectorbusiness?._id}`, formdata)
            .then((res) => {
                setmessage(res.data.message);
                setsuccessholder(true);
            })
            .catch((e) => {
                console.log(e);
                setmessage(
                    e?.response?.data.message
                        ? e.response?.data.message
                        : "An error occurred, please try again"
                );
                setfailhandler(true);
            });
    };
    const updateProfile = async (e) => {
        e.preventDefault();
        setloaderupdate(true);

        const formdata = new FormData();
        formdata.append("idType", means);
        formdata.append("state", state);
        formdata.append("address", address);
        formdata.append("validId", document, document.name);

        await axios
            .put(`/v1/users/update?uploadType=validId`, formdata)
            .then((res) => {
                // console.log(res)
                setmessage(res.data.message);
                setsuccessholder(true);
                setloaderupdate(false);
            })
            .catch((e) => {
                console.log(e);
                setloaderupdate(false);
                setmessage(e.response.data.message);
                setfailhandler(true);
            });
    };
    const hideBalance = async () => {
        setischecked(!ischecked);
        const data = {
            hideBalance: ischecked.toString(),
        };
        await axios
            .post("/v1/users/hide-balance", data)
            .then(() => {
                //console.log(res);
            })
            .catch((e) => {
                console.log(e);
            });
    };
    // console.log(selectorbusiness);
    // console.log(loadeddocument);
    const copyURL1 = async (mytext, key) => {
        try {
            await navigator.clipboard.writeText(mytext);
            setshowsuccess(true);
            setmessage(`${key} Copied successfully`);

            setTimeout(() => {
                setshowsuccess(false);
            }, 2000);
        } catch ($e) {
            console.log($e);
        }
    };
    const generateApiKey = async () => {
        setloading(true);
        await axios
            .put(`/v1/users/update-api-key`)
            .then((res) => {
                // console.log(res);
                setloading(false);
                setshowapi(true);
                setapi(res.data.data.apiKey);
            })
            .catch((e) => {
                console.log(e);
                setloading(false);
            });
    };
    // console.log(selectorbusiness.type);
    const generateWebhook = async (e) => {
        e.preventDefault();
        setloading2(true);
        await axios
            .put(`/v1/users/update-webhook-url`, {
                webhookURL: url,
            })
            .then((res) => {
                // console.log(res);
                setloading2(false);
                // setshowapi(true);
                setapi(res.data.data.url);
            })
            .catch((e) => {
                console.log(e);
                setloading2(false);
            });
    };
    const updateBusinessDetails = async () => {
        //  setloadercc(true);
        setloading(true);
        const formData = new FormData();
        if (businessindex === 3) {
            formData.append("file", filename, filename.name);
            formData.append("uploadType", name);

            axios
                .post(`/v1/business/upload-proof-of-address/${selectorbusiness?._id}`, formData)
                .then((res) => {
                    //  console.log(res);
                    setlodaedocument((prevArray) => [...prevArray, businessindex]);
                    setloadercc(true);
                })
                .catch((e) => {
                    console.log(e);

                    // setLoader(false);
                    // navigate(`/business/type/${id}`)
                });
        } else {
            formData.append(
                businessindex === 0
                    ? "CAC"
                    : businessindex === 1
                    ? "CAC_STATUS_REPORT"
                    : businessindex === 2
                    ? "VALID_ID"
                    : businessindex === 17
                    ? "APPLICATION_LETTER"
                    : businessindex === 4
                    ? "CAC_FORM_2"
                    : businessindex === 5
                    ? "CAC_FORM_2_1"
                    : businessindex === 6
                    ? "CAC_FORM_3"
                    : businessindex === 7
                    ? "CAC_FORM_4"
                    : businessindex === 8
                    ? "CAC_FORM_7"
                    : businessindex === 9
                    ? "MEMORANDUM_AND_ARTICLE_OF_ASSOCIATION"
                    : businessindex === 10
                    ? "BOARD_RESOLUTION"
                    : businessindex === 15
                    ? "CAC"
                    : "",
                filename,
                filename.name
            );
            await axios
                .post(
                    `v1/business/upload-business-documents/${selectorbusiness._id}?businessType=${selectorbusiness?.type}`,
                    formData
                )
                .then(() => {
                    //setlodaedocument(loadeddocument.push(businessindex));
                    setlodaedocument((prevArray) => [...prevArray, businessindex]);

                    setloadercc(true);
                    // console.log(res);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    };
    const closeAccountDetails = async (e) => {
        setloader(true);
        e.preventDefault();
        const data = {
            //  reason: reason,
            password: password,
        };
        CLOSE_ACCOUNT(data, dispatch)
            .then((res) => {
                navigate("/");
            })
            .catch((e) => {
                // console.log(e);
                Swal.fire({
                    title: "Unable to close account",
                    text: e.response.data.message,
                    icon: "error",
                    confirmButtonText: "OK",
                    confirmButtonColor: "rgb(111, 0, 255)",
                    width: "280px",
                }).then((res) => {
                    if (res.value) {
                        setloader(false);
                    }
                });
            });
    };
    useEffect(() => {
        const fectchAllbeneficiary = async () => {
            await axios
                .get("/v1/beneficiary")
                .then((res) => {
                    setAllBeneficiary(res.data);
                    setbenloader(false);
                })
                .catch((e) => {
                    console.log(e);
                    setbenloader(false);
                });
        };
        fectchAllbeneficiary();
    }, []);

    const filterBuyer = buyers.filter(
        (item) =>
            item.firstName.match(searchbank) ||
            item.lastName.match(searchbank) ||
            item.businessName.match(searchbank)
    );
    //console.log(allBeneficiary)
    const filterBeneficiary = allBeneficiary.filter(
        (item) => item.name.match(searchBen) || item.accountNumber.match(searchBen)
    );

    filterBuyer.sort((a, b) =>
        a.firstName.localeCompare(b.firstName, "es", { sensitivity: "base" })
    );
    let data = filterBuyer.reduce((r, e) => {
        let alphabet = e.firstName[0];

        if (!r[alphabet])
            r[alphabet] = {
                alphabet,
                record: [],
            };

        r[alphabet].record.push(e);
        return r;
    }, {});
    let result = Object.values(data);

    const filterSupplier = suppliers.filter(
        (item) =>
            item.firstName.match(searchbanks) ||
            item.lastName.match(searchbanks) ||
            item.businessName.match(searchbanks)
    );

    filterSupplier.sort((a, b) =>
        a.firstName.localeCompare(b.firstName, "es", { sensitivity: "base" })
    );
    let datas = filterSupplier.reduce((r, e) => {
        let alphabet = e.firstName[0];

        if (!r[alphabet])
            r[alphabet] = {
                alphabet,
                record: [e],
            };

        r[alphabet].record.push(e);
        return r;
    }, {});
    let results = Object.values(datas);

    filterBeneficiary.sort((a, b) => a.name.localeCompare(b.name, "es", { sensitivity: "base" }));
    let databen = filterBeneficiary.reduce((r, e) => {
        let alphabet = e.name[0];

        if (!r[alphabet])
            r[alphabet] = {
                alphabet,
                record: [],
            };

        r[alphabet].record.push(e);
        return r;
    }, {});
    let resultben = Object.values(databen);

    const [cards] = useState([]);
    const navigate = useNavigate();

    const weekday = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
    let day = weekday[mydate.getDay()];

    const month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "december",
    ];

    useEffect(() => {
        const timer = setInterval(() => {
            setmydate(new Date());
        }, 50000);
        return () => {
            clearInterval(timer);
        };
    });

    let months = month[mydate.getMonth()];
    let year = mydate.getFullYear();
    let today = mydate.getDate();
    let hrs = mydate.getHours();
    let min = mydate.getMinutes();
    /*
  const deletedBuyer = async () => {
    setloader(true);
    await axios
      .delete(`/v1/flex/user/${selectedbuyers._id}`)
      .then((res) => {
        console.log(res);
        setloader(false);
        setshowModal3(false);
        if (mode === "buyer") {
          const updatedHero = buyers.filter(
            (item) => item._id !== selectedbuyers._id
          );
          setbuyers(updatedHero);
          setdelete(true);
        } else {
          const updatedHeros = suppliers.filter(
            (item) => item._id !== selectedbuyers._id
          );
          setsuppliers(updatedHeros);
          setdelete(true);
        }
      })
      .catch((e) => {
        console.log(e);
        setloader(false);
        setshowModal3(false);
      });
  };
*/
    return (
        <div className="h-100 w-100 ">
            <div
                className=""
                style={{
                    maxWidth: "2000px",
                    margin: "0px auto",
                }}
            >
                <Helmet>
                    <title>Tradevu — account</title>
                </Helmet>

                <Navbar />

                <CloseAccountModals
                    show={showrequeststatment}
                    setshow={setshowrequestStatement}
                    business={selectorbusiness}
                    setmodal={setshowModal5}
                />
                <DeleteAccounts
                    show={showModal6}
                    setshow={setshowModal6}
                    business={selector}
                    setshowModal3={setshowModal3}
                    closeAccountDetails={closeAccountDetails}
                    reason={reason}
                    setreason={setreason}
                    setmodal={setshowModal5}
                    loader={loader}
                />
                <CloseAccount
                    loader={loader}
                    show={showModal3}
                    setpassword={setpassword}
                    setshow={setshowModal3}
                    deletedBuyer={closeAccountDetails}
                />
                <CloseAccountSuccess
                    show={showModal5}
                    // setpassword={setpassword}
                    setshow={setshowModal5}
                    business={selectorbusiness}
                />
                <CardDetails show={show} setshow={setshow} />
                <UpdateBusinessModal
                    filename={filename}
                    setfilename={setfilename}
                    show={showmodalbusiness}
                    setshow={setshowmodalbusiness}
                    setfilesize={setfilesize}
                    filesize={filesize}
                    title={title}
                    updateBusinessDetails={updateBusinessDetails}
                    loaderrc={loaderrc}
                    setloadercc={setloadercc}
                    loading={loading}
                    setloading={setloading}
                />
                <ProfileUpdateModal
                    show={userModal}
                    message={message}
                    setshow={setuserModal}
                    user={selector}
                    business={selectorbusiness}
                    update={updateProfile}
                    loaderupdate={loaderupdate}
                    successholder={successholder}
                    failhandler={failhandler}
                    uploadImage={uploadImage}
                    documenttype={documenttype}
                    document={document}
                    setdocument={setdocument}
                    setmeans={setmeans}
                    upload={upload}
                    address={address}
                    setaddress={setaddress}
                    state={state}
                    setstate={setstate}
                />
                <UtitlityDocument
                    show={showdoucment}
                    setshow={setshowdocument}
                    filename={filename}
                    filesize={filesize}
                    setfilename={setfilename}
                    setfilesize={setfilesize}
                    showtype={showtype}
                    setshowtype={setshowtype}
                    name={name}
                    updateBusinessDetails={updateBusinessDetails}
                    loaderrc={loaderrc}
                    setloadercc={setloadercc}
                    loading={loading}
                    setloading={setloading}
                />
                <DirectorModalBvn
                    show={showModal}
                    director={director}
                    setDirector={setDirector}
                    setShowModal={setShowModal}
                    setfileUploadedBvn={setfileUploadedBvn}
                    state={{
                        businessId: selectorbusiness?._id,
                    }}
                    type={selectorbusiness.type}
                    // from={"Account"}
                />
                <UtitltyType show={showtype} setshow={setshowtype} setname={setname} />
                <ApiModal
                    show={showwallet}
                    setshow={setshowallet}
                    setshowsuccess={setshowsuccess}
                    setfail={setfail}
                    setmessage={setmessage}
                    data={api}
                />
                <ApiModalSuccess setshow={setshowapi} show={showapi} data={api} />
            </div>
            <div
                style={{
                    height: "100%",
                    maxWidth: "2000px",
                    margin: "0px auto",
                    width: "100%",
                }}
            >
                <div
                    className=" "
                    style={{
                        margin: "0px auto",
                        width: "100%",
                        padding: "20px 80px",
                    }}
                >
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6 ">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "column",
                                    padding: "21px",
                                    gap: "8px",
                                }}
                            >
                                <div
                                    style={{
                                        fontSize: "32px",
                                        lineHeight: "40px",
                                        color: "#344054",
                                        fontWeight: "500",
                                    }}
                                >
                                    Account
                                </div>
                                <div>
                                    <span
                                        style={{
                                            lineHeight: "24px",
                                            fontSize: "1rem",
                                            fontWeight: "400",
                                            letterSpacing: "2%",
                                            marginRight: "5px",
                                            color: "#101828",
                                        }}
                                    >
                                        Manage all your account information here.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 ">
                            <div
                                className="d-flex"
                                style={{
                                    flexDirection: "column",
                                    justifyContent: "end",
                                    alignItems: "end",
                                    paddingRight: "30px",
                                }}
                            >
                                <span
                                    style={{
                                        color: "#667085",
                                        lineHeight: "24px",
                                        fontWeight: "400",
                                        letterSpacing: "2%",
                                    }}
                                >
                                    {" "}
                                    {hrs}:{min}{" "}
                                </span>
                                <span
                                    style={{
                                        color: "#667085",
                                        lineHeight: "24px",
                                        fontWeight: "400",
                                        letterSpacing: "2%",
                                    }}
                                >
                                    {" "}
                                    {day},<span> {today}</span>
                                    <span> {months} </span>
                                    {year}{" "}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div
                        className="container"
                        style={{
                            maxWidth: "2000px",
                            margin: "0px auto",
                            padding: "0px 80px",
                            display: "flex",
                        }}
                    >
                        <ul
                            className="d-flex"
                            style={{
                                justifyContent: "flex-start",
                                listStyle: "none",
                                gap: "24px",
                                marginBottom: "0px",
                                width: "95%",
                                margin: "0px auto",
                                padding: "0px",
                                paddingLeft: "10px",
                            }}
                        >
                            <li
                                onClick={() => {
                                    setisDashboard(false);
                                    setisInvoice(true);
                                    setisActivities(false);
                                    setisTermination(false);
                                }}
                                style={{
                                    color: isInvoice ? "#101828" : "#667085",
                                    fontSize: "1rem",
                                    lineHeight: "32px",
                                    fontWeight: isInvoice ? "500" : "400",
                                    letterSpacing: "2%",
                                    cursor: "pointer",
                                    borderBottom: isInvoice ? "2px solid #6F00FF" : "",
                                }}
                            >
                                Profile
                            </li>

                            <li
                                onClick={() => {
                                    setisDashboard(false);
                                    setisInvoice(false);
                                    setisActivities(true);
                                    setisTermination(false);
                                }}
                                style={{
                                    color: isActivities ? "#101828" : "#667085",
                                    fontSize: "1rem",
                                    lineHeight: "32px",
                                    fontWeight: isActivities ? "500" : "400",
                                    letterSpacing: "2%",
                                    display: "flex",
                                    gap: "8px",
                                    cursor: "pointer",
                                    borderBottom: isActivities ? "2px solid #6F00FF" : "",
                                }}
                            >
                                Security
                            </li>
                            <li
                                onClick={() => {
                                    setisDashboard(false);
                                    setisInvoice(false);
                                    setisActivities(false);
                                    setisTermination(true);
                                }}
                                style={{
                                    color: isTermination ? "#101828" : "#667085",
                                    fontSize: "1rem",
                                    lineHeight: "32px",
                                    fontWeight: isTermination ? "500" : "400",
                                    letterSpacing: "2%",
                                    display: "flex",
                                    gap: "8px",
                                    cursor: "pointer",
                                    borderBottom: isTermination ? "2px solid #6F00FF" : "",
                                }}
                            >
                                API Management
                            </li>
                            <li
                                onClick={() => {
                                    setisDashboard(true);
                                    setisInvoice(false);
                                    setisActivities(false);
                                    setisTermination(false);
                                }}
                                style={{
                                    color: isDashboard ? "#101828" : "#667085",
                                    fontSize: "1rem",
                                    lineHeight: "32px",
                                    fontWeight: isDashboard ? "500" : "400",
                                    letterSpacing: "2%",
                                    display: "flex",
                                    gap: "8px",
                                    cursor: "pointer",
                                    borderBottom: isDashboard ? "2px solid #6F00FF" : "",
                                }}
                            >
                                Help
                            </li>
                        </ul>
                    </div>

                    <div
                        className=" "
                        style={{
                            margin: "0px auto",
                            width: "100%",
                            padding: "0px 80px",
                        }}
                    >
                        <div
                            className="card border-0 p"
                            style={{
                                borderRadius: "24px 24px 0px 0px",
                                background: "#FCFCFD",
                            }}
                        >
                            {" "}
                            {isInvoice && (
                                <div
                                    className="card-body p-0"
                                    style={{
                                        borderRadius: "24px 24px 0px 0px",

                                        minHeight: "977px",
                                        display: "flex",
                                        overflowY: "auto",
                                    }}
                                >
                                    <div
                                        className="left-col-static-nav"
                                        style={{
                                            minHeight: "1550px",
                                        }}
                                    >
                                        <div className="interrior-lg-sm">
                                            {toggledetail.map((toggle, index) => (
                                                <div
                                                    className="each-scale-ratio"
                                                    key={index}
                                                    style={{
                                                        border:
                                                            selectedtoggle === index &&
                                                            "0.5px solid #6F00FF",
                                                        cursor: "pointer",
                                                        display:
                                                            selectorbusiness.length === 0 &&
                                                            index === 1
                                                                ? "none"
                                                                : "",
                                                    }}
                                                    onClick={() => {
                                                        setselectedtoggle(index);
                                                        if (index !== 3) {
                                                            setprofiletab(index);
                                                        }
                                                    }}
                                                >
                                                    <div
                                                        className="inner-lg-mf"
                                                        style={{
                                                            background:
                                                                selectedtoggle === index &&
                                                                "#F4F0FF",
                                                        }}
                                                    >
                                                        <div className="img-lg-text">
                                                            <img
                                                                src={
                                                                    index === 0
                                                                        ? personal
                                                                        : index === 1
                                                                        ? business
                                                                        : index === 2
                                                                        ? beneficiary
                                                                        : balance
                                                                }
                                                                alt=""
                                                            />
                                                            <span>{toggle}</span>
                                                        </div>
                                                        {selectedtoggle === index &&
                                                            index !== 3 && (
                                                                <img src={checkbox} alt="" />
                                                            )}
                                                        {index === 3 && (
                                                            <label className="switch">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={ischecked}
                                                                    onChange={hideBalance}
                                                                />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {profiletab === 0 && (
                                        <form
                                            className="right-side-account-details"
                                            onSubmit={updateProfile}
                                        >
                                            <div className="miid-side-details">
                                                <SuccessHodler
                                                    success={successholder}
                                                    message={message}
                                                />
                                                <FailHandler
                                                    success={failhandler}
                                                    message={message}
                                                />

                                                <div className="top-details-lg-mgph">
                                                    <span>Owner's Details</span>
                                                    <div className="form-details-lg-hl">
                                                        <div
                                                            className="card  border-0 "
                                                            style={{
                                                                background: "#ffff",
                                                                cursor: "pointer",
                                                            }}
                                                            //   onClick={props.die showFormandHideForm}
                                                            //key={index}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    gap: "8px",
                                                                }}
                                                            >
                                                                <div
                                                                    className="card-body  d-flex gap-2 "
                                                                    style={{
                                                                        alignItems: "center",
                                                                        border: "1px solid #E7E9FB",
                                                                        padding: "16px",
                                                                        borderRadius: "8px",
                                                                    }}
                                                                >
                                                                    <div className="intial">
                                                                        {selector?.profileImage ? (
                                                                            <img
                                                                                src={`${selector?.profileImage.url}`}
                                                                                alt=""
                                                                                style={{
                                                                                    height: "100%",
                                                                                    width: "100%",
                                                                                    borderRadius:
                                                                                        "50%",
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <>
                                                                                {selector?.firstName?.slice(
                                                                                    0,
                                                                                    1
                                                                                )}
                                                                                {selector?.lastName?.slice(
                                                                                    0,
                                                                                    1
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div className="d-flex justify-content-between align-items-center w-100">
                                                                        <div>
                                                                            <span
                                                                                style={{
                                                                                    color: "#101828",
                                                                                    fontSize:
                                                                                        "1rem",
                                                                                    letterSpacing:
                                                                                        "2%",
                                                                                    fontWeight:
                                                                                        "600",
                                                                                    lineHeight:
                                                                                        "24px",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    selector?.firstName
                                                                                }{" "}
                                                                                {selector?.lastName}
                                                                            </span>
                                                                            <div
                                                                                className=""
                                                                                style={{
                                                                                    color: "#667085",
                                                                                    fontSize:
                                                                                        "12px",
                                                                                    letterSpacing:
                                                                                        "2%",
                                                                                    fontWeight:
                                                                                        "500%",
                                                                                    lineHeight:
                                                                                        "16.2px",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    selector?.phoneNumber
                                                                                }{" "}
                                                                                <span
                                                                                    style={{
                                                                                        fontWeight:
                                                                                            "600",
                                                                                        fontSize:
                                                                                            "16px",
                                                                                        color: "#667085",
                                                                                        marginBottom:
                                                                                            "5px",
                                                                                    }}
                                                                                >
                                                                                    .{" "}
                                                                                </span>
                                                                                {selector?.email}
                                                                            </div>
                                                                        </div>
                                                                        <img
                                                                            src={edit}
                                                                            alt=""
                                                                            onClick={() =>
                                                                                setuserModal(true)
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {selector?.address && selector?.state && (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    gap: "8px",
                                                                    marginTop: "20px",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent:
                                                                            "space-between",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            color: "#101828",
                                                                            fontWeight: "500",
                                                                            fontSize: "14px",
                                                                            lineHeight: "19.6px",
                                                                        }}
                                                                    >
                                                                        KYC
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            color: "#6F00FF",
                                                                            fontWeight: "700",
                                                                            fontSize: "16px",
                                                                            lineHeight: "20px",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() =>
                                                                            setuserModal(true)
                                                                        }
                                                                    >
                                                                        Update
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        gap: "8px",
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="card-body  d-flex gap-2 "
                                                                        style={{
                                                                            alignItems: "center",
                                                                            border: "1px solid #E7E9FB",
                                                                            padding: "16px",
                                                                            borderRadius: "8px",
                                                                            background: "#fff",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="d-flex"
                                                                            style={{
                                                                                flexDirection:
                                                                                    "column",
                                                                                gap: "16px",
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    gap: "32px",
                                                                                    alignItems:
                                                                                        "center",
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        color: "#667085",
                                                                                        fontWeight:
                                                                                            "400",
                                                                                        fontSize:
                                                                                            "14px",
                                                                                        lineHeight:
                                                                                            "19.6px",
                                                                                        width: "110px",
                                                                                    }}
                                                                                >
                                                                                    State
                                                                                </span>

                                                                                <span
                                                                                    style={{
                                                                                        color: "#344054",
                                                                                        fontWeight:
                                                                                            "500",
                                                                                        fontSize:
                                                                                            "16px",
                                                                                        lineHeight:
                                                                                            "19.6px",
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        selector?.state
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    gap: "32px",
                                                                                    alignItems:
                                                                                        "center",
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        color: "#667085",
                                                                                        fontWeight:
                                                                                            "400",
                                                                                        fontSize:
                                                                                            "14px",
                                                                                        lineHeight:
                                                                                            "19.6px",
                                                                                        width: "110px",
                                                                                    }}
                                                                                >
                                                                                    Address
                                                                                </span>

                                                                                <span
                                                                                    style={{
                                                                                        color: "#344054",
                                                                                        fontWeight:
                                                                                            "500",
                                                                                        fontSize:
                                                                                            "16px",
                                                                                        lineHeight:
                                                                                            "19.6px",
                                                                                        width: "448px",
                                                                                        textAlign:
                                                                                            "left",
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        selector?.address
                                                                                    }
                                                                                </span>
                                                                            </div>

                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    gap: "32px",
                                                                                    alignItems:
                                                                                        "center",
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        color: "#667085",
                                                                                        fontWeight:
                                                                                            "400",
                                                                                        fontSize:
                                                                                            "14px",
                                                                                        lineHeight:
                                                                                            "19.6px",
                                                                                        width: "110px",
                                                                                    }}
                                                                                >
                                                                                    ID
                                                                                </span>

                                                                                <span
                                                                                    style={{
                                                                                        color: "#667085",
                                                                                        fontWeight:
                                                                                            "400",
                                                                                        fontSize:
                                                                                            "14px",
                                                                                        lineHeight:
                                                                                            "19.6px",
                                                                                    }}
                                                                                >
                                                                                    <a
                                                                                        style={{
                                                                                            color: "#6F00FF",
                                                                                            fontSize:
                                                                                                "16px",
                                                                                            fontWeight:
                                                                                                "500",
                                                                                            lineHeight:
                                                                                                "19.6px",
                                                                                            textDecoration:
                                                                                                "none",
                                                                                        }}
                                                                                        href={`${selector?.validId?.url}`}
                                                                                        target="_blank"
                                                                                    >
                                                                                        {
                                                                                            selector
                                                                                                .validId
                                                                                                ?.key
                                                                                        }
                                                                                    </a>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                gap: "8px",
                                                                marginTop: "30px",
                                                            }}
                                                        >
                                                            <label
                                                                htmlFor=""
                                                                style={{
                                                                    color: "#101828",
                                                                    fontWeight: "600",
                                                                    fontSize: "14px",
                                                                    lineHeight: "19.6px",
                                                                }}
                                                            >
                                                                Danger Zone
                                                            </label>
                                                            <div
                                                                style={{
                                                                    border: "1px solid #E7E9FB",
                                                                    padding: "16px",
                                                                    display: "flex",

                                                                    borderRadius: "8px",
                                                                    alignItems: "center",
                                                                    justifyContent: "space-between",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => setshowModal6(true)}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        gap: "8px",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <img src={imageDelete} alt="" />
                                                                    <span
                                                                        style={{
                                                                            color: "#F04438",
                                                                            fontWeight: "600",
                                                                            fontSize: "16px",
                                                                            lineHeight: "24px",
                                                                        }}
                                                                    >
                                                                        Delete Account
                                                                    </span>
                                                                </div>
                                                                <span
                                                                    className="material-icons"
                                                                    style={{
                                                                        marginTop: "10px",
                                                                        fontSize: "16px",
                                                                        color: "#667085",
                                                                    }}
                                                                >
                                                                    chevron_right
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/**
                            <div className="change-profile">

                              <span>
                                <input
                                  type="file"
                                  name=""
                                  id=""
                                  style={{
                                    opacity: "0",
                                    visibility: "0",
                                    position: "absolute",
                                  }}
                                  onChange={(e) =>
                                    uploadImage(e.target.files[0])
                                  }
                                />
                                Change
                              </span>
                            </div>
                            */}
                                                        {/***
                            <div className="each-profile-input">
                              <label htmlFor="">First name</label>
                              <input
                                type="text"
                                value={selector.firstName}
                                onChange={(e) => setfirstName(e.target.value)}
                              />
                            </div>
                            <div className="each-profile-input">
                              <label htmlFor="">Last name</label>
                              <input
                                type="text"
                                value={selector.lastName}
                                onChange={(e) => setlastName(e.target.value)}
                              />
                            </div>
                            <div className="each-profile-input">
                              <label htmlFor="">Email</label>
                              <input
                                type="text"
                                readOnly
                                value={selector.email}
                              />
                            </div>
                            <div className="each-profile-input">
                              <label htmlFor="">Phone Number</label>
                              <input
                                type="text"
                                readOnly
                                value={selector.phoneNumber}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="top-details-lg-mgph">
                          <span>KYC</span>
                          <div className="form-details-lg-hl">
                            <div className="each-profile-input">
                              <label htmlFor="">State of residence</label>
                              <input
                                type="text"
                                value={state}
                                onChange={(e) => setstate(e.target.value)}
                              />
                            </div>
                            <div className="each-profile-input">
                              <label htmlFor="">Residential address</label>
                              <input
                                type="text"
                                value={address}
                                onChange={(e) => setaddress(e.target.value)}
                              />
                            </div>

                            <div className="each-profile-input">
                              <label htmlFor="">Select a valid ID</label>
                              <select
                                type="text"
                                onChange={(e) => {
                                  setmeans(e.target.value);
                                }}
                              >
                                {documenttype.map((document, index) => (
                                  <option value={document} index={index}>
                                    {document}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div
                              className="uploadfile-each"
                              style={{
                                position: "relative",
                              }}
                              onClick={() => setdocument([])}
                            >
                              {document.length === 0 && (
                                <input
                                  type="file"
                                  name=""
                                  id=""
                                  style={{
                                    position: "absolute",
                                    opacity: "0",
                                    visibility: "0",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  onChange={(e) =>
                                    setdocument(e.target.files[0])
                                  }
                                />
                              )}

                              <img src={upload} alt="" />
                              {document.length !== 0 && (
                                <div
                                  style={{
                                    color: "#6F00FF",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    lineHeight: "19.6px",
                                  }}
                                >
                                  {document.name}
                                </div>
                              )}
                              {document.length === 0 && (
                                <div className="text-img-lg">
                                  <span className="head-tag-gl">
                                    Upload Document
                                  </span>
                                  <span className="small-text-lg-pt">
                                    JPEG, PNG, JPG, PDF
                                  </span>
                                  <span className="small-text-lg-pt">
                                    Max. file size: 20.0 MB
                                  </span>
                                </div>
                              )}
                            </div>
                            */}

                                                        {/**

                            <button
                              disabled={loaderupdate}
                              className="update-profile"
                              style={{
                                background: "#6F00FF",
                                display: "flex",
                                marginTop: "30px",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "#fff",
                              }}
                            >
                              {loaderupdate ? <Loader /> : "Update profile"}
                            </button>
                            */}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                    {profiletab === 1 && (
                                        <div className="right-side-account-details">
                                            <div className="miid-side-details">
                                                <div className="top-details-lg-mgph">
                                                    <span>Business profile</span>
                                                    <div className="form-details-lg-hl">
                                                        <div className="change-profile">
                                                            <div className="intial">
                                                                {selectorbusiness?.businessImage ? (
                                                                    <img
                                                                        src={`${selectorbusiness?.businessImage?.url}`}
                                                                        alt=""
                                                                        style={{
                                                                            height: "100%",
                                                                            width: "100%",
                                                                            borderRadius: "50%",
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        {selectorbusiness?.name?.slice(
                                                                            0,
                                                                            2
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                            <span>
                                                                <input
                                                                    type="file"
                                                                    name=""
                                                                    id=""
                                                                    onChange={(e) =>
                                                                        uploadImageBusiness(
                                                                            e.target.files[0]
                                                                        )
                                                                    }
                                                                    style={{
                                                                        opacity: "0",
                                                                        visibility: "0",
                                                                        position: "absolute",
                                                                    }}
                                                                />
                                                                Change
                                                            </span>
                                                        </div>
                                                        <div className="each-profile-input">
                                                            <label htmlFor="">RC Number</label>
                                                            <input
                                                                type="text"
                                                                value={
                                                                    selectorbusiness?.registrationNumber
                                                                }
                                                                onChange={(e) =>
                                                                    setfirstName(e.target.value)
                                                                }
                                                            />
                                                        </div>
                                                        <div className="each-profile-input">
                                                            <label htmlFor="">Business name</label>
                                                            <input
                                                                type="text"
                                                                value={selectorbusiness?.name}
                                                                onChange={(e) =>
                                                                    setlastName(e.target.value)
                                                                }
                                                            />
                                                        </div>
                                                        <div className="each-profile-input">
                                                            <label htmlFor="">Business type</label>
                                                            <input
                                                                type="text"
                                                                readOnly
                                                                value={selectorbusiness?.type}
                                                            />
                                                        </div>
                                                        {/**
                          <div className="each-profile-input">
                            <label htmlFor="">Business sector</label>
                            <input
                              type="text"
                              readOnly
                              value={selectorbusiness?.category}
                            />
                          </div>
                          */}
                                                        <div className="each-profile-input">
                                                            <label htmlFor="">Address</label>
                                                            <input
                                                                type="text"
                                                                readOnly
                                                                value={selectorbusiness?.address}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="top-details-lg-mgph">
                                                    <span>KYC</span>
                                                    <div className="form-details-lg-hl" style={{}}>
                                                        {selectorbusiness?.type === "LLC" && (
                                                            <>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="each-profile-input-kyc"
                                                                        style={{
                                                                            position: "relative",
                                                                            display: "flex",

                                                                            alignItems: "center",
                                                                            padding: "16px",
                                                                            background: "#fff",
                                                                            borderBottom:
                                                                                "1px solid #E7E9FB",
                                                                            justifyContent:
                                                                                "space-between",
                                                                        }}
                                                                        onClick={() => {
                                                                            //  alert("Hello");
                                                                            if (
                                                                                businessDocument
                                                                                    ?.LLC?.CAC
                                                                                    ?.status !==
                                                                                "APPROVED"
                                                                            )
                                                                                setshowmodalbusiness(
                                                                                    true
                                                                                );
                                                                            setfilename([]);
                                                                            setfilesize(0);
                                                                            settitle(
                                                                                "Certificate of Incorporation"
                                                                            );
                                                                            setbusinesindex(0);
                                                                            setloadercc(false);
                                                                            setloading(false);
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="lg-mg-lo-pl"
                                                                            style={{
                                                                                display: "flex",
                                                                                gap: "8px",
                                                                                alignItems:
                                                                                    "center",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={document}
                                                                                alt=""
                                                                            />
                                                                            <div
                                                                                className="lg-bm-fl-g"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    flexDirection:
                                                                                        "column",
                                                                                }}
                                                                            >
                                                                                <h4
                                                                                    style={{
                                                                                        padding:
                                                                                            "0px",
                                                                                        margin: "0px",
                                                                                        fontSize:
                                                                                            "16px",
                                                                                        fontWeight:
                                                                                            "600",
                                                                                        lineHeight:
                                                                                            "24px",
                                                                                        letterSpacing:
                                                                                            "0.02em",
                                                                                        textAlign:
                                                                                            "left",
                                                                                        color: "#101828",
                                                                                    }}
                                                                                >
                                                                                    Certificate of
                                                                                    Incorporation
                                                                                </h4>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "12px",
                                                                                        fontWeight:
                                                                                            "400",
                                                                                        lineHeight:
                                                                                            "16px",
                                                                                        letterSpacing:
                                                                                            "0.02em",
                                                                                        textAlign:
                                                                                            "left",
                                                                                        color: "#667085",
                                                                                    }}
                                                                                >
                                                                                    From your
                                                                                    country
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        {loadeddocument.includes(
                                                                            0
                                                                        ) ? (
                                                                            <img src={check} />
                                                                        ) : businessDocument?.LLC
                                                                              ?.CAC?.status ===
                                                                          "PENDING" ? (
                                                                            <img
                                                                                src={
                                                                                    loadingbusiness
                                                                                }
                                                                            />
                                                                        ) : businessDocument?.LLC
                                                                              ?.CAC?.status ===
                                                                          "APPROVED" ? (
                                                                            <img
                                                                                src={busiesssuccess}
                                                                            />
                                                                        ) : businessDocument?.LLC
                                                                              ?.CAC?.status ===
                                                                          "REJECTED" ? (
                                                                            <img
                                                                                src={busiessscancel}
                                                                            />
                                                                        ) : (
                                                                            <span className="material-icons">
                                                                                chevron_right
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <div
                                                                        className="each-profile-input-kyc"
                                                                        style={{
                                                                            position: "relative",

                                                                            display: "flex",

                                                                            alignItems: "center",
                                                                            padding: "16px",
                                                                            background: "#fff",
                                                                            borderBottom:
                                                                                "1px solid #E7E9FB",
                                                                            justifyContent:
                                                                                "space-between",
                                                                        }}
                                                                        onClick={() => {
                                                                            //  alert("Hello");
                                                                            if (
                                                                                businessDocument
                                                                                    ?.LLC
                                                                                    ?.CAC_STATUS_REPORT
                                                                                    ?.status !==
                                                                                "APPROVED"
                                                                            )
                                                                                setshowmodalbusiness(
                                                                                    true
                                                                                );
                                                                            setfilename([]);
                                                                            setfilesize(0);
                                                                            settitle(
                                                                                "CAC Status Report (Optional)"
                                                                            );
                                                                            setbusinesindex(1);
                                                                            setloadercc(false);
                                                                            setloading(false);
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="lg-mg-lo-pl"
                                                                            style={{
                                                                                display: "flex",
                                                                                gap: "8px",
                                                                                alignItems:
                                                                                    "center",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={document}
                                                                                alt=""
                                                                            />
                                                                            <div
                                                                                className="lg-bm-fl-g"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    flexDirection:
                                                                                        "column",
                                                                                }}
                                                                            >
                                                                                <h4
                                                                                    style={{
                                                                                        padding:
                                                                                            "0px",
                                                                                        margin: "0px",
                                                                                        fontSize:
                                                                                            "16px",
                                                                                        fontWeight:
                                                                                            "600",
                                                                                        lineHeight:
                                                                                            "24px",
                                                                                        letterSpacing:
                                                                                            "0.02em",
                                                                                        textAlign:
                                                                                            "left",
                                                                                        color: "#101828",
                                                                                    }}
                                                                                >
                                                                                    CAC Status
                                                                                    Report
                                                                                    (Optional)
                                                                                </h4>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "12px",
                                                                                        fontWeight:
                                                                                            "400",
                                                                                        lineHeight:
                                                                                            "16px",
                                                                                        letterSpacing:
                                                                                            "0.02em",
                                                                                        textAlign:
                                                                                            "left",
                                                                                        color: "#667085",
                                                                                    }}
                                                                                >
                                                                                    Within the last
                                                                                    3 years
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        {loadeddocument.includes(
                                                                            1
                                                                        ) ? (
                                                                            <img src={check} />
                                                                        ) : businessDocument?.LLC
                                                                              ?.CAC_STATUS_REPORT
                                                                              ?.status ===
                                                                          "PENDING" ? (
                                                                            <img
                                                                                src={
                                                                                    loadingbusiness
                                                                                }
                                                                            />
                                                                        ) : businessDocument?.LLC
                                                                              ?.CAC_STATUS_REPORT
                                                                              ?.status ===
                                                                          "APPROVED" ? (
                                                                            <img
                                                                                src={busiesssuccess}
                                                                            />
                                                                        ) : businessDocument?.LLC
                                                                              ?.CAC_STATUS_REPORT
                                                                              ?.status ===
                                                                          "REJECTED" ? (
                                                                            <img
                                                                                src={busiessscancel}
                                                                            />
                                                                        ) : (
                                                                            <span className="material-icons">
                                                                                chevron_right
                                                                            </span>
                                                                        )}
                                                                    </div>

                                                                    <div
                                                                        className="each-profile-input-kyc"
                                                                        style={{
                                                                            position: "relative",

                                                                            display: "flex",

                                                                            alignItems: "center",
                                                                            padding: "16px",
                                                                            background: "#fff",
                                                                            borderBottom:
                                                                                "1px solid #E7E9FB",
                                                                            justifyContent:
                                                                                "space-between",
                                                                        }}
                                                                        onClick={() => {
                                                                            //  alert("Hello");
                                                                            if (
                                                                                businessDocument
                                                                                    ?.LLC?.VALID_ID
                                                                                    ?.status !==
                                                                                "APPROVED"
                                                                            )
                                                                                setshowmodalbusiness(
                                                                                    true
                                                                                );
                                                                            setfilename([]);
                                                                            setfilesize(0);
                                                                            settitle(
                                                                                " Valid means of ID"
                                                                            );
                                                                            setbusinesindex(2);
                                                                            setloadercc(false);
                                                                            setloading(false);
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="lg-mg-lo-pl"
                                                                            style={{
                                                                                display: "flex",
                                                                                gap: "8px",
                                                                                alignItems:
                                                                                    "center",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={document}
                                                                                alt=""
                                                                            />
                                                                            <div
                                                                                className="lg-bm-fl-g"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    flexDirection:
                                                                                        "column",
                                                                                }}
                                                                            >
                                                                                <h4
                                                                                    style={{
                                                                                        padding:
                                                                                            "0px",
                                                                                        margin: "0px",
                                                                                        fontSize:
                                                                                            "16px",
                                                                                        fontWeight:
                                                                                            "600",
                                                                                        lineHeight:
                                                                                            "24px",
                                                                                        letterSpacing:
                                                                                            "0.02em",
                                                                                        textAlign:
                                                                                            "left",
                                                                                        color: "#101828",
                                                                                    }}
                                                                                >
                                                                                    Valid means of
                                                                                    ID
                                                                                </h4>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "12px",
                                                                                        fontWeight:
                                                                                            "400",
                                                                                        lineHeight:
                                                                                            "16px",
                                                                                        letterSpacing:
                                                                                            "0.02em",
                                                                                        textAlign:
                                                                                            "left",
                                                                                        color: "#667085",
                                                                                    }}
                                                                                >
                                                                                    Government-issued
                                                                                    e.g. NIN
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        {loadeddocument.includes(
                                                                            2
                                                                        ) ? (
                                                                            <img src={check} />
                                                                        ) : businessDocument?.LLC
                                                                              ?.VALID_ID?.status ===
                                                                          "PENDING" ? (
                                                                            <img
                                                                                src={
                                                                                    loadingbusiness
                                                                                }
                                                                            />
                                                                        ) : businessDocument?.LLC
                                                                              ?.VALID_ID?.status ===
                                                                          "APPROVED" ? (
                                                                            <img
                                                                                src={busiesssuccess}
                                                                            />
                                                                        ) : businessDocument?.LLC
                                                                              ?.VALID_ID?.status ===
                                                                          "REJECTED" ? (
                                                                            <img
                                                                                src={busiessscancel}
                                                                            />
                                                                        ) : (
                                                                            <span className="material-icons">
                                                                                chevron_right
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <div
                                                                        className="each-profile-input-kyc"
                                                                        style={{
                                                                            position: "relative",

                                                                            display: "flex",

                                                                            alignItems: "center",
                                                                            padding: "16px",
                                                                            background: "#fff",
                                                                            borderBottom:
                                                                                "1px solid #E7E9FB",
                                                                            justifyContent:
                                                                                "space-between",
                                                                        }}
                                                                        onClick={() => {
                                                                            // alert("Hello");
                                                                            if (
                                                                                businessDocument
                                                                                    ?.proofOfAddress
                                                                                    ?.status !==
                                                                                "APPROVED"
                                                                            )
                                                                                setshowdocument(
                                                                                    true
                                                                                );
                                                                            setfilename([]);
                                                                            setfilesize(0);

                                                                            settitle(
                                                                                "Proof of business address"
                                                                            );
                                                                            setbusinesindex(3);
                                                                            setloadercc(false);
                                                                            setloading(false);
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="lg-mg-lo-pl"
                                                                            style={{
                                                                                display: "flex",
                                                                                gap: "8px",
                                                                                alignItems:
                                                                                    "center",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={document}
                                                                                alt=""
                                                                            />
                                                                            <div
                                                                                className="lg-bm-fl-g"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    flexDirection:
                                                                                        "column",
                                                                                }}
                                                                            >
                                                                                <h4
                                                                                    style={{
                                                                                        padding:
                                                                                            "0px",
                                                                                        margin: "0px",
                                                                                        fontSize:
                                                                                            "16px",
                                                                                        fontWeight:
                                                                                            "600",
                                                                                        lineHeight:
                                                                                            "24px",
                                                                                        letterSpacing:
                                                                                            "0.02em",
                                                                                        textAlign:
                                                                                            "left",
                                                                                        color: "#101828",
                                                                                    }}
                                                                                >
                                                                                    Proof of
                                                                                    business address
                                                                                </h4>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "12px",
                                                                                        fontWeight:
                                                                                            "400",
                                                                                        lineHeight:
                                                                                            "16px",
                                                                                        letterSpacing:
                                                                                            "0.02em",
                                                                                        textAlign:
                                                                                            "left",
                                                                                        color: "#667085",
                                                                                    }}
                                                                                >
                                                                                    Utility bill
                                                                                    within last 3
                                                                                    months
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        {loadeddocument.includes(
                                                                            0
                                                                        ) ? (
                                                                            <img src={check} />
                                                                        ) : businessDocument
                                                                              ?.proofOfAddress
                                                                              ?.status ===
                                                                          "PENDING" ? (
                                                                            <img
                                                                                src={
                                                                                    loadingbusiness
                                                                                }
                                                                            />
                                                                        ) : businessDocument
                                                                              ?.proofOfAddress
                                                                              ?.status ===
                                                                          "APPROVED" ? (
                                                                            <img
                                                                                src={busiesssuccess}
                                                                            />
                                                                        ) : businessDocument
                                                                              ?.proofOfAddress
                                                                              ?.status ===
                                                                          "REJECTED" ? (
                                                                            <img
                                                                                src={busiessscancel}
                                                                            />
                                                                        ) : (
                                                                            <span className="material-icons">
                                                                                chevron_right
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                        {selectorbusiness?.type ===
                                                            "SoleProprietorship" && (
                                                            <>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="each-profile-input-kyc"
                                                                        style={{
                                                                            position: "relative",
                                                                            display: "flex",

                                                                            alignItems: "center",
                                                                            padding: "16px",
                                                                            background: "#fff",
                                                                            borderBottom:
                                                                                "1px solid #E7E9FB",
                                                                            justifyContent:
                                                                                "space-between",
                                                                        }}
                                                                        onClick={() => {
                                                                            //  alert("Hello");
                                                                            if (
                                                                                businessDocument
                                                                                    ?.SoleProprietorship
                                                                                    ?.CAC
                                                                                    ?.status !==
                                                                                "APPROVED"
                                                                            )
                                                                                setshowmodalbusiness(
                                                                                    true
                                                                                );
                                                                            setfilename([]);
                                                                            setfilesize(0);
                                                                            settitle(
                                                                                "Certificate of Incorporation"
                                                                            );
                                                                            setbusinesindex(0);
                                                                            setloadercc(false);
                                                                            setloading(false);
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="lg-mg-lo-pl"
                                                                            style={{
                                                                                display: "flex",
                                                                                gap: "8px",
                                                                                alignItems:
                                                                                    "center",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={document}
                                                                                alt=""
                                                                            />
                                                                            <div
                                                                                className="lg-bm-fl-g"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    flexDirection:
                                                                                        "column",
                                                                                }}
                                                                            >
                                                                                <h4
                                                                                    style={{
                                                                                        padding:
                                                                                            "0px",
                                                                                        margin: "0px",
                                                                                        fontSize:
                                                                                            "16px",
                                                                                        fontWeight:
                                                                                            "600",
                                                                                        lineHeight:
                                                                                            "24px",
                                                                                        letterSpacing:
                                                                                            "0.02em",
                                                                                        textAlign:
                                                                                            "left",
                                                                                        color: "#101828",
                                                                                    }}
                                                                                >
                                                                                    Certificate of
                                                                                    Incorporation
                                                                                </h4>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "12px",
                                                                                        fontWeight:
                                                                                            "400",
                                                                                        lineHeight:
                                                                                            "16px",
                                                                                        letterSpacing:
                                                                                            "0.02em",
                                                                                        textAlign:
                                                                                            "left",
                                                                                        color: "#667085",
                                                                                    }}
                                                                                >
                                                                                    From your
                                                                                    country
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        {loadeddocument.includes(
                                                                            0
                                                                        ) ? (
                                                                            <img src={check} />
                                                                        ) : businessDocument
                                                                              ?.SoleProprietorship
                                                                              ?.CAC?.status ===
                                                                          "PENDING" ? (
                                                                            <img
                                                                                src={
                                                                                    loadingbusiness
                                                                                }
                                                                            />
                                                                        ) : businessDocument
                                                                              ?.SoleProprietorship
                                                                              ?.CAC?.status ===
                                                                          "APPROVED" ? (
                                                                            <img
                                                                                src={busiesssuccess}
                                                                            />
                                                                        ) : businessDocument
                                                                              ?.SoleProprietorship
                                                                              ?.CAC?.status ===
                                                                          "REJECTED" ? (
                                                                            <img
                                                                                src={busiessscancel}
                                                                            />
                                                                        ) : (
                                                                            <span className="material-icons">
                                                                                chevron_right
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <div
                                                                        className="each-profile-input-kyc"
                                                                        style={{
                                                                            position: "relative",

                                                                            display: "flex",

                                                                            alignItems: "center",
                                                                            padding: "16px",
                                                                            background: "#fff",
                                                                            borderBottom:
                                                                                "1px solid #E7E9FB",
                                                                            justifyContent:
                                                                                "space-between",
                                                                        }}
                                                                        onClick={() => {
                                                                            //  alert("Hello");
                                                                            if (
                                                                                businessDocument
                                                                                    ?.SoleProprietorship
                                                                                    ?.CAC_STATUS_REPORT
                                                                                    ?.status !==
                                                                                "APPROVED"
                                                                            )
                                                                                setshowmodalbusiness(
                                                                                    true
                                                                                );
                                                                            setfilename([]);
                                                                            setfilesize(0);
                                                                            settitle(
                                                                                "CAC Status Report (Optional)"
                                                                            );
                                                                            setbusinesindex(1);
                                                                            setloadercc(false);
                                                                            setloading(false);
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="lg-mg-lo-pl"
                                                                            style={{
                                                                                display: "flex",
                                                                                gap: "8px",
                                                                                alignItems:
                                                                                    "center",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={document}
                                                                                alt=""
                                                                            />
                                                                            <div
                                                                                className="lg-bm-fl-g"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    flexDirection:
                                                                                        "column",
                                                                                }}
                                                                            >
                                                                                <h4
                                                                                    style={{
                                                                                        padding:
                                                                                            "0px",
                                                                                        margin: "0px",
                                                                                        fontSize:
                                                                                            "16px",
                                                                                        fontWeight:
                                                                                            "600",
                                                                                        lineHeight:
                                                                                            "24px",
                                                                                        letterSpacing:
                                                                                            "0.02em",
                                                                                        textAlign:
                                                                                            "left",
                                                                                        color: "#101828",
                                                                                    }}
                                                                                >
                                                                                    CAC Status
                                                                                    Report
                                                                                    (Optional)
                                                                                </h4>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "12px",
                                                                                        fontWeight:
                                                                                            "400",
                                                                                        lineHeight:
                                                                                            "16px",
                                                                                        letterSpacing:
                                                                                            "0.02em",
                                                                                        textAlign:
                                                                                            "left",
                                                                                        color: "#667085",
                                                                                    }}
                                                                                >
                                                                                    Within the last
                                                                                    3 years
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        {loadeddocument.includes(
                                                                            1
                                                                        ) ? (
                                                                            <img src={check} />
                                                                        ) : businessDocument
                                                                              ?.SoleProprietorship
                                                                              ?.CAC_STATUS_REPORT
                                                                              ?.status ===
                                                                          "PENDING" ? (
                                                                            <img
                                                                                src={
                                                                                    loadingbusiness
                                                                                }
                                                                            />
                                                                        ) : businessDocument
                                                                              ?.SoleProprietorship
                                                                              ?.CAC_STATUS_REPORT
                                                                              ?.status ===
                                                                          "APPROVED" ? (
                                                                            <img
                                                                                src={busiesssuccess}
                                                                            />
                                                                        ) : businessDocument
                                                                              ?.SoleProprietorship
                                                                              ?.CAC_STATUS_REPORT
                                                                              ?.status ===
                                                                          "REJECTED" ? (
                                                                            <img
                                                                                src={busiessscancel}
                                                                            />
                                                                        ) : (
                                                                            <span className="material-icons">
                                                                                chevron_right
                                                                            </span>
                                                                        )}
                                                                    </div>

                                                                    <div
                                                                        className="each-profile-input-kyc"
                                                                        style={{
                                                                            position: "relative",

                                                                            display: "flex",

                                                                            alignItems: "center",
                                                                            padding: "16px",
                                                                            background: "#fff",
                                                                            borderBottom:
                                                                                "1px solid #E7E9FB",
                                                                            justifyContent:
                                                                                "space-between",
                                                                        }}
                                                                        onClick={() => {
                                                                            //  alert("Hello");
                                                                            if (
                                                                                businessDocument
                                                                                    ?.LLC?.VALID_ID
                                                                                    ?.status !==
                                                                                "APPROVED"
                                                                            )
                                                                                setshowmodalbusiness(
                                                                                    true
                                                                                );
                                                                            setfilename([]);
                                                                            setfilesize(0);
                                                                            settitle(
                                                                                " Valid means of ID"
                                                                            );
                                                                            setbusinesindex(2);
                                                                            setloadercc(false);
                                                                            setloading(false);
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="lg-mg-lo-pl"
                                                                            style={{
                                                                                display: "flex",
                                                                                gap: "8px",
                                                                                alignItems:
                                                                                    "center",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={document}
                                                                                alt=""
                                                                            />
                                                                            <div
                                                                                className="lg-bm-fl-g"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    flexDirection:
                                                                                        "column",
                                                                                }}
                                                                            >
                                                                                <h4
                                                                                    style={{
                                                                                        padding:
                                                                                            "0px",
                                                                                        margin: "0px",
                                                                                        fontSize:
                                                                                            "16px",
                                                                                        fontWeight:
                                                                                            "600",
                                                                                        lineHeight:
                                                                                            "24px",
                                                                                        letterSpacing:
                                                                                            "0.02em",
                                                                                        textAlign:
                                                                                            "left",
                                                                                        color: "#101828",
                                                                                    }}
                                                                                >
                                                                                    Valid means of
                                                                                    ID
                                                                                </h4>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "12px",
                                                                                        fontWeight:
                                                                                            "400",
                                                                                        lineHeight:
                                                                                            "16px",
                                                                                        letterSpacing:
                                                                                            "0.02em",
                                                                                        textAlign:
                                                                                            "left",
                                                                                        color: "#667085",
                                                                                    }}
                                                                                >
                                                                                    Government-issued
                                                                                    e.g. NIN
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        {loadeddocument.includes(
                                                                            2
                                                                        ) ? (
                                                                            <img src={check} />
                                                                        ) : businessDocument
                                                                              ?.SoleProprietorship
                                                                              ?.VALID_ID?.status ===
                                                                          "PENDING" ? (
                                                                            <img
                                                                                src={
                                                                                    loadingbusiness
                                                                                }
                                                                            />
                                                                        ) : businessDocument
                                                                              ?.SoleProprietorship
                                                                              ?.VALID_ID?.status ===
                                                                          "APPROVED" ? (
                                                                            <img
                                                                                src={busiesssuccess}
                                                                            />
                                                                        ) : businessDocument
                                                                              ?.SoleProprietorship
                                                                              ?.VALID_ID?.status ===
                                                                          "REJECTED" ? (
                                                                            <img
                                                                                src={busiessscancel}
                                                                            />
                                                                        ) : (
                                                                            <span className="material-icons">
                                                                                chevron_right
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <div
                                                                        className="each-profile-input-kyc"
                                                                        style={{
                                                                            position: "relative",

                                                                            display: "flex",

                                                                            alignItems: "center",
                                                                            padding: "16px",
                                                                            background: "#fff",
                                                                            borderBottom:
                                                                                "1px solid #E7E9FB",
                                                                            justifyContent:
                                                                                "space-between",
                                                                        }}
                                                                        onClick={() => {
                                                                            // alert("Hello");
                                                                            if (
                                                                                businessDocument
                                                                                    ?.proofOfAddress
                                                                                    ?.status !==
                                                                                "APPROVED"
                                                                            )
                                                                                setshowdocument(
                                                                                    true
                                                                                );
                                                                            setfilename([]);
                                                                            setfilesize(0);

                                                                            settitle(
                                                                                "Proof of business address"
                                                                            );
                                                                            setbusinesindex(3);
                                                                            setloadercc(false);
                                                                            setloading(false);
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="lg-mg-lo-pl"
                                                                            style={{
                                                                                display: "flex",
                                                                                gap: "8px",
                                                                                alignItems:
                                                                                    "center",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={document}
                                                                                alt=""
                                                                            />
                                                                            <div
                                                                                className="lg-bm-fl-g"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    flexDirection:
                                                                                        "column",
                                                                                }}
                                                                            >
                                                                                <h4
                                                                                    style={{
                                                                                        padding:
                                                                                            "0px",
                                                                                        margin: "0px",
                                                                                        fontSize:
                                                                                            "16px",
                                                                                        fontWeight:
                                                                                            "600",
                                                                                        lineHeight:
                                                                                            "24px",
                                                                                        letterSpacing:
                                                                                            "0.02em",
                                                                                        textAlign:
                                                                                            "left",
                                                                                        color: "#101828",
                                                                                    }}
                                                                                >
                                                                                    Proof of
                                                                                    business address
                                                                                </h4>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "12px",
                                                                                        fontWeight:
                                                                                            "400",
                                                                                        lineHeight:
                                                                                            "16px",
                                                                                        letterSpacing:
                                                                                            "0.02em",
                                                                                        textAlign:
                                                                                            "left",
                                                                                        color: "#667085",
                                                                                    }}
                                                                                >
                                                                                    Utility bill
                                                                                    within last 3
                                                                                    months
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        {loadeddocument.includes(
                                                                            3
                                                                        ) ? (
                                                                            <img src={check} />
                                                                        ) : businessDocument
                                                                              ?.proofOfAddress
                                                                              ?.status ===
                                                                          "PENDING" ? (
                                                                            <img
                                                                                src={
                                                                                    loadingbusiness
                                                                                }
                                                                            />
                                                                        ) : businessDocument
                                                                              ?.proofOfAddress
                                                                              ?.status ===
                                                                          "APPROVED" ? (
                                                                            <img
                                                                                src={busiesssuccess}
                                                                            />
                                                                        ) : businessDocument
                                                                              ?.proofOfAddress
                                                                              ?.status ===
                                                                          "REJECTED" ? (
                                                                            <img
                                                                                src={busiessscancel}
                                                                            />
                                                                        ) : (
                                                                            <span className="material-icons">
                                                                                chevron_right
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                        {selectorbusiness?.type ===
                                                            "SoleProprietorship" && (
                                                            <>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="each-profile-input-kyc"
                                                                        style={{
                                                                            position: "relative",
                                                                            display: "flex",

                                                                            alignItems: "center",
                                                                            padding: "16px",
                                                                            background: "#fff",
                                                                            borderBottom:
                                                                                "1px solid #E7E9FB",
                                                                            justifyContent:
                                                                                "space-between",
                                                                        }}
                                                                        onClick={() => {
                                                                            //  alert("Hello");
                                                                            if (
                                                                                businessDocument
                                                                                    ?.SoleProprietorship
                                                                                    ?.CAC_BN
                                                                                    ?.status !==
                                                                                "APPROVED"
                                                                            )
                                                                                setshowmodalbusiness(
                                                                                    true
                                                                                );
                                                                            setfilename([]);
                                                                            setfilesize(0);
                                                                            settitle(
                                                                                "CAC BN Form 1"
                                                                            );
                                                                            setbusinesindex(15);
                                                                            setloadercc(false);
                                                                            setloading(false);
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="lg-mg-lo-pl"
                                                                            style={{
                                                                                display: "flex",
                                                                                gap: "8px",
                                                                                alignItems:
                                                                                    "center",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={document}
                                                                                alt=""
                                                                            />
                                                                            <div
                                                                                className="lg-bm-fl-g"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    flexDirection:
                                                                                        "column",
                                                                                }}
                                                                            >
                                                                                <h4
                                                                                    style={{
                                                                                        padding:
                                                                                            "0px",
                                                                                        margin: "0px",
                                                                                        fontSize:
                                                                                            "16px",
                                                                                        fontWeight:
                                                                                            "600",
                                                                                        lineHeight:
                                                                                            "24px",
                                                                                        letterSpacing:
                                                                                            "0.02em",
                                                                                        textAlign:
                                                                                            "left",
                                                                                        color: "#101828",
                                                                                    }}
                                                                                >
                                                                                    CAC BN Form 1
                                                                                    (Optional)
                                                                                </h4>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "12px",
                                                                                        fontWeight:
                                                                                            "400",
                                                                                        lineHeight:
                                                                                            "16px",
                                                                                        letterSpacing:
                                                                                            "0.02em",
                                                                                        textAlign:
                                                                                            "left",
                                                                                        color: "#667085",
                                                                                    }}
                                                                                >
                                                                                    Statement of
                                                                                    share capital
                                                                                    and return
                                                                                    allotment of
                                                                                    shares
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        {loadeddocument.includes(
                                                                            15
                                                                        ) ? (
                                                                            <img src={check} />
                                                                        ) : businessDocument
                                                                              ?.SoleProprietorship
                                                                              ?.CAC_BN?.status ===
                                                                          "PENDING" ? (
                                                                            <img
                                                                                src={
                                                                                    loadingbusiness
                                                                                }
                                                                            />
                                                                        ) : businessDocument
                                                                              ?.SoleProprietorship
                                                                              ?.CAC_BN?.status ===
                                                                          "APPROVED" ? (
                                                                            <img
                                                                                src={busiesssuccess}
                                                                            />
                                                                        ) : businessDocument
                                                                              ?.SoleProprietorship
                                                                              ?.CAC_BN?.status ===
                                                                          "REJECTED" ? (
                                                                            <img
                                                                                src={busiessscancel}
                                                                            />
                                                                        ) : (
                                                                            <span className="material-icons">
                                                                                chevron_right
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <div
                                                                        className="each-profile-input-kyc"
                                                                        style={{
                                                                            position: "relative",

                                                                            display: "flex",

                                                                            alignItems: "center",
                                                                            padding: "16px",
                                                                            background: "#fff",
                                                                            borderBottom:
                                                                                "1px solid #E7E9FB",
                                                                            justifyContent:
                                                                                "space-between",
                                                                        }}
                                                                        onClick={() => {
                                                                            // alert("Hello");
                                                                            if (
                                                                                businessDocument
                                                                                    ?.SoleProprietorship
                                                                                    ?.APPLICATION_LETTER
                                                                                    ?.status !==
                                                                                "APPROVED"
                                                                            )
                                                                                // setshowdocument(true);
                                                                                setshowmodalbusiness(
                                                                                    true
                                                                                );

                                                                            setfilename([]);
                                                                            setfilesize(0);

                                                                            settitle(
                                                                                "Application Letter"
                                                                            );
                                                                            setbusinesindex(17);
                                                                            setloadercc(false);
                                                                            setloading(false);
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="lg-mg-lo-pl"
                                                                            style={{
                                                                                display: "flex",
                                                                                gap: "8px",
                                                                                alignItems:
                                                                                    "center",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={document}
                                                                                alt=""
                                                                            />
                                                                            <div
                                                                                className="lg-bm-fl-g"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    flexDirection:
                                                                                        "column",
                                                                                }}
                                                                            >
                                                                                <h4
                                                                                    style={{
                                                                                        padding:
                                                                                            "0px",
                                                                                        margin: "0px",
                                                                                        fontSize:
                                                                                            "16px",
                                                                                        fontWeight:
                                                                                            "600",
                                                                                        lineHeight:
                                                                                            "24px",
                                                                                        letterSpacing:
                                                                                            "0.02em",
                                                                                        textAlign:
                                                                                            "left",
                                                                                        color: "#101828",
                                                                                    }}
                                                                                >
                                                                                    Application
                                                                                    letter to open
                                                                                    an account with
                                                                                    Tradevu
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                        {loadeddocument.includes(
                                                                            17
                                                                        ) ? (
                                                                            <img src={check} />
                                                                        ) : businessDocument
                                                                              ?.SoleProprietorship
                                                                              ?.APPLICATION_LETTER
                                                                              ?.status ===
                                                                          "PENDING" ? (
                                                                            <img
                                                                                src={
                                                                                    loadingbusiness
                                                                                }
                                                                            />
                                                                        ) : businessDocument
                                                                              ?.SoleProprietorship
                                                                              ?.APPLICATION_LETTER
                                                                              ?.status ===
                                                                          "APPROVED" ? (
                                                                            <img
                                                                                src={busiesssuccess}
                                                                            />
                                                                        ) : businessDocument
                                                                              ?.SoleProprietorship
                                                                              ?.APPLICATION_LETTER
                                                                              ?.status ===
                                                                          "REJECTED" ? (
                                                                            <img
                                                                                src={busiessscancel}
                                                                            />
                                                                        ) : (
                                                                            <span className="material-icons">
                                                                                chevron_right
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <div
                                                                        className="each-profile-input-kyc"
                                                                        style={{
                                                                            position: "relative",

                                                                            display: "flex",

                                                                            alignItems: "center",
                                                                            padding: "16px",
                                                                            background: "#fff",
                                                                            borderBottom:
                                                                                "1px solid #E7E9FB",
                                                                            justifyContent:
                                                                                "space-between",
                                                                        }}
                                                                        onClick={() =>
                                                                            setShowModal(true)
                                                                        }
                                                                    >
                                                                        <div
                                                                            className="lg-mg-lo-pl"
                                                                            style={{
                                                                                display: "flex",
                                                                                gap: "8px",
                                                                                alignItems:
                                                                                    "center",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={document}
                                                                                alt=""
                                                                            />
                                                                            <div
                                                                                className="lg-bm-fl-g"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    flexDirection:
                                                                                        "column",
                                                                                }}
                                                                            >
                                                                                <h4
                                                                                    style={{
                                                                                        padding:
                                                                                            "0px",
                                                                                        margin: "0px",
                                                                                        fontSize:
                                                                                            "16px",
                                                                                        fontWeight:
                                                                                            "600",
                                                                                        lineHeight:
                                                                                            "24px",
                                                                                        letterSpacing:
                                                                                            "0.02em",
                                                                                        textAlign:
                                                                                            "left",
                                                                                        color: "#101828",
                                                                                    }}
                                                                                >
                                                                                    BVN of the Sole
                                                                                    Propeirtors or
                                                                                    Partner
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                        {selectorbusiness?.type === "LLC" && (
                                                            <>
                                                                <div className="form-details-lg-hl">
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="each-profile-input-kyc"
                                                                            style={{
                                                                                position:
                                                                                    "relative",

                                                                                display: "flex",

                                                                                alignItems:
                                                                                    "center",
                                                                                padding: "16px",
                                                                                background: "#fff",
                                                                                borderBottom:
                                                                                    "1px solid #E7E9FB",
                                                                                justifyContent:
                                                                                    "space-between",
                                                                            }}
                                                                            onClick={() => {
                                                                                if (
                                                                                    businessDocument
                                                                                        ?.LLC
                                                                                        ?.CAC_FORM_2
                                                                                        ?.status !==
                                                                                    "APPROVED"
                                                                                )
                                                                                    //  alert("Hello");
                                                                                    setshowmodalbusiness(
                                                                                        true
                                                                                    );
                                                                                setfilename([]);
                                                                                setfilesize(0);
                                                                                settitle(
                                                                                    "CAC Form 2 (Optional)"
                                                                                );
                                                                                setbusinesindex(4);
                                                                                setloadercc(false);
                                                                                setloading(false);
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className="lg-mg-lo-pl"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    gap: "8px",
                                                                                    alignItems:
                                                                                        "center",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={document}
                                                                                    alt=""
                                                                                />
                                                                                <div
                                                                                    className="lg-bm-fl-g"
                                                                                    style={{
                                                                                        display:
                                                                                            "flex",
                                                                                        flexDirection:
                                                                                            "column",
                                                                                    }}
                                                                                >
                                                                                    <h4
                                                                                        style={{
                                                                                            padding:
                                                                                                "0px",
                                                                                            margin: "0px",
                                                                                            fontSize:
                                                                                                "16px",
                                                                                            fontWeight:
                                                                                                "600",
                                                                                            lineHeight:
                                                                                                "24px",
                                                                                            letterSpacing:
                                                                                                "0.02em",
                                                                                            textAlign:
                                                                                                "left",
                                                                                            color: "#101828",
                                                                                        }}
                                                                                    >
                                                                                        CAC Form 2
                                                                                        (Optional)
                                                                                    </h4>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize:
                                                                                                "12px",
                                                                                            fontWeight:
                                                                                                "400",
                                                                                            lineHeight:
                                                                                                "16px",
                                                                                            letterSpacing:
                                                                                                "0.02em",
                                                                                            textAlign:
                                                                                                "left",
                                                                                            color: "#667085",
                                                                                        }}
                                                                                    >
                                                                                        Statement of
                                                                                        share
                                                                                        capital and
                                                                                        return of
                                                                                        allotment of
                                                                                        shares
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            {loadeddocument.includes(
                                                                                4
                                                                            ) ? (
                                                                                <img src={check} />
                                                                            ) : businessDocument
                                                                                  ?.LLC?.CAC_FORM_2
                                                                                  ?.status ===
                                                                              "PENDING" ? (
                                                                                <img
                                                                                    src={
                                                                                        loadingbusiness
                                                                                    }
                                                                                />
                                                                            ) : businessDocument
                                                                                  ?.LLC?.CAC_FORM_2
                                                                                  ?.status ===
                                                                              "APPROVED" ? (
                                                                                <img
                                                                                    src={
                                                                                        busiesssuccess
                                                                                    }
                                                                                />
                                                                            ) : businessDocument
                                                                                  ?.LLC?.CAC_FORM_2
                                                                                  ?.status ===
                                                                              "REJECTED" ? (
                                                                                <img
                                                                                    src={
                                                                                        busiessscancel
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <span className="material-icons">
                                                                                    chevron_right
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                        <div
                                                                            className="each-profile-input-kyc"
                                                                            style={{
                                                                                position:
                                                                                    "relative",

                                                                                display: "flex",

                                                                                alignItems:
                                                                                    "center",
                                                                                padding: "16px",
                                                                                background: "#fff",
                                                                                borderBottom:
                                                                                    "1px solid #E7E9FB",
                                                                                justifyContent:
                                                                                    "space-between",
                                                                            }}
                                                                            onClick={() => {
                                                                                //  alert("Hello");
                                                                                if (
                                                                                    businessDocument
                                                                                        ?.LLC
                                                                                        ?.CAC_FORM_2_1
                                                                                        ?.status !==
                                                                                    "APPROVED"
                                                                                )
                                                                                    setshowmodalbusiness(
                                                                                        true
                                                                                    );
                                                                                setfilename([]);
                                                                                setfilesize(0);
                                                                                settitle(
                                                                                    "CAC Form 2.1 (Optional)"
                                                                                );
                                                                                setbusinesindex(5);
                                                                                setloadercc(false);
                                                                                setloading(false);
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className="lg-mg-lo-pl"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    gap: "8px",
                                                                                    alignItems:
                                                                                        "center",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={document}
                                                                                    alt=""
                                                                                />
                                                                                <div
                                                                                    className="lg-bm-fl-g"
                                                                                    style={{
                                                                                        display:
                                                                                            "flex",
                                                                                        flexDirection:
                                                                                            "column",
                                                                                    }}
                                                                                >
                                                                                    <h4
                                                                                        style={{
                                                                                            padding:
                                                                                                "0px",
                                                                                            margin: "0px",
                                                                                            fontSize:
                                                                                                "16px",
                                                                                            fontWeight:
                                                                                                "600",
                                                                                            lineHeight:
                                                                                                "24px",
                                                                                            letterSpacing:
                                                                                                "0.02em",
                                                                                            textAlign:
                                                                                                "left",
                                                                                            color: "#101828",
                                                                                        }}
                                                                                    >
                                                                                        CAC Form 2.1
                                                                                        (Optional)
                                                                                    </h4>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize:
                                                                                                "12px",
                                                                                            fontWeight:
                                                                                                "400",
                                                                                            lineHeight:
                                                                                                "16px",
                                                                                            letterSpacing:
                                                                                                "0.02em",
                                                                                            textAlign:
                                                                                                "left",
                                                                                            color: "#667085",
                                                                                        }}
                                                                                    >
                                                                                        Particulars
                                                                                        of secretary
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            {loadeddocument.includes(
                                                                                5
                                                                            ) ? (
                                                                                <img src={check} />
                                                                            ) : businessDocument
                                                                                  ?.LLC
                                                                                  ?.CAC_FORM_2_1
                                                                                  ?.status ===
                                                                              "PENDING" ? (
                                                                                <img
                                                                                    src={
                                                                                        loadingbusiness
                                                                                    }
                                                                                />
                                                                            ) : businessDocument
                                                                                  ?.LLC
                                                                                  ?.CAC_FORM_2_1
                                                                                  ?.status ===
                                                                              "APPROVED" ? (
                                                                                <img
                                                                                    src={
                                                                                        busiesssuccess
                                                                                    }
                                                                                />
                                                                            ) : businessDocument
                                                                                  ?.LLC
                                                                                  ?.CAC_FORM_2_1
                                                                                  ?.status ===
                                                                              "REJECTED" ? (
                                                                                <img
                                                                                    src={
                                                                                        busiessscancel
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <span className="material-icons">
                                                                                    chevron_right
                                                                                </span>
                                                                            )}
                                                                        </div>

                                                                        <div
                                                                            className="each-profile-input-kyc"
                                                                            style={{
                                                                                position:
                                                                                    "relative",

                                                                                display: "flex",

                                                                                alignItems:
                                                                                    "center",
                                                                                padding: "16px",
                                                                                background: "#fff",
                                                                                borderBottom:
                                                                                    "1px solid #E7E9FB",
                                                                                justifyContent:
                                                                                    "space-between",
                                                                            }}
                                                                            onClick={() => {
                                                                                //  alert("Hello");
                                                                                if (
                                                                                    businessDocument
                                                                                        ?.LLC
                                                                                        ?.CAC_FORM_3
                                                                                        ?.status !==
                                                                                    "APPROVED"
                                                                                )
                                                                                    setshowmodalbusiness(
                                                                                        true
                                                                                    );
                                                                                setfilename([]);
                                                                                setfilesize(0);
                                                                                settitle(
                                                                                    "CAC Form 3 (Optional)"
                                                                                );
                                                                                setbusinesindex(6);
                                                                                setloadercc(false);
                                                                                setloading(false);
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className="lg-mg-lo-pl"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    gap: "8px",
                                                                                    alignItems:
                                                                                        "center",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={document}
                                                                                    alt=""
                                                                                />
                                                                                <div
                                                                                    className="lg-bm-fl-g"
                                                                                    style={{
                                                                                        display:
                                                                                            "flex",
                                                                                        flexDirection:
                                                                                            "column",
                                                                                    }}
                                                                                >
                                                                                    <h4
                                                                                        style={{
                                                                                            padding:
                                                                                                "0px",
                                                                                            margin: "0px",
                                                                                            fontSize:
                                                                                                "16px",
                                                                                            fontWeight:
                                                                                                "600",
                                                                                            lineHeight:
                                                                                                "24px",
                                                                                            letterSpacing:
                                                                                                "0.02em",
                                                                                            textAlign:
                                                                                                "left",
                                                                                            color: "#101828",
                                                                                        }}
                                                                                    >
                                                                                        CAC Form 3
                                                                                        (Optional)
                                                                                    </h4>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize:
                                                                                                "12px",
                                                                                            fontWeight:
                                                                                                "400",
                                                                                            lineHeight:
                                                                                                "16px",
                                                                                            letterSpacing:
                                                                                                "0.02em",
                                                                                            textAlign:
                                                                                                "left",
                                                                                            color: "#667085",
                                                                                        }}
                                                                                    >
                                                                                        Notice of
                                                                                        registered
                                                                                        addresses
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            {loadeddocument.includes(
                                                                                6
                                                                            ) ? (
                                                                                <img src={check} />
                                                                            ) : businessDocument
                                                                                  ?.LLC?.CAC_FORM_3
                                                                                  ?.status ===
                                                                              "PENDING" ? (
                                                                                <img
                                                                                    src={
                                                                                        loadingbusiness
                                                                                    }
                                                                                />
                                                                            ) : businessDocument
                                                                                  ?.LLC?.CAC_FORM_3
                                                                                  ?.status ===
                                                                              "APPROVED" ? (
                                                                                <img
                                                                                    src={
                                                                                        busiesssuccess
                                                                                    }
                                                                                />
                                                                            ) : businessDocument
                                                                                  ?.LLC?.CAC_FORM_3
                                                                                  ?.status ===
                                                                              "REJECTED" ? (
                                                                                <img
                                                                                    src={
                                                                                        busiessscancel
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <span className="material-icons">
                                                                                    chevron_right
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                        <div
                                                                            className="each-profile-input-kyc"
                                                                            style={{
                                                                                position:
                                                                                    "relative",

                                                                                display: "flex",

                                                                                alignItems:
                                                                                    "center",
                                                                                padding: "16px",
                                                                                background: "#fff",
                                                                                borderBottom:
                                                                                    "1px solid #E7E9FB",
                                                                                justifyContent:
                                                                                    "space-between",
                                                                            }}
                                                                            onClick={() => {
                                                                                //  alert("Hello");
                                                                                if (
                                                                                    businessDocument
                                                                                        ?.LLC
                                                                                        ?.CAC_FORM_4
                                                                                        ?.status !==
                                                                                    "APPROVED"
                                                                                )
                                                                                    setshowmodalbusiness(
                                                                                        true
                                                                                    );
                                                                                setfilename([]);
                                                                                setfilesize(0);
                                                                                settitle(
                                                                                    " CAC Form 4 (Optional)"
                                                                                );
                                                                                setbusinesindex(7);
                                                                                setloadercc(false);
                                                                                setloading(false);
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className="lg-mg-lo-pl"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    gap: "8px",
                                                                                    alignItems:
                                                                                        "center",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={document}
                                                                                    alt=""
                                                                                />
                                                                                <div
                                                                                    className="lg-bm-fl-g"
                                                                                    style={{
                                                                                        display:
                                                                                            "flex",
                                                                                        flexDirection:
                                                                                            "column",
                                                                                    }}
                                                                                >
                                                                                    <h4
                                                                                        style={{
                                                                                            padding:
                                                                                                "0px",
                                                                                            margin: "0px",
                                                                                            fontSize:
                                                                                                "16px",
                                                                                            fontWeight:
                                                                                                "600",
                                                                                            lineHeight:
                                                                                                "24px",
                                                                                            letterSpacing:
                                                                                                "0.02em",
                                                                                            textAlign:
                                                                                                "left",
                                                                                            color: "#101828",
                                                                                        }}
                                                                                    >
                                                                                        CAC Form 4
                                                                                        (Optional)
                                                                                    </h4>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize:
                                                                                                "12px",
                                                                                            fontWeight:
                                                                                                "400",
                                                                                            lineHeight:
                                                                                                "16px",
                                                                                            letterSpacing:
                                                                                                "0.02em",
                                                                                            textAlign:
                                                                                                "left",
                                                                                            color: "#667085",
                                                                                        }}
                                                                                    >
                                                                                        Declaration
                                                                                        of
                                                                                        compliance
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            {loadeddocument.includes(
                                                                                7
                                                                            ) ? (
                                                                                <img src={check} />
                                                                            ) : businessDocument
                                                                                  ?.LLC?.CAC_FORM_4
                                                                                  ?.status ===
                                                                              "PENDING" ? (
                                                                                <img
                                                                                    src={
                                                                                        loadingbusiness
                                                                                    }
                                                                                />
                                                                            ) : businessDocument
                                                                                  ?.LLC?.CAC_FORM_4
                                                                                  ?.status ===
                                                                              "APPROVED" ? (
                                                                                <img
                                                                                    src={
                                                                                        busiesssuccess
                                                                                    }
                                                                                />
                                                                            ) : businessDocument
                                                                                  ?.LLC?.CAC_FORM_4
                                                                                  ?.status ===
                                                                              "REJECTED" ? (
                                                                                <img
                                                                                    src={
                                                                                        busiessscancel
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <span className="material-icons">
                                                                                    chevron_right
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                        <div
                                                                            className="each-profile-input-kyc"
                                                                            style={{
                                                                                position:
                                                                                    "relative",

                                                                                display: "flex",

                                                                                alignItems:
                                                                                    "center",
                                                                                padding: "16px",
                                                                                background: "#fff",
                                                                                borderBottom:
                                                                                    "1px solid #E7E9FB",
                                                                                justifyContent:
                                                                                    "space-between",
                                                                            }}
                                                                            onClick={() => {
                                                                                //  alert("Hello");
                                                                                if (
                                                                                    businessDocument
                                                                                        ?.LLC
                                                                                        ?.CAC_FORM_7
                                                                                        ?.status !==
                                                                                    "APPROVED"
                                                                                )
                                                                                    setshowmodalbusiness(
                                                                                        true
                                                                                    );
                                                                                setfilename([]);
                                                                                setfilesize(0);
                                                                                settitle(
                                                                                    "CAC Form 7 (Optional)"
                                                                                );
                                                                                setbusinesindex(8);
                                                                                setloadercc(false);
                                                                                setloading(false);
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className="lg-mg-lo-pl"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    gap: "8px",
                                                                                    alignItems:
                                                                                        "center",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={document}
                                                                                    alt=""
                                                                                />
                                                                                <div
                                                                                    className="lg-bm-fl-g"
                                                                                    style={{
                                                                                        display:
                                                                                            "flex",
                                                                                        flexDirection:
                                                                                            "column",
                                                                                    }}
                                                                                >
                                                                                    <h4
                                                                                        style={{
                                                                                            padding:
                                                                                                "0px",
                                                                                            margin: "0px",
                                                                                            fontSize:
                                                                                                "16px",
                                                                                            fontWeight:
                                                                                                "600",
                                                                                            lineHeight:
                                                                                                "24px",
                                                                                            letterSpacing:
                                                                                                "0.02em",
                                                                                            textAlign:
                                                                                                "left",
                                                                                            color: "#101828",
                                                                                        }}
                                                                                    >
                                                                                        CAC Form 7
                                                                                        (Optional)
                                                                                    </h4>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize:
                                                                                                "12px",
                                                                                            fontWeight:
                                                                                                "400",
                                                                                            lineHeight:
                                                                                                "16px",
                                                                                            letterSpacing:
                                                                                                "0.02em",
                                                                                            textAlign:
                                                                                                "left",
                                                                                            color: "#667085",
                                                                                        }}
                                                                                    >
                                                                                        Particulars
                                                                                        of directors
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            {loadeddocument.includes(
                                                                                8
                                                                            ) ? (
                                                                                <img src={check} />
                                                                            ) : businessDocument
                                                                                  ?.LLC?.CAC_FORM_7
                                                                                  ?.status ===
                                                                              "PENDING" ? (
                                                                                <img
                                                                                    src={
                                                                                        loadingbusiness
                                                                                    }
                                                                                />
                                                                            ) : businessDocument
                                                                                  ?.LLC?.CAC_FORM_7
                                                                                  ?.status ===
                                                                              "APPROVED" ? (
                                                                                <img
                                                                                    src={
                                                                                        busiesssuccess
                                                                                    }
                                                                                />
                                                                            ) : businessDocument
                                                                                  ?.LLC?.CAC_FORM_7
                                                                                  ?.status ===
                                                                              "REJECTED" ? (
                                                                                <img
                                                                                    src={
                                                                                        busiessscancel
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <span className="material-icons">
                                                                                    chevron_right
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                        <div
                                                                            className="each-profile-input-kyc"
                                                                            style={{
                                                                                position:
                                                                                    "relative",

                                                                                display: "flex",

                                                                                alignItems:
                                                                                    "center",
                                                                                padding: "16px",
                                                                                background: "#fff",
                                                                                borderBottom:
                                                                                    "1px solid #E7E9FB",
                                                                                justifyContent:
                                                                                    "space-between",
                                                                            }}
                                                                            onClick={() => {
                                                                                //  alert("Hello");
                                                                                if (
                                                                                    businessDocument
                                                                                        ?.LLC
                                                                                        ?.MEMORANDUM_AND_ARTICLE_OF_ASSOCIATION
                                                                                        ?.status !==
                                                                                    "APPROVED"
                                                                                )
                                                                                    setshowmodalbusiness(
                                                                                        true
                                                                                    );
                                                                                setfilename([]);
                                                                                setfilesize(0);
                                                                                settitle(
                                                                                    "Memorandum & articles of association"
                                                                                );
                                                                                setbusinesindex(9);
                                                                                setloadercc(false);
                                                                                setloading(false);
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className="lg-mg-lo-pl"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    gap: "8px",
                                                                                    alignItems:
                                                                                        "center",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={document}
                                                                                    alt=""
                                                                                />
                                                                                <div
                                                                                    className="lg-bm-fl-g"
                                                                                    style={{
                                                                                        display:
                                                                                            "flex",
                                                                                        flexDirection:
                                                                                            "column",
                                                                                    }}
                                                                                >
                                                                                    <h4
                                                                                        style={{
                                                                                            padding:
                                                                                                "0px",
                                                                                            margin: "0px",
                                                                                            fontSize:
                                                                                                "16px",
                                                                                            fontWeight:
                                                                                                "600",
                                                                                            lineHeight:
                                                                                                "24px",
                                                                                            letterSpacing:
                                                                                                "0.02em",
                                                                                            textAlign:
                                                                                                "left",
                                                                                            color: "#101828",
                                                                                        }}
                                                                                    >
                                                                                        Memorandum &
                                                                                        articles of
                                                                                        association
                                                                                    </h4>
                                                                                </div>
                                                                            </div>
                                                                            {loadeddocument.includes(
                                                                                9
                                                                            ) ? (
                                                                                <img src={check} />
                                                                            ) : businessDocument
                                                                                  ?.LLC
                                                                                  ?.MEMORANDUM_AND_ARTICLE_OF_ASSOCIATION
                                                                                  ?.status ===
                                                                              "PENDING" ? (
                                                                                <img
                                                                                    src={
                                                                                        loadingbusiness
                                                                                    }
                                                                                />
                                                                            ) : businessDocument
                                                                                  ?.LLC
                                                                                  ?.MEMORANDUM_AND_ARTICLE_OF_ASSOCIATION
                                                                                  ?.status ===
                                                                              "APPROVED" ? (
                                                                                <img
                                                                                    src={
                                                                                        busiesssuccess
                                                                                    }
                                                                                />
                                                                            ) : businessDocument
                                                                                  ?.LLC
                                                                                  ?.MEMORANDUM_AND_ARTICLE_OF_ASSOCIATION
                                                                                  ?.status ===
                                                                              "REJECTED" ? (
                                                                                <img
                                                                                    src={
                                                                                        busiessscancel
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <span className="material-icons">
                                                                                    chevron_right
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                        <div
                                                                            className="each-profile-input-kyc"
                                                                            style={{
                                                                                position:
                                                                                    "relative",

                                                                                display: "flex",

                                                                                alignItems:
                                                                                    "center",
                                                                                padding: "16px",
                                                                                background: "#fff",
                                                                                borderBottom:
                                                                                    "1px solid #E7E9FB",
                                                                                justifyContent:
                                                                                    "space-between",
                                                                            }}
                                                                            onClick={() => {
                                                                                //  alert("Hello");
                                                                                if (
                                                                                    businessDocument
                                                                                        ?.LLC
                                                                                        ?.BOARD_RESOLUTION
                                                                                        ?.status !==
                                                                                    "APPROVED"
                                                                                )
                                                                                    setshowmodalbusiness(
                                                                                        true
                                                                                    );
                                                                                setfilename([]);
                                                                                setfilesize(0);
                                                                                settitle(
                                                                                    "Board Resolution"
                                                                                );
                                                                                setbusinesindex(10);
                                                                                setloadercc(false);
                                                                                setloading(false);
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className="lg-mg-lo-pl"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    gap: "8px",
                                                                                    alignItems:
                                                                                        "center",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={document}
                                                                                    alt=""
                                                                                />
                                                                                <div
                                                                                    className="lg-bm-fl-g"
                                                                                    style={{
                                                                                        display:
                                                                                            "flex",
                                                                                        flexDirection:
                                                                                            "column",
                                                                                    }}
                                                                                >
                                                                                    <h4
                                                                                        style={{
                                                                                            padding:
                                                                                                "0px",
                                                                                            margin: "0px",
                                                                                            fontSize:
                                                                                                "16px",
                                                                                            fontWeight:
                                                                                                "600",
                                                                                            lineHeight:
                                                                                                "24px",
                                                                                            letterSpacing:
                                                                                                "0.02em",
                                                                                            textAlign:
                                                                                                "left",
                                                                                            color: "#101828",
                                                                                        }}
                                                                                    >
                                                                                        Board
                                                                                        resolution
                                                                                        to open an
                                                                                        account with
                                                                                        Tradevu
                                                                                    </h4>
                                                                                </div>
                                                                            </div>
                                                                            {loadeddocument.includes(
                                                                                10
                                                                            ) ? (
                                                                                <img src={check} />
                                                                            ) : businessDocument
                                                                                  ?.LLC
                                                                                  ?.BOARD_RESOLUTION
                                                                                  ?.status ===
                                                                              "PENDING" ? (
                                                                                <img
                                                                                    src={
                                                                                        loadingbusiness
                                                                                    }
                                                                                />
                                                                            ) : businessDocument
                                                                                  ?.LLC
                                                                                  ?.BOARD_RESOLUTION
                                                                                  ?.status ===
                                                                              "APPROVED" ? (
                                                                                <img
                                                                                    src={
                                                                                        busiesssuccess
                                                                                    }
                                                                                />
                                                                            ) : businessDocument
                                                                                  ?.LLC
                                                                                  ?.BOARD_RESOLUTION
                                                                                  ?.status ===
                                                                              "REJECTED" ? (
                                                                                <img
                                                                                    src={
                                                                                        busiessscancel
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <span className="material-icons">
                                                                                    chevron_right
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                        <div
                                                                            className="each-profile-input-kyc"
                                                                            style={{
                                                                                position:
                                                                                    "relative",

                                                                                display: "flex",

                                                                                alignItems:
                                                                                    "center",
                                                                                padding: "16px",
                                                                                background: "#fff",
                                                                                borderBottom:
                                                                                    "1px solid #E7E9FB",
                                                                                justifyContent:
                                                                                    "space-between",
                                                                            }}
                                                                            onClick={() =>
                                                                                setShowModal(true)
                                                                            }
                                                                        >
                                                                            <div
                                                                                className="lg-mg-lo-pl"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    gap: "8px",
                                                                                    alignItems:
                                                                                        "center",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={document}
                                                                                    alt=""
                                                                                />
                                                                                <div
                                                                                    className="lg-bm-fl-g"
                                                                                    style={{
                                                                                        display:
                                                                                            "flex",
                                                                                        flexDirection:
                                                                                            "column",
                                                                                    }}
                                                                                >
                                                                                    <h4
                                                                                        style={{
                                                                                            padding:
                                                                                                "0px",
                                                                                            margin: "0px",
                                                                                            fontSize:
                                                                                                "16px",
                                                                                            fontWeight:
                                                                                                "600",
                                                                                            lineHeight:
                                                                                                "24px",
                                                                                            letterSpacing:
                                                                                                "0.02em",
                                                                                            textAlign:
                                                                                                "left",
                                                                                            color: "#101828",
                                                                                        }}
                                                                                    >
                                                                                        BVN of the
                                                                                        Directors of
                                                                                        the Company
                                                                                    </h4>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                        {/***

                            {businessDocument?.SoleProprietorship && (
                              <>
                                Sole
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    className="each-profile-input-kyc"
                                    style={{
                                      position: "relative",
                                      display: "flex",

                                      alignItems: "center",
                                      padding: "16px",
                                      background: "#fff",
                                      borderBottom: "1px solid #E7E9FB",
                                      justifyContent: "space-between",
                                    }}
                                    onClick={() => {
                                      //  alert("Hello");
                                      if (
                                        businessDocument?.LLC?.CAC?.status !==
                                        "APPROVED"
                                      )
                                        setshowmodalbusiness(true);
                                      setfilename([]);
                                      setfilesize(0);
                                      settitle("Certificate of Incorporation");
                                      setbusinesindex(0);
                                      setloadercc(false);
                                      setloading(false);
                                    }}
                                  >
                                    <div
                                      className="lg-mg-lo-pl"
                                      style={{
                                        display: "flex",
                                        gap: "8px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img src={document} alt="" />
                                      <div
                                        className="lg-bm-fl-g"
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <h4
                                          style={{
                                            padding: "0px",
                                            margin: "0px",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            lineHeight: "24px",
                                            letterSpacing: "0.02em",
                                            textAlign: "left",
                                            color: "#101828",
                                          }}
                                        >
                                          Certificate of Incorporation
                                        </h4>
                                        <span
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            lineHeight: "16px",
                                            letterSpacing: "0.02em",
                                            textAlign: "left",
                                            color: "#667085",
                                          }}
                                        >
                                          From your country
                                        </span>
                                      </div>
                                    </div>
                                    {loadeddocument.includes(0) ? (
                                      <img src={check} />
                                    ) : businessDocument?.LLC?.CAC?.status ===
                                      "PENDING" ? (
                                      <img src={loadingbusiness} />
                                    ) : businessDocument?.LLC?.CAC?.status ===
                                      "APPROVED" ? (
                                      <img src={busiesssuccess} />
                                    ) : businessDocument?.LLC?.CAC?.status ===
                                      "REJECTED" ? (
                                      <img src={busiessscancel} />
                                    ) : (
                                      <span className="material-icons">
                                        chevron_right
                                      </span>
                                    )}
                                  </div>
                                  <div
                                    className="each-profile-input-kyc"
                                    style={{
                                      position: "relative",

                                      display: "flex",

                                      alignItems: "center",
                                      padding: "16px",
                                      background: "#fff",
                                      borderBottom: "1px solid #E7E9FB",
                                      justifyContent: "space-between",
                                    }}
                                    onClick={() => {
                                      //  alert("Hello");
                                      if (
                                        businessDocument?.LLC?.CAC_STATUS_REPORT
                                          ?.status !== "APPROVED"
                                      )
                                        setshowmodalbusiness(true);
                                      setfilename([]);
                                      setfilesize(0);
                                      settitle("CAC Status Report (Optional)");
                                      setbusinesindex(1);
                                      setloadercc(false);
                                      setloading(false);
                                    }}
                                  >
                                    <div
                                      className="lg-mg-lo-pl"
                                      style={{
                                        display: "flex",
                                        gap: "8px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img src={document} alt="" />
                                      <div
                                        className="lg-bm-fl-g"
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <h4
                                          style={{
                                            padding: "0px",
                                            margin: "0px",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            lineHeight: "24px",
                                            letterSpacing: "0.02em",
                                            textAlign: "left",
                                            color: "#101828",
                                          }}
                                        >
                                          CAC Status Report (Optional)
                                        </h4>
                                        <span
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            lineHeight: "16px",
                                            letterSpacing: "0.02em",
                                            textAlign: "left",
                                            color: "#667085",
                                          }}
                                        >
                                          Within the last 3 years
                                        </span>
                                      </div>
                                    </div>
                                    {loadeddocument.includes(1) ? (
                                      <img src={check} />
                                    ) : businessDocument?.LLC?.CAC_STATUS_REPORT
                                        ?.status === "PENDING" ? (
                                      <img src={loadingbusiness} />
                                    ) : businessDocument?.LLC?.CAC_STATUS_REPORT
                                        ?.status === "APPROVED" ? (
                                      <img src={busiesssuccess} />
                                    ) : businessDocument?.LLC?.CAC_STATUS_REPORT
                                        ?.status === "REJECTED" ? (
                                      <img src={busiessscancel} />
                                    ) : (
                                      <span className="material-icons">
                                        chevron_right
                                      </span>
                                    )}
                                  </div>

                                  <div
                                    className="each-profile-input-kyc"
                                    style={{
                                      position: "relative",

                                      display: "flex",

                                      alignItems: "center",
                                      padding: "16px",
                                      background: "#fff",
                                      borderBottom: "1px solid #E7E9FB",
                                      justifyContent: "space-between",
                                    }}
                                    onClick={() => {
                                      //  alert("Hello");
                                      if (
                                        businessDocument?.LLC?.VALID_ID
                                          ?.status !== "APPROVED"
                                      )
                                        setshowmodalbusiness(true);
                                      setfilename([]);
                                      setfilesize(0);
                                      settitle(" Valid means of ID");
                                      setbusinesindex(2);
                                      setloadercc(false);
                                      setloading(false);
                                    }}
                                  >
                                    <div
                                      className="lg-mg-lo-pl"
                                      style={{
                                        display: "flex",
                                        gap: "8px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img src={document} alt="" />
                                      <div
                                        className="lg-bm-fl-g"
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <h4
                                          style={{
                                            padding: "0px",
                                            margin: "0px",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            lineHeight: "24px",
                                            letterSpacing: "0.02em",
                                            textAlign: "left",
                                            color: "#101828",
                                          }}
                                        >
                                          Valid means of ID
                                        </h4>
                                        <span
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            lineHeight: "16px",
                                            letterSpacing: "0.02em",
                                            textAlign: "left",
                                            color: "#667085",
                                          }}
                                        >
                                          Government-issued e.g. NIN
                                        </span>
                                      </div>
                                    </div>
                                    {loadeddocument.includes(2) ? (
                                      <img src={check} />
                                    ) : businessDocument?.LLC?.VALID_ID
                                        ?.status === "PENDING" ? (
                                      <img src={loadingbusiness} />
                                    ) : businessDocument?.LLC?.VALID_ID
                                        ?.status === "APPROVED" ? (
                                      <img src={busiesssuccess} />
                                    ) : businessDocument?.LLC?.VALID_ID
                                        ?.status === "REJECTED" ? (
                                      <img src={busiessscancel} />
                                    ) : (
                                      <span className="material-icons">
                                        chevron_right
                                      </span>
                                    )}
                                  </div>
                                  <div
                                    className="each-profile-input-kyc"
                                    style={{
                                      position: "relative",

                                      display: "flex",

                                      alignItems: "center",
                                      padding: "16px",
                                      background: "#fff",
                                      borderBottom: "1px solid #E7E9FB",
                                      justifyContent: "space-between",
                                    }}
                                    onClick={() => {
                                      // alert("Hello");
                                      if (
                                        businessDocument?.proofOfAddress
                                          ?.status !== "APPROVED"
                                      )
                                        setshowdocument(true);
                                      setfilename([]);
                                      setfilesize(0);

                                      settitle("Proof of business address");
                                      setbusinesindex(3);
                                      setloadercc(false);
                                      setloading(false);
                                    }}
                                  >
                                    <div
                                      className="lg-mg-lo-pl"
                                      style={{
                                        display: "flex",
                                        gap: "8px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img src={document} alt="" />
                                      <div
                                        className="lg-bm-fl-g"
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <h4
                                          style={{
                                            padding: "0px",
                                            margin: "0px",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            lineHeight: "24px",
                                            letterSpacing: "0.02em",
                                            textAlign: "left",
                                            color: "#101828",
                                          }}
                                        >
                                          Proof of business address
                                        </h4>
                                        <span
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            lineHeight: "16px",
                                            letterSpacing: "0.02em",
                                            textAlign: "left",
                                            color: "#667085",
                                          }}
                                        >
                                          Utility bill within last 3 months
                                        </span>
                                      </div>
                                    </div>
                                    {loadeddocument.includes(0) ? (
                                      <img src={check} />
                                    ) : businessDocument?.proofOfAddress
                                        ?.status === "PENDING" ? (
                                      <img src={loadingbusiness} />
                                    ) : businessDocument?.proofOfAddress
                                        ?.status === "APPROVED" ? (
                                      <img src={busiesssuccess} />
                                    ) : businessDocument?.proofOfAddress
                                        ?.status === "REJECTED" ? (
                                      <img src={busiessscancel} />
                                    ) : (
                                      <span className="material-icons">
                                        chevron_right
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                            {businessDocument?.SoleProprietorship && (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    className="each-profile-input-kyc"
                                    style={{
                                      position: "relative",
                                      display: "flex",

                                      alignItems: "center",
                                      padding: "16px",
                                      background: "#fff",
                                      borderBottom: "1px solid #E7E9FB",
                                      justifyContent: "space-between",
                                    }}
                                    onClick={() => {
                                      //  alert("Hello");
                                      if (
                                        businessDocument?.SoleProprietorship
                                          ?.CAC?.status !== "APPROVED"
                                      )
                                        setshowmodalbusiness(true);
                                      setfilename([]);
                                      setfilesize(0);
                                      settitle("Certificate of Incorporation");
                                      setbusinesindex(0);
                                      setloadercc(false);
                                      setloading(false);
                                    }}
                                  >
                                    <div
                                      className="lg-mg-lo-pl"
                                      style={{
                                        display: "flex",
                                        gap: "8px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img src={document} alt="" />
                                      <div
                                        className="lg-bm-fl-g"
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <h4
                                          style={{
                                            padding: "0px",
                                            margin: "0px",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            lineHeight: "24px",
                                            letterSpacing: "0.02em",
                                            textAlign: "left",
                                            color: "#101828",
                                          }}
                                        >
                                          Certificate of Incorporation
                                        </h4>
                                        <span
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            lineHeight: "16px",
                                            letterSpacing: "0.02em",
                                            textAlign: "left",
                                            color: "#667085",
                                          }}
                                        >
                                          From your country
                                        </span>
                                      </div>
                                    </div>
                                    {loadeddocument.includes(0) ? (
                                      <img src={check} />
                                    ) : businessDocument?.SoleProprietorship
                                        ?.CAC?.status === "PENDING" ? (
                                      <img src={loadingbusiness} />
                                    ) : businessDocument?.SoleProprietorship
                                        ?.CAC?.status === "APPROVED" ? (
                                      <img src={busiesssuccess} />
                                    ) : businessDocument?.SoleProprietorship
                                        ?.CAC?.status === "REJECTED" ? (
                                      <img src={busiessscancel} />
                                    ) : (
                                      <span className="material-icons">
                                        chevron_right
                                      </span>
                                    )}
                                  </div>
                                  <div
                                    className="each-profile-input-kyc"
                                    style={{
                                      position: "relative",

                                      display: "flex",

                                      alignItems: "center",
                                      padding: "16px",
                                      background: "#fff",
                                      borderBottom: "1px solid #E7E9FB",
                                      justifyContent: "space-between",
                                    }}
                                    onClick={() => {
                                      //  alert("Hello");
                                      if (
                                        businessDocument?.SoleProprietorship
                                          ?.CAC_STATUS_REPORT?.status !==
                                        "APPROVED"
                                      )
                                        setshowmodalbusiness(true);
                                      setfilename([]);
                                      setfilesize(0);
                                      settitle("CAC Status Report (Optional)");
                                      setbusinesindex(1);
                                      setloadercc(false);
                                      setloading(false);
                                    }}
                                  >
                                    <div
                                      className="lg-mg-lo-pl"
                                      style={{
                                        display: "flex",
                                        gap: "8px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img src={document} alt="" />
                                      <div
                                        className="lg-bm-fl-g"
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <h4
                                          style={{
                                            padding: "0px",
                                            margin: "0px",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            lineHeight: "24px",
                                            letterSpacing: "0.02em",
                                            textAlign: "left",
                                            color: "#101828",
                                          }}
                                        >
                                          CAC Status Report (Optional)
                                        </h4>
                                        <span
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            lineHeight: "16px",
                                            letterSpacing: "0.02em",
                                            textAlign: "left",
                                            color: "#667085",
                                          }}
                                        >
                                          Within the last 3 years
                                        </span>
                                      </div>
                                    </div>
                                    {loadeddocument.includes(1) ? (
                                      <img src={check} />
                                    ) : businessDocument?.SoleProprietorship
                                        ?.CAC_STATUS_REPORT?.status ===
                                      "PENDING" ? (
                                      <img src={loadingbusiness} />
                                    ) : businessDocument?.SoleProprietorship
                                        ?.CAC_STATUS_REPORT?.status ===
                                      "APPROVED" ? (
                                      <img src={busiesssuccess} />
                                    ) : businessDocument?.SoleProprietorship
                                        ?.CAC_STATUS_REPORT?.status ===
                                      "REJECTED" ? (
                                      <img src={busiessscancel} />
                                    ) : (
                                      <span className="material-icons">
                                        chevron_right
                                      </span>
                                    )}
                                  </div>

                                  <div
                                    className="each-profile-input-kyc"
                                    style={{
                                      position: "relative",

                                      display: "flex",

                                      alignItems: "center",
                                      padding: "16px",
                                      background: "#fff",
                                      borderBottom: "1px solid #E7E9FB",
                                      justifyContent: "space-between",
                                    }}
                                    onClick={() => {
                                      //  alert("Hello");
                                      if (
                                        businessDocument?.LLC?.VALID_ID
                                          ?.status !== "APPROVED"
                                      )
                                        setshowmodalbusiness(true);
                                      setfilename([]);
                                      setfilesize(0);
                                      settitle(" Valid means of ID");
                                      setbusinesindex(2);
                                      setloadercc(false);
                                      setloading(false);
                                    }}
                                  >
                                    <div
                                      className="lg-mg-lo-pl"
                                      style={{
                                        display: "flex",
                                        gap: "8px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img src={document} alt="" />
                                      <div
                                        className="lg-bm-fl-g"
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <h4
                                          style={{
                                            padding: "0px",
                                            margin: "0px",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            lineHeight: "24px",
                                            letterSpacing: "0.02em",
                                            textAlign: "left",
                                            color: "#101828",
                                          }}
                                        >
                                          Valid means of ID
                                        </h4>
                                        <span
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            lineHeight: "16px",
                                            letterSpacing: "0.02em",
                                            textAlign: "left",
                                            color: "#667085",
                                          }}
                                        >
                                          Government-issued e.g. NIN
                                        </span>
                                      </div>
                                    </div>
                                    {loadeddocument.includes(2) ? (
                                      <img src={check} />
                                    ) : businessDocument?.SoleProprietorship
                                        ?.VALID_ID?.status === "PENDING" ? (
                                      <img src={loadingbusiness} />
                                    ) : businessDocument?.SoleProprietorship
                                        ?.VALID_ID?.status === "APPROVED" ? (
                                      <img src={busiesssuccess} />
                                    ) : businessDocument?.SoleProprietorship
                                        ?.VALID_ID?.status === "REJECTED" ? (
                                      <img src={busiessscancel} />
                                    ) : (
                                      <span className="material-icons">
                                        chevron_right
                                      </span>
                                    )}
                                  </div>
                                  <div
                                    className="each-profile-input-kyc"
                                    style={{
                                      position: "relative",

                                      display: "flex",

                                      alignItems: "center",
                                      padding: "16px",
                                      background: "#fff",
                                      borderBottom: "1px solid #E7E9FB",
                                      justifyContent: "space-between",
                                    }}
                                    onClick={() => {
                                      // alert("Hello");
                                      if (
                                        businessDocument?.proofOfAddress
                                          ?.status !== "APPROVED"
                                      )
                                        setshowdocument(true);
                                      setfilename([]);
                                      setfilesize(0);

                                      settitle("Proof of business address");
                                      setbusinesindex(3);
                                      setloadercc(false);
                                      setloading(false);
                                    }}
                                  >
                                    <div
                                      className="lg-mg-lo-pl"
                                      style={{
                                        display: "flex",
                                        gap: "8px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img src={document} alt="" />
                                      <div
                                        className="lg-bm-fl-g"
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <h4
                                          style={{
                                            padding: "0px",
                                            margin: "0px",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            lineHeight: "24px",
                                            letterSpacing: "0.02em",
                                            textAlign: "left",
                                            color: "#101828",
                                          }}
                                        >
                                          Proof of business address
                                        </h4>
                                        <span
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            lineHeight: "16px",
                                            letterSpacing: "0.02em",
                                            textAlign: "left",
                                            color: "#667085",
                                          }}
                                        >
                                          Utility bill within last 3 months
                                        </span>
                                      </div>
                                    </div>
                                    {loadeddocument.includes(0) ? (
                                      <img src={check} />
                                    ) : businessDocument?.proofOfAddress
                                        ?.status === "PENDING" ? (
                                      <img src={loadingbusiness} />
                                    ) : businessDocument?.proofOfAddress
                                        ?.status === "APPROVED" ? (
                                      <img src={busiesssuccess} />
                                    ) : businessDocument?.proofOfAddress
                                        ?.status === "REJECTED" ? (
                                      <img src={busiessscancel} />
                                    ) : (
                                      <span className="material-icons">
                                        chevron_right
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                            {businessDocument?.SoleProprietorship && (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    className="each-profile-input-kyc"
                                    style={{
                                      position: "relative",
                                      display: "flex",

                                      alignItems: "center",
                                      padding: "16px",
                                      background: "#fff",
                                      borderBottom: "1px solid #E7E9FB",
                                      justifyContent: "space-between",
                                    }}
                                    onClick={() => {
                                      //  alert("Hello");
                                      if (
                                        businessDocument?.SoleProprietorship
                                          ?.CAC_BN?.status !== "APPROVED"
                                      )
                                        setshowmodalbusiness(true);
                                      setfilename([]);
                                      setfilesize(0);
                                      settitle("CAC BN Form 1");
                                      setbusinesindex(0);
                                      setloadercc(false);
                                      setloading(false);
                                    }}
                                  >
                                    <div
                                      className="lg-mg-lo-pl"
                                      style={{
                                        display: "flex",
                                        gap: "8px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img src={document} alt="" />
                                      <div
                                        className="lg-bm-fl-g"
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <h4
                                          style={{
                                            padding: "0px",
                                            margin: "0px",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            lineHeight: "24px",
                                            letterSpacing: "0.02em",
                                            textAlign: "left",
                                            color: "#101828",
                                          }}
                                        >
                                          CAC BN Form 1 (Optional)
                                        </h4>
                                        <span
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            lineHeight: "16px",
                                            letterSpacing: "0.02em",
                                            textAlign: "left",
                                            color: "#667085",
                                          }}
                                        >
                                          Statement of share capital and return
                                          allotment of shares
                                        </span>
                                      </div>
                                    </div>
                                    {loadeddocument.includes(0) ? (
                                      <img src={check} />
                                    ) : businessDocument?.SoleProprietorship
                                        ?.CAC_BN?.status === "PENDING" ? (
                                      <img src={loadingbusiness} />
                                    ) : businessDocument?.SoleProprietorship
                                        ?.CAC_BN?.status === "APPROVED" ? (
                                      <img src={busiesssuccess} />
                                    ) : businessDocument?.SoleProprietorship
                                        ?.CAC_BN?.status === "REJECTED" ? (
                                      <img src={busiessscancel} />
                                    ) : (
                                      <span className="material-icons">
                                        chevron_right
                                      </span>
                                    )}
                                  </div>
                                  <div
                                    className="each-profile-input-kyc"
                                    style={{
                                      position: "relative",

                                      display: "flex",

                                      alignItems: "center",
                                      padding: "16px",
                                      background: "#fff",
                                      borderBottom: "1px solid #E7E9FB",
                                      justifyContent: "space-between",
                                    }}
                                    onClick={() => setShowModal(true)}
                                  >
                                    <div
                                      className="lg-mg-lo-pl"
                                      style={{
                                        display: "flex",
                                        gap: "8px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img src={document} alt="" />
                                      <div
                                        className="lg-bm-fl-g"
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <h4
                                          style={{
                                            padding: "0px",
                                            margin: "0px",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            lineHeight: "24px",
                                            letterSpacing: "0.02em",
                                            textAlign: "left",
                                            color: "#101828",
                                          }}
                                        >
                                          BVN of the Sole Propeirtors or Partner
                                        </h4>
                                      </div>
                                    </div>
                                    {loadeddocument.includes(12) ? (
                                      <img src={check} />
                                    ) : businessDocument?.directors?.length >=
                                      1 ? (
                                      <img src={busiesssuccess} />
                                    ) : (
                                      <span className="material-icons">
                                        chevron_right
                                      </span>
                                    )}
                                  </div>

                                  <div
                                    className="each-profile-input-kyc"
                                    style={{
                                      position: "relative",

                                      display: "flex",

                                      alignItems: "center",
                                      padding: "16px",
                                      background: "#fff",
                                      borderBottom: "1px solid #E7E9FB",
                                      justifyContent: "space-between",
                                    }}
                                    onClick={() => {
                                      // alert("Hello");
                                      if (
                                        businessDocument?.proofOfAddress
                                          ?.status !== "APPROVED"
                                      )
                                        setshowdocument(true);
                                      setfilename([]);
                                      setfilesize(0);

                                      settitle("Proof of business address");
                                      setbusinesindex(3);
                                      setloadercc(false);
                                      setloading(false);
                                    }}
                                  >
                                    <div
                                      className="lg-mg-lo-pl"
                                      style={{
                                        display: "flex",
                                        gap: "8px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img src={document} alt="" />
                                      <div
                                        className="lg-bm-fl-g"
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <h4
                                          style={{
                                            padding: "0px",
                                            margin: "0px",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            lineHeight: "24px",
                                            letterSpacing: "0.02em",
                                            textAlign: "left",
                                            color: "#101828",
                                          }}
                                        >
                                          Application letter to open an account
                                          with Tradevu
                                        </h4>
                                      </div>
                                    </div>
                                    {loadeddocument.includes(0) ? (
                                      <img src={check} />
                                    ) : businessDocument?.proofOfAddress
                                        ?.status === "PENDING" ? (
                                      <img src={loadingbusiness} />
                                    ) : businessDocument?.proofOfAddress
                                        ?.status === "APPROVED" ? (
                                      <img src={busiesssuccess} />
                                    ) : businessDocument?.proofOfAddress
                                        ?.status === "REJECTED" ? (
                                      <img src={busiessscancel} />
                                    ) : (
                                      <span className="material-icons">
                                        chevron_right
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                            {businessDocument?.LLC && (
                              <>
                                <div className="form-details-lg-hl">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <div
                                      className="each-profile-input-kyc"
                                      style={{
                                        position: "relative",

                                        display: "flex",

                                        alignItems: "center",
                                        padding: "16px",
                                        background: "#fff",
                                        borderBottom: "1px solid #E7E9FB",
                                        justifyContent: "space-between",
                                      }}
                                      onClick={() => {
                                        if (
                                          businessDocument?.LLC?.CAC_FORM_2
                                            ?.status !== "APPROVED"
                                        )
                                          //  alert("Hello");
                                          setshowmodalbusiness(true);
                                        setfilename([]);
                                        setfilesize(0);
                                        settitle("CAC Form 2 (Optional)");
                                        setbusinesindex(4);
                                        setloadercc(false);
                                        setloading(false);
                                      }}
                                    >
                                      <div
                                        className="lg-mg-lo-pl"
                                        style={{
                                          display: "flex",
                                          gap: "8px",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img src={document} alt="" />
                                        <div
                                          className="lg-bm-fl-g"
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <h4
                                            style={{
                                              padding: "0px",
                                              margin: "0px",
                                              fontSize: "16px",
                                              fontWeight: "600",
                                              lineHeight: "24px",
                                              letterSpacing: "0.02em",
                                              textAlign: "left",
                                              color: "#101828",
                                            }}
                                          >
                                            CAC Form 2 (Optional)
                                          </h4>
                                          <span
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "400",
                                              lineHeight: "16px",
                                              letterSpacing: "0.02em",
                                              textAlign: "left",
                                              color: "#667085",
                                            }}
                                          >
                                            Statement of share capital and
                                            return of allotment of shares
                                          </span>
                                        </div>
                                      </div>
                                      {loadeddocument.includes(4) ? (
                                        <img src={check} />
                                      ) : businessDocument?.LLC?.CAC_FORM_2
                                          ?.status === "PENDING" ? (
                                        <img src={loadingbusiness} />
                                      ) : businessDocument?.LLC?.CAC_FORM_2
                                          ?.status === "APPROVED" ? (
                                        <img src={busiesssuccess} />
                                      ) : businessDocument?.LLC?.CAC_FORM_2
                                          ?.status === "REJECTED" ? (
                                        <img src={busiessscancel} />
                                      ) : (
                                        <span className="material-icons">
                                          chevron_right
                                        </span>
                                      )}
                                    </div>
                                    <div
                                      className="each-profile-input-kyc"
                                      style={{
                                        position: "relative",

                                        display: "flex",

                                        alignItems: "center",
                                        padding: "16px",
                                        background: "#fff",
                                        borderBottom: "1px solid #E7E9FB",
                                        justifyContent: "space-between",
                                      }}
                                      onClick={() => {
                                        //  alert("Hello");
                                        if (
                                          businessDocument?.LLC?.CAC_FORM_2_1
                                            ?.status !== "APPROVED"
                                        )
                                          setshowmodalbusiness(true);
                                        setfilename([]);
                                        setfilesize(0);
                                        settitle("CAC Form 2.1 (Optional)");
                                        setbusinesindex(5);
                                        setloadercc(false);
                                        setloading(false);
                                      }}
                                    >
                                      <div
                                        className="lg-mg-lo-pl"
                                        style={{
                                          display: "flex",
                                          gap: "8px",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img src={document} alt="" />
                                        <div
                                          className="lg-bm-fl-g"
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <h4
                                            style={{
                                              padding: "0px",
                                              margin: "0px",
                                              fontSize: "16px",
                                              fontWeight: "600",
                                              lineHeight: "24px",
                                              letterSpacing: "0.02em",
                                              textAlign: "left",
                                              color: "#101828",
                                            }}
                                          >
                                            CAC Form 2.1 (Optional)
                                          </h4>
                                          <span
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "400",
                                              lineHeight: "16px",
                                              letterSpacing: "0.02em",
                                              textAlign: "left",
                                              color: "#667085",
                                            }}
                                          >
                                            Particulars of secretary
                                          </span>
                                        </div>
                                      </div>
                                      {loadeddocument.includes(5) ? (
                                        <img src={check} />
                                      ) : businessDocument?.LLC?.CAC_FORM_2_1
                                          ?.status === "PENDING" ? (
                                        <img src={loadingbusiness} />
                                      ) : businessDocument?.LLC?.CAC_FORM_2_1
                                          ?.status === "APPROVED" ? (
                                        <img src={busiesssuccess} />
                                      ) : businessDocument?.LLC?.CAC_FORM_2_1
                                          ?.status === "REJECTED" ? (
                                        <img src={busiessscancel} />
                                      ) : (
                                        <span className="material-icons">
                                          chevron_right
                                        </span>
                                      )}
                                    </div>

                                    <div
                                      className="each-profile-input-kyc"
                                      style={{
                                        position: "relative",

                                        display: "flex",

                                        alignItems: "center",
                                        padding: "16px",
                                        background: "#fff",
                                        borderBottom: "1px solid #E7E9FB",
                                        justifyContent: "space-between",
                                      }}
                                      onClick={() => {
                                        //  alert("Hello");
                                        if (
                                          businessDocument?.LLC?.CAC_FORM_3
                                            ?.status !== "APPROVED"
                                        )
                                          setshowmodalbusiness(true);
                                        setfilename([]);
                                        setfilesize(0);
                                        settitle("CAC Form 3 (Optional)");
                                        setbusinesindex(6);
                                        setloadercc(false);
                                        setloading(false);
                                      }}
                                    >
                                      <div
                                        className="lg-mg-lo-pl"
                                        style={{
                                          display: "flex",
                                          gap: "8px",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img src={document} alt="" />
                                        <div
                                          className="lg-bm-fl-g"
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <h4
                                            style={{
                                              padding: "0px",
                                              margin: "0px",
                                              fontSize: "16px",
                                              fontWeight: "600",
                                              lineHeight: "24px",
                                              letterSpacing: "0.02em",
                                              textAlign: "left",
                                              color: "#101828",
                                            }}
                                          >
                                            CAC Form 3 (Optional)
                                          </h4>
                                          <span
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "400",
                                              lineHeight: "16px",
                                              letterSpacing: "0.02em",
                                              textAlign: "left",
                                              color: "#667085",
                                            }}
                                          >
                                            Notice of registered addresses
                                          </span>
                                        </div>
                                      </div>
                                      {loadeddocument.includes(6) ? (
                                        <img src={check} />
                                      ) : businessDocument?.LLC?.CAC_FORM_3
                                          ?.status === "PENDING" ? (
                                        <img src={loadingbusiness} />
                                      ) : businessDocument?.LLC?.CAC_FORM_3
                                          ?.status === "APPROVED" ? (
                                        <img src={busiesssuccess} />
                                      ) : businessDocument?.LLC?.CAC_FORM_3
                                          ?.status === "REJECTED" ? (
                                        <img src={busiessscancel} />
                                      ) : (
                                        <span className="material-icons">
                                          chevron_right
                                        </span>
                                      )}
                                    </div>
                                    <div
                                      className="each-profile-input-kyc"
                                      style={{
                                        position: "relative",

                                        display: "flex",

                                        alignItems: "center",
                                        padding: "16px",
                                        background: "#fff",
                                        borderBottom: "1px solid #E7E9FB",
                                        justifyContent: "space-between",
                                      }}
                                      onClick={() => {
                                        //  alert("Hello");
                                        if (
                                          businessDocument?.LLC?.CAC_FORM_4
                                            ?.status !== "APPROVED"
                                        )
                                          setshowmodalbusiness(true);
                                        setfilename([]);
                                        setfilesize(0);
                                        settitle(" CAC Form 4 (Optional)");
                                        setbusinesindex(7);
                                        setloadercc(false);
                                        setloading(false);
                                      }}
                                    >
                                      <div
                                        className="lg-mg-lo-pl"
                                        style={{
                                          display: "flex",
                                          gap: "8px",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img src={document} alt="" />
                                        <div
                                          className="lg-bm-fl-g"
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <h4
                                            style={{
                                              padding: "0px",
                                              margin: "0px",
                                              fontSize: "16px",
                                              fontWeight: "600",
                                              lineHeight: "24px",
                                              letterSpacing: "0.02em",
                                              textAlign: "left",
                                              color: "#101828",
                                            }}
                                          >
                                            CAC Form 4 (Optional)
                                          </h4>
                                          <span
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "400",
                                              lineHeight: "16px",
                                              letterSpacing: "0.02em",
                                              textAlign: "left",
                                              color: "#667085",
                                            }}
                                          >
                                            Declaration of compliance
                                          </span>
                                        </div>
                                      </div>
                                      {loadeddocument.includes(7) ? (
                                        <img src={check} />
                                      ) : businessDocument?.LLC?.CAC_FORM_4
                                          ?.status === "PENDING" ? (
                                        <img src={loadingbusiness} />
                                      ) : businessDocument?.LLC?.CAC_FORM_4
                                          ?.status === "APPROVED" ? (
                                        <img src={busiesssuccess} />
                                      ) : businessDocument?.LLC?.CAC_FORM_4
                                          ?.status === "REJECTED" ? (
                                        <img src={busiessscancel} />
                                      ) : (
                                        <span className="material-icons">
                                          chevron_right
                                        </span>
                                      )}
                                    </div>
                                    <div
                                      className="each-profile-input-kyc"
                                      style={{
                                        position: "relative",

                                        display: "flex",

                                        alignItems: "center",
                                        padding: "16px",
                                        background: "#fff",
                                        borderBottom: "1px solid #E7E9FB",
                                        justifyContent: "space-between",
                                      }}
                                      onClick={() => {
                                        //  alert("Hello");
                                        if (
                                          businessDocument?.LLC?.CAC_FORM_7
                                            ?.status !== "APPROVED"
                                        )
                                          setshowmodalbusiness(true);
                                        setfilename([]);
                                        setfilesize(0);
                                        settitle("CAC Form 7 (Optional)");
                                        setbusinesindex(8);
                                        setloadercc(false);
                                        setloading(false);
                                      }}
                                    >
                                      <div
                                        className="lg-mg-lo-pl"
                                        style={{
                                          display: "flex",
                                          gap: "8px",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img src={document} alt="" />
                                        <div
                                          className="lg-bm-fl-g"
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <h4
                                            style={{
                                              padding: "0px",
                                              margin: "0px",
                                              fontSize: "16px",
                                              fontWeight: "600",
                                              lineHeight: "24px",
                                              letterSpacing: "0.02em",
                                              textAlign: "left",
                                              color: "#101828",
                                            }}
                                          >
                                            CAC Form 7 (Optional)
                                          </h4>
                                          <span
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "400",
                                              lineHeight: "16px",
                                              letterSpacing: "0.02em",
                                              textAlign: "left",
                                              color: "#667085",
                                            }}
                                          >
                                            Particulars of directors
                                          </span>
                                        </div>
                                      </div>
                                      {loadeddocument.includes(8) ? (
                                        <img src={check} />
                                      ) : businessDocument?.LLC?.CAC_FORM_7
                                          ?.status === "PENDING" ? (
                                        <img src={loadingbusiness} />
                                      ) : businessDocument?.LLC?.CAC_FORM_7
                                          ?.status === "APPROVED" ? (
                                        <img src={busiesssuccess} />
                                      ) : businessDocument?.LLC?.CAC_FORM_7
                                          ?.status === "REJECTED" ? (
                                        <img src={busiessscancel} />
                                      ) : (
                                        <span className="material-icons">
                                          chevron_right
                                        </span>
                                      )}
                                    </div>
                                    <div
                                      className="each-profile-input-kyc"
                                      style={{
                                        position: "relative",

                                        display: "flex",

                                        alignItems: "center",
                                        padding: "16px",
                                        background: "#fff",
                                        borderBottom: "1px solid #E7E9FB",
                                        justifyContent: "space-between",
                                      }}
                                      onClick={() => {
                                        //  alert("Hello");
                                        if (
                                          businessDocument?.LLC
                                            ?.MEMORANDUM_AND_ARTICLE_OF_ASSOCIATION
                                            ?.status !== "APPROVED"
                                        )
                                          setshowmodalbusiness(true);
                                        setfilename([]);
                                        setfilesize(0);
                                        settitle(
                                          "Memorandum & articles of association"
                                        );
                                        setbusinesindex(9);
                                        setloadercc(false);
                                        setloading(false);
                                      }}
                                    >
                                      <div
                                        className="lg-mg-lo-pl"
                                        style={{
                                          display: "flex",
                                          gap: "8px",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img src={document} alt="" />
                                        <div
                                          className="lg-bm-fl-g"
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <h4
                                            style={{
                                              padding: "0px",
                                              margin: "0px",
                                              fontSize: "16px",
                                              fontWeight: "600",
                                              lineHeight: "24px",
                                              letterSpacing: "0.02em",
                                              textAlign: "left",
                                              color: "#101828",
                                            }}
                                          >
                                            Memorandum & articles of association
                                          </h4>
                                        </div>
                                      </div>
                                      {loadeddocument.includes(9) ? (
                                        <img src={check} />
                                      ) : businessDocument?.LLC
                                          ?.MEMORANDUM_AND_ARTICLE_OF_ASSOCIATION
                                          ?.status === "PENDING" ? (
                                        <img src={loadingbusiness} />
                                      ) : businessDocument?.LLC
                                          ?.MEMORANDUM_AND_ARTICLE_OF_ASSOCIATION
                                          ?.status === "APPROVED" ? (
                                        <img src={busiesssuccess} />
                                      ) : businessDocument?.LLC
                                          ?.MEMORANDUM_AND_ARTICLE_OF_ASSOCIATION
                                          ?.status === "REJECTED" ? (
                                        <img src={busiessscancel} />
                                      ) : (
                                        <span className="material-icons">
                                          chevron_right
                                        </span>
                                      )}
                                    </div>
                                    <div
                                      className="each-profile-input-kyc"
                                      style={{
                                        position: "relative",

                                        display: "flex",

                                        alignItems: "center",
                                        padding: "16px",
                                        background: "#fff",
                                        borderBottom: "1px solid #E7E9FB",
                                        justifyContent: "space-between",
                                      }}
                                      onClick={() => setShowModal(true)}
                                    >
                                      <div
                                        className="lg-mg-lo-pl"
                                        style={{
                                          display: "flex",
                                          gap: "8px",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img src={document} alt="" />
                                        <div
                                          className="lg-bm-fl-g"
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <h4
                                            style={{
                                              padding: "0px",
                                              margin: "0px",
                                              fontSize: "16px",
                                              fontWeight: "600",
                                              lineHeight: "24px",
                                              letterSpacing: "0.02em",
                                              textAlign: "left",
                                              color: "#101828",
                                            }}
                                          >
                                            BVN of the Directors of the Company
                                          </h4>
                                        </div>
                                      </div>
                                      {loadeddocument.includes(12) ? (
                                        <img src={check} />
                                      ) : businessDocument?.directors?.length >=
                                        1 ? (
                                        <img src={busiesssuccess} />
                                      ) : (
                                        <span className="material-icons">
                                          chevron_right
                                        </span>
                                      )}
                                    </div>
                                    <div
                                      className="each-profile-input-kyc"
                                      style={{
                                        position: "relative",

                                        display: "flex",

                                        alignItems: "center",
                                        padding: "16px",
                                        background: "#fff",
                                        // borderBottom: "1px solid #E7E9FB",
                                        justifyContent: "space-between",
                                      }}
                                      onClick={() => {
                                        //  alert("Hello");
                                        if (
                                          businessDocument?.LLC
                                            ?.BOARD_RESOLUTION?.status !==
                                          "APPROVED"
                                        )
                                          setshowmodalbusiness(true);
                                        setfilename([]);
                                        setfilesize(0);
                                        settitle("Board Resolution");
                                        setbusinesindex(10);
                                        setloadercc(false);
                                        setloading(false);
                                      }}
                                    >
                                      <div
                                        className="lg-mg-lo-pl"
                                        style={{
                                          display: "flex",
                                          gap: "8px",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img src={document} alt="" />
                                        <div
                                          className="lg-bm-fl-g"
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <h4
                                            style={{
                                              padding: "0px",
                                              margin: "0px",
                                              fontSize: "16px",
                                              fontWeight: "600",
                                              lineHeight: "24px",
                                              letterSpacing: "0.02em",
                                              textAlign: "left",
                                              color: "#101828",
                                            }}
                                          >
                                            Board resolution to open an account
                                            with Tradevu
                                          </h4>
                                        </div>
                                      </div>
                                      {loadeddocument.includes(10) ? (
                                        <img src={check} />
                                      ) : businessDocument?.LLC
                                          ?.BOARD_RESOLUTION?.status ===
                                        "PENDING" ? (
                                        <img src={loadingbusiness} />
                                      ) : businessDocument?.LLC
                                          ?.BOARD_RESOLUTION?.status ===
                                        "APPROVED" ? (
                                        <img src={busiesssuccess} />
                                      ) : businessDocument?.LLC
                                          ?.BOARD_RESOLUTION?.status ===
                                        "REJECTED" ? (
                                        <img src={busiessscancel} />
                                      ) : (
                                        <span className="material-icons">
                                          chevron_right
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                           */}

                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                gap: "8px",
                                                                marginTop: "30px",
                                                            }}
                                                        >
                                                            <label
                                                                htmlFor=""
                                                                style={{
                                                                    color: "#101828",
                                                                    fontWeight: "600",
                                                                    fontSize: "14px",
                                                                    lineHeight: "19.6px",
                                                                }}
                                                            >
                                                                Business Account
                                                            </label>
                                                            <div
                                                                style={{
                                                                    border: "1px solid #E7E9FB",
                                                                    padding: "16px",
                                                                    display: "flex",

                                                                    borderRadius: "8px",
                                                                    alignItems: "center",
                                                                    justifyContent: "space-between",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() =>
                                                                    setshowrequestStatement(true)
                                                                }
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        gap: "8px",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <img src={imageDelete} alt="" />
                                                                    <span
                                                                        style={{
                                                                            color: "#F04438",
                                                                            fontWeight: "600",
                                                                            fontSize: "16px",
                                                                            lineHeight: "24px",
                                                                        }}
                                                                    >
                                                                        Delete Business Account
                                                                    </span>
                                                                </div>
                                                                <span
                                                                    className="material-icons"
                                                                    style={{
                                                                        marginTop: "10px",
                                                                        fontSize: "16px",
                                                                        color: "#667085",
                                                                    }}
                                                                >
                                                                    chevron_right
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {profiletab === 2 && (
                                        <div className="right-side-account-details">
                                            <div className="miid-side-details">
                                                <div
                                                    className="top-details-lg-mgph"
                                                    style={{
                                                        padding: "32px",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "24px",
                                                    }}
                                                >
                                                    <div
                                                        className="top-level-beneficiary-log"
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: "24px",
                                                        }}
                                                    >
                                                        <div
                                                            className="h3-level-span"
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                gap: "8px",
                                                            }}
                                                        >
                                                            <h4
                                                                style={{
                                                                    fontSize: "18px",
                                                                    fontWeight: "800",
                                                                    lineHeight: "24px",
                                                                    letterSpacing: "2%",
                                                                    textAlign: "left",
                                                                    color: "#101828",
                                                                    padding: "0px",
                                                                    margin: "0px",
                                                                }}
                                                            >
                                                                Saved Beneficiaries
                                                            </h4>

                                                            <span
                                                                style={{
                                                                    fontSize: "14px",
                                                                    fontWeight: "400",
                                                                    lineHeight: "20px",
                                                                    letterSpacing: "2%",
                                                                    textAlign: "left",
                                                                    color: "#667085",
                                                                }}
                                                            >
                                                                Manage your payment beneficiaries
                                                                here
                                                            </span>
                                                        </div>
                                                        <div
                                                            className="input-search-beneficiary-lg"
                                                            style={{
                                                                height: "48px",
                                                                borderRadius: "8px",
                                                                backgroundColor: "#ECEFF3",
                                                                padding: "0px 8px",
                                                                display: "flex",
                                                                gap: "8px",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <img src={search} alt="" />
                                                            <input
                                                                type="search"
                                                                className="input-search-beneficiary"
                                                                placeholder="Search by name or account"
                                                                onChange={(e) =>
                                                                    setsearchBen(e.target.value)
                                                                }
                                                                style={{
                                                                    fontSize: "14px",
                                                                    fontWeight: "500",
                                                                    lineHeight: "20px",
                                                                    letterSpacing: "2%",
                                                                    textAlign: "left",
                                                                    color: "#667085",
                                                                    outline: "none",
                                                                    border: "none",
                                                                    backgroundColor: "inherit",
                                                                    outline: "none",
                                                                    border: "none",
                                                                    width: "100%",
                                                                }}
                                                            />
                                                        </div>
                                                        {resultben.length > 0 &&
                                                            benloader === false && (
                                                                <>
                                                                    <div className="each-value-buyer">
                                                                        {resultben.map(
                                                                            (res, index) => (
                                                                                <div
                                                                                    className="each-buyer-icon-lg"
                                                                                    key={index}
                                                                                >
                                                                                    <label htmlFor="">
                                                                                        {
                                                                                            res.alphabet
                                                                                        }
                                                                                    </label>
                                                                                    {res.record.map(
                                                                                        (
                                                                                            ress,
                                                                                            index
                                                                                        ) => (
                                                                                            <div
                                                                                                className="main-each-lg"
                                                                                                key={
                                                                                                    index
                                                                                                }
                                                                                                style={{
                                                                                                    background:
                                                                                                        "#FFFFFF",
                                                                                                }}
                                                                                            >
                                                                                                <div className="lg-sie-avater-lg">
                                                                                                    <div className="intial">
                                                                                                        {ress.name?.slice(
                                                                                                            0,
                                                                                                            2
                                                                                                        )}
                                                                                                    </div>

                                                                                                    <div className="top-tier-lg">
                                                                                                        <h4
                                                                                                            style={{
                                                                                                                fontSize:
                                                                                                                    "15px",
                                                                                                                fontWeight:
                                                                                                                    "500",
                                                                                                                lineHeight:
                                                                                                                    "19.6px",
                                                                                                                letterSpacing:
                                                                                                                    "2%",
                                                                                                            }}
                                                                                                        >
                                                                                                            {
                                                                                                                ress.name
                                                                                                            }
                                                                                                        </h4>
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color: "#667085",
                                                                                                                fontSize:
                                                                                                                    "14px",
                                                                                                                lineHeight:
                                                                                                                    "19.6px",
                                                                                                                fontWeight:
                                                                                                                    "400",
                                                                                                            }}
                                                                                                        >
                                                                                                            {
                                                                                                                ress.bankName
                                                                                                            }
                                                                                                            {
                                                                                                                ""
                                                                                                            }{" "}
                                                                                                            <img
                                                                                                                src={
                                                                                                                    dot
                                                                                                                }
                                                                                                                alt=""
                                                                                                            />{" "}
                                                                                                            {
                                                                                                                ress.accountNumber
                                                                                                            }
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <span
                                                                                                    className="material-icons"
                                                                                                    style={{
                                                                                                        marginTop:
                                                                                                            "10px",
                                                                                                        fontSize:
                                                                                                            "16px",
                                                                                                        color: "#667085",
                                                                                                    }}
                                                                                                >
                                                                                                    chevron_right
                                                                                                </span>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                </>
                                                            )}
                                                        {resultben.length === 0 &&
                                                            benloader === false && (
                                                                <>
                                                                    <div
                                                                        className="no-flex-c"
                                                                        style={{
                                                                            height: "460px",
                                                                            overflow: "auto",
                                                                            padding:
                                                                                "16px 24px 24px 24px",
                                                                            borderRadius: "16px",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent:
                                                                                "center",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                width: "100%",
                                                                                alignItems:
                                                                                    "center",
                                                                                display: "flex",
                                                                                flexDirection:
                                                                                    "column",
                                                                                justifyContent:
                                                                                    "center",
                                                                                gap: "24px",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={invoice}
                                                                                alt=""
                                                                            />
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    flexDirection:
                                                                                        "column",
                                                                                    gap: "8px",
                                                                                }}
                                                                            >
                                                                                <h3
                                                                                    style={{
                                                                                        fontWeight:
                                                                                            "700",
                                                                                        color: "#101828",
                                                                                        fontSize:
                                                                                            "18px",
                                                                                        lineHeight:
                                                                                            "24.3px",
                                                                                        textAlign:
                                                                                            "center",
                                                                                    }}
                                                                                >
                                                                                    You don’t have
                                                                                    any beneficiary
                                                                                    here
                                                                                </h3>
                                                                                <span
                                                                                    style={{
                                                                                        color: "#667085",
                                                                                        fontSize:
                                                                                            "14px",
                                                                                        lineHeight:
                                                                                            "19.6px",
                                                                                        fontWeight:
                                                                                            "400",
                                                                                        textAlign:
                                                                                            "center",
                                                                                        maxWidth:
                                                                                            "229px",
                                                                                    }}
                                                                                >
                                                                                    Add beneficiary
                                                                                    you trade with
                                                                                    to manage their
                                                                                    orders from
                                                                                    here.
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        {benloader && <Loading />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {isActivities && <Security />}
                            {isTermination && (
                                <div
                                    className="card-body p-0"
                                    style={{
                                        borderRadius: "24px 24px 0px 0px",

                                        minHeight: "977px",
                                        display: "flex",
                                        overflowY: "auto",
                                        width: "100%",
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        className="left-col-static-nav"
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            className="interrior-lg-sm"
                                            style={{
                                                boxSizing: "border-box",
                                                width: "100%",
                                                minWidth: "316px",
                                            }}
                                        >
                                            {terminations.map((toggle, index) => (
                                                <div
                                                    className="each-scale-ratio"
                                                    key={index}
                                                    style={{
                                                        border: "0.5px solid #6F00FF",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        Logout(index);
                                                        setselectedtermination(index);
                                                    }}
                                                >
                                                    <div
                                                        className="inner-lg-mf"
                                                        style={{
                                                            background: "#F4F0FF",
                                                        }}
                                                    >
                                                        <div className="img-lg-text">
                                                            <img
                                                                src={
                                                                    index === 0
                                                                        ? closeaccount
                                                                        : logout
                                                                }
                                                                alt=""
                                                            />
                                                            <span>{toggle}</span>
                                                        </div>
                                                        {selectedtermination === index && (
                                                            <img src={checkbox} alt="" />
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div
                                        className="right-side-account-details"
                                        style={{
                                            padding: "32px",
                                        }}
                                    >
                                        <div
                                            className="miid-side-details"
                                            style={{
                                                margin: "0px",
                                                maxWidth: "100%",
                                            }}
                                        >
                                            {selectedtermination === 0 && (
                                                <div
                                                    className="top-details-lg-mgph-close"
                                                    style={{}}
                                                >
                                                    <div className="md-text-lg-text">
                                                        <h2>Tradevu API</h2>
                                                        <span>
                                                            You’ll need an API key to send requests
                                                            via Tradevu API
                                                        </span>
                                                    </div>

                                                    <button
                                                        type="button"
                                                        style={{
                                                            background: "#6F00FF",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                        className="closebtn"
                                                        onClick={() => {
                                                            // setshowallet(true);
                                                            generateApiKey();
                                                        }}
                                                    >
                                                        {loading ? <Loader /> : "Generate New Key"}
                                                    </button>
                                                    {selector?.apiKey !== undefined ||
                                                    api !== undefined ? (
                                                        <div
                                                            style={{
                                                                overflow: "auto",
                                                                background: "#fff",
                                                                borderRadius: "8px",
                                                            }}
                                                        >
                                                            <table className="table  row-datatable">
                                                                <thead className="table-head">
                                                                    <tr>
                                                                        <th
                                                                            onClick={() =>
                                                                                sorting(
                                                                                    "user['firstName']",
                                                                                    0
                                                                                )
                                                                            }
                                                                        >
                                                                            Description
                                                                            <div className="sort_icon-con">
                                                                                <span
                                                                                    className="material-icons up-arrwo"
                                                                                    style={{
                                                                                        color:
                                                                                            order ===
                                                                                                "ASC" &&
                                                                                            selectedIndex ===
                                                                                                0
                                                                                                ? "#873BFF"
                                                                                                : "#667085",
                                                                                    }}
                                                                                >
                                                                                    arrow_drop_up
                                                                                </span>

                                                                                <span
                                                                                    className="material-icons down-arrow"
                                                                                    style={{
                                                                                        color:
                                                                                            order ===
                                                                                                "DSC" &&
                                                                                            selectedIndex ===
                                                                                                0
                                                                                                ? "#873BFF"
                                                                                                : "#667085",
                                                                                    }}
                                                                                >
                                                                                    arrow_drop_down
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th
                                                                            onClick={() =>
                                                                                sorting("email", 1)
                                                                            }
                                                                        >
                                                                            API Key{" "}
                                                                            <div className="sort_icon-con">
                                                                                <span
                                                                                    className="material-icons up-arrwo"
                                                                                    style={{
                                                                                        color:
                                                                                            order ===
                                                                                                "ASC" &&
                                                                                            selectedIndex ===
                                                                                                1
                                                                                                ? "#873BFF"
                                                                                                : "#667085",
                                                                                    }}
                                                                                >
                                                                                    arrow_drop_up
                                                                                </span>

                                                                                <span
                                                                                    className="material-icons down-arrow"
                                                                                    style={{
                                                                                        color:
                                                                                            order ===
                                                                                                "DSC" &&
                                                                                            selectedIndex ===
                                                                                                1
                                                                                                ? "#873BFF"
                                                                                                : "#667085",
                                                                                    }}
                                                                                >
                                                                                    arrow_drop_down
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th
                                                                            onClick={() =>
                                                                                sorting(
                                                                                    "phoneNumber",
                                                                                    2
                                                                                )
                                                                            }
                                                                        >
                                                                            Created{" "}
                                                                            <div className="sort_icon-con">
                                                                                <span
                                                                                    className="material-icons up-arrwo"
                                                                                    style={{
                                                                                        color:
                                                                                            order ===
                                                                                                "ASC" &&
                                                                                            selectedIndex ===
                                                                                                2
                                                                                                ? "#873BFF"
                                                                                                : "#667085",
                                                                                    }}
                                                                                >
                                                                                    arrow_drop_up
                                                                                </span>

                                                                                <span
                                                                                    className="material-icons down-arrow"
                                                                                    style={{
                                                                                        color:
                                                                                            order ===
                                                                                                "DSC" &&
                                                                                            selectedIndex ===
                                                                                                2
                                                                                                ? "#873BFF"
                                                                                                : "#667085",
                                                                                    }}
                                                                                >
                                                                                    arrow_drop_down
                                                                                </span>
                                                                            </div>
                                                                        </th>

                                                                        <th
                                                                            style={{
                                                                                opacity: "0",
                                                                                visibility: "0",
                                                                            }}
                                                                        >
                                                                            Status{" "}
                                                                            <div className="sort_icon-con">
                                                                                <span
                                                                                    className="material-icons up-arrwo"
                                                                                    style={{
                                                                                        color:
                                                                                            order ===
                                                                                            "ASC"
                                                                                                ? "#873BFF"
                                                                                                : "#667085",
                                                                                    }}
                                                                                >
                                                                                    arrow_drop_up
                                                                                </span>

                                                                                <span
                                                                                    className="material-icons down-arrow"
                                                                                    style={{
                                                                                        color:
                                                                                            order ===
                                                                                            "DSC"
                                                                                                ? "#873BFF"
                                                                                                : "#667085",
                                                                                    }}
                                                                                >
                                                                                    arrow_drop_down
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody
                                                                    className="table-head"
                                                                    style={{ width: "100%" }}
                                                                >
                                                                    <tr
                                                                        className="each_row"
                                                                        onClick={() => {
                                                                            setshowallet(true);
                                                                        }}
                                                                        // onClick={() => goToDetails(user, "Credit Line")}
                                                                    >
                                                                        <td
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems:
                                                                                    "center",
                                                                                gap: "8px",
                                                                                border: "none",
                                                                                color: "#344054",
                                                                                fontSize: "12px",
                                                                                fontWeight: "500",
                                                                                gap: "16px",
                                                                                border: "none",
                                                                            }}
                                                                        >
                                                                            API Token
                                                                        </td>

                                                                        <td
                                                                            style={{
                                                                                color: "#344054",
                                                                                display: "flex",
                                                                                alignItems:
                                                                                    "center",
                                                                                fontSize: "12px",
                                                                                fontWeight: "500",
                                                                                gap: "16px",
                                                                                border: "none",
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {api}
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    gap: "4px",
                                                                                    alignItems:
                                                                                        "center",
                                                                                    padding:
                                                                                        "4px 8px",
                                                                                    borderRadius:
                                                                                        "6px",
                                                                                    background:
                                                                                        "#F4F0FF",
                                                                                    fontSize:
                                                                                        "12px",
                                                                                    fontWeight:
                                                                                        "600",
                                                                                    lineHeight:
                                                                                        "16.2px",
                                                                                    color: "#6F00FF",
                                                                                }}
                                                                                onClick={() =>
                                                                                    copyURL1(
                                                                                        api,
                                                                                        "API Token"
                                                                                    )
                                                                                }
                                                                            >
                                                                                <img
                                                                                    src={copy}
                                                                                    alt=""
                                                                                />
                                                                                Copy
                                                                            </div>
                                                                        </td>

                                                                        <td
                                                                            style={{
                                                                                border: "none",
                                                                                color: "#344054",
                                                                                display: "flex",
                                                                                alignItems:
                                                                                    "center",
                                                                                fontSize: "12px",
                                                                                fontWeight: "500",
                                                                                gap: "16px",
                                                                                border: "none",
                                                                            }}
                                                                        >
                                                                            {new Date(
                                                                                selector.updatedAt
                                                                            ).toDateString()}
                                                                        </td>

                                                                        <td
                                                                            className="view_account"
                                                                            style={{
                                                                                border: "none",
                                                                                color: "#6F00FF",
                                                                                fontSize: "14px",
                                                                                fontWeight: "600",
                                                                            }}
                                                                        >
                                                                            View{" "}
                                                                            <span
                                                                                style={{
                                                                                    fontSize:
                                                                                        "14px",
                                                                                    fontWeight:
                                                                                        "600",
                                                                                }}
                                                                                className="material-icons"
                                                                            >
                                                                                chevron_right
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>

                                                                {/***
                  {currentTableDataFinance.length === 0 && (
                    <div className="empty_lg">
                      <img src={empty} alt="" />
                      <div className="bg_lg_dm">
                        <h4>No Finance Transaction</h4>
                        <span>Finance will show here.</span>
                      </div>
                    </div>
                  )}
                   */}
                                                            </table>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <div
                                                        className="top-details-lg-mgph-close"
                                                        style={{}}
                                                    >
                                                        <div className="md-text-lg-text">
                                                            <h2>Webhook</h2>
                                                            <span>
                                                                You’ll need to set Webhook to
                                                                receive response via Tradevu API
                                                            </span>
                                                        </div>
                                                        <form
                                                            action=""
                                                            onSubmit={generateWebhook}
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <div
                                                                className="each-profile-input"
                                                                style={{
                                                                    maxWidth: "300px",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <label htmlFor="">
                                                                    Webhook Url
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    value={url}
                                                                    onChange={(e) =>
                                                                        seturl(e.target.value)
                                                                    }
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                    placeholder="Enter Url"
                                                                />
                                                            </div>

                                                            <button
                                                                type="submit"
                                                                style={{
                                                                    background: "#6F00FF",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    marginTop: "30px",
                                                                }}
                                                                className="closebtn"
                                                            >
                                                                {loading2 ? (
                                                                    <Loader />
                                                                ) : (
                                                                    "Set Webhook"
                                                                )}
                                                            </button>
                                                        </form>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {isDashboard && <Help />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountHome;
