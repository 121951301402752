import checkbox from "../asset/images/account/checkbox.png";
import Loading from "../component/loading";
import Loader from "../component/loader";
import { useEffect, useRef, useState } from "react";
import personal from "../asset/images/changepin.svg";
import { useSelector } from "react-redux";
import business from "../asset/images/resetpassword.svg";
import beneficiary from "../asset/images/Notification-alert.svg";
import SuccessHodler from "../component/successholder";
//import Loader from "../component/loader";
import FailHandler from "../component/failhandler";
import balance from "../asset/images/requeststatmement.svg";
import ConfrimPin from "./confirmModal";
import axios from "axios";
import UpdateStatus from "./updatestatusdetails";

const Security = () => {
  const [profiletab, setprofiletab] = useState(0);
  const toggledetail = [
      "Change PIN",
      "Reset password",
      "Notifications",
      // "Request statement",
  ];
  const [selectedtoggle, setselectedtoggle] = useState(0);
  const [message, setmessage] = useState("");
  const [password, setpassword] = useState("");
  const [currentpassword, setcurrentpassword] = useState("");

  const selector = useSelector((state) => state.users);
  const [pins, setPins] = useState(["", "", "", ""]);
  const [newpin, setNewPins] = useState("");

  const [loaderupdate, setloaderupdate] = useState(false);
  const [failhandler, setfailhandler] = useState(false);
  const selectorbusiness = useSelector((state) => state.business);
  const [successholder, setsuccessholder] = useState(false);
  const [show, setShow] = useState(false);
  const [loader, setloader] = useState(false);
  const [isPushednotificationupdate, setisPushednotificationupdate] = useState(false);
  const [isEmailupdate, setisEmailupdate] = useState(false);

  const inputs = useRef([]);

  useEffect(() => {
      inputs.current[0]?.focus();
  }, []);
  useEffect(() => {
      if (successholder || failhandler) {
          let timer = setTimeout(() => {
              setfailhandler(false);
              setsuccessholder(false);
          }, 3000);
          return () => {
              clearTimeout(timer);
          };
      }
  });

  const handleInputChange = (event, index) => {
      const currentInput = event.target.value.trim();

      if (/^\d$/.test(currentInput)) {
          const newPins = [...pins];
          newPins[index] = currentInput;

          setPins(newPins);

          if (index < inputs.current.length - 1 && currentInput) {
              inputs.current[index + 1].focus();
          }
      }
  };

  const handleInputBackspace = (event, index) => {
      const currentInput = event.target.value.trim();
      console.log(currentInput);

      if (event.key === "Backspace" && !currentInput && index > 0) {
          const newPins = [...pins];
          newPins[index - 1] = "";

          setPins(newPins);
          inputs.current[index - 1].focus();
      }
  };
  const changePassword = async (e) => {
      e.preventDefault();

      setloader(true);
      const data = {
          password: password,
          newPassword: currentpassword,
      };
      axios
          .put("/v1/users/update-password", data)
          .then((res) => {
              setmessage(res.data.message);
              setsuccessholder(true);
              setloader(false);
              setpassword("");
              setcurrentpassword("");
          })
          .catch((e) => {
              setmessage(e.response?.data?.message ? e.response?.data?.message : "An error Ocuur");
              setsuccessholder(false);
              setfailhandler(true);
              setloader(false);
          });
  };
  const changeUpdate = async (e) => {
      // e.preventDefault();

      setloader(true);
      const data = {
          emailUpdate: isEmailupdate.toString(),
          pushNotification: isPushednotificationupdate.toString(),
      };
      axios
          .put("/v1/users/update-notification", data)
          .then((res) => {
              setmessage(res.data.message);
              setsuccessholder(true);
              setloader(false);
              setpassword("");
              setcurrentpassword("");
          })
          .catch((e) => {
              setmessage(
                  e.response?.data?.message ? e.response?.data?.message : "An error Ocurred"
              );
              setsuccessholder(false);
              setfailhandler(true);
              setloader(false);
          });
  };

  const sendRequest = async (e) => {
      e.preventDefault();
      setShow(false);
      setloader(true);
      // const [pin1, pin2, pin3, pin4] = pin;
      //   const numericPin = pin1 + "" + pin2 + "" + pin3 + "" + pin4;
      //const pinsdata = {
      //pin: numericPin,
      //};
      const [newpin1, newpin2, newpin3, newpin4] = pins;
      const allnewpin = newpin1 + "" + newpin2 + "" + newpin3 + "" + newpin4;
      const newpin = {
          pin: allnewpin,
          password: password,
      };

      await axios
          .post("/v1/users/reset-pin", newpin)
          .then((res) => {
              setmessage(res.data.message);
              setsuccessholder(true);
              setloader(false);
              setPins(["", "", "", ""]);
              setpassword("");
          })
          .catch((e) => {
              setmessage(
                  e.response?.data?.message ? e.response?.data?.message : "An error Ocurred"
              );
              setsuccessholder(false);
              setfailhandler(true);
              setloader(false);
          })

          .catch((e) => {
              setmessage(e.response?.data?.message ? e.response?.data?.message : "An error Ocuur");
              setsuccessholder(false);
              setfailhandler(true);
              setloader(false);
          });
  };
  return (
      <>
          <ConfrimPin
              pincode={pins}
              show={show}
              setshow={setShow}
              setPins={setNewPins}
              sendRequest={sendRequest}
          />
          <div
              className="p-0 card-body"
              style={{
                  borderRadius: "24px 24px 0px 0px",

                  minHeight: "977px",
                  display: "flex",
                  overflowY: "auto",
              }}
          >
              <div className="left-col-static-nav">
                  <div className="interrior-lg-sm">
                      {toggledetail.map((toggle, index) => (
                          <div
                              className="each-scale-ratio"
                              key={index}
                              style={{
                                  border: selectedtoggle === index && "0.5px solid #6F00FF",
                                  cursor: "pointer",
                              }}
                              onClick={() => {
                                  setselectedtoggle(index);

                                  setprofiletab(index);
                              }}
                          >
                              <div
                                  className="inner-lg-mf"
                                  style={{
                                      background: selectedtoggle === index && "#F4F0FF",
                                  }}
                              >
                                  <div className="img-lg-text">
                                      <img
                                          src={
                                              index === 0
                                                  ? personal
                                                  : index === 1
                                                  ? business
                                                  : index === 2
                                                  ? beneficiary
                                                  : balance
                                          }
                                          alt=""
                                      />
                                      <span>{toggle}</span>
                                  </div>
                                  {selectedtoggle === index && <img src={checkbox} alt="" />}
                              </div>
                          </div>
                      ))}
                  </div>
              </div>
              {profiletab === 0 && (
                  <form className="right-side-account-details" onSubmit={sendRequest}>
                      <div className="miid-side-details">
                          <SuccessHodler success={successholder} message={message} />
                          <FailHandler success={failhandler} message={message} />

                          <div
                              className="top-details-lg-mgph"
                              style={{
                                  gap: "32px",
                              }}
                          >
                              <div className="lower_layer_layout">
                                  <h2
                                      style={{
                                          padding: "0px",
                                          margin: "0px",
                                      }}
                                  >
                                      Change PIN
                                  </h2>
                                  <span>Please, enter your PIN</span>
                              </div>
                              <div
                                  style={{
                                      display: "flex",
                                      gap: "16px",
                                      maxWidth: "300px",
                                      width: "100%",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                  }}
                              >
                                  {pins.map((pin, index) => (
                                      <input
                                          key={index}
                                          type="tel"
                                          className="!shadow-none"
                                          required
                                          style={{
                                              background: "#ECEFF3",
                                              color: "#667085",
                                              padding: "8px 16px 8px 16px",
                                              textAlign: "center",
                                              height: "53px",
                                              minWidth: "50px",
                                              border: "none",
                                              outline: "none",
                                              borderRadius: "8px",
                                          }}
                                          placeholder="-"
                                          maxLength="1"
                                          value={pin}
                                          ref={(element) => (inputs.current[index] = element)}
                                          onChange={(event) => handleInputChange(event, index)}
                                          onKeyDown={(event) => handleInputBackspace(event, index)}
                                      />
                                  ))}
                              </div>
                              <div
                                  className="each-profile-input"
                                  style={{
                                      maxWidth: "300px",
                                      width: "100%",
                                  }}
                              >
                                  <label htmlFor="">Password</label>
                                  <input
                                      type="password"
                                      value={password}
                                      required
                                      onChange={(e) => setpassword(e.target.value)}
                                      style={{
                                          width: "100%",
                                      }}
                                      placeholder="Enter password"
                                      className="!shadow-none"
                                  />
                              </div>

                              <button className="confirmButtonPin" type="subnit">
                                  {loader ? <Loader /> : "Confirm"}
                              </button>
                          </div>
                      </div>
                  </form>
              )}
              {profiletab === 1 && (
                  <form className="right-side-account-details" onSubmit={changePassword}>
                      <div className="miid-side-details">
                          <SuccessHodler success={successholder} message={message} />
                          <FailHandler success={failhandler} message={message} />

                          <div
                              className="top-details-lg-mgph"
                              style={{
                                  gap: "32px",
                              }}
                          >
                              <div className="lower_layer_layout">
                                  <h2
                                      style={{
                                          padding: "0px",
                                          margin: "0px",
                                      }}
                                  >
                                      Change password
                                  </h2>
                                  <span>Enter a new password below</span>
                              </div>

                              <div
                                  className="each-profile-input"
                                  style={{
                                      maxWidth: "50%",
                                      width: "100%",
                                  }}
                              >
                                  <label htmlFor="">Current Password</label>
                                  <input
                                      type="password"
                                      value={currentpassword}
                                      onChange={(e) => setcurrentpassword(e.target.value)}
                                      style={{
                                          width: "100%",
                                      }}
                                      placeholder="Enter password"
                                      className="!shadow-none"
                                  />
                              </div>
                              <div
                                  className="each-profile-input"
                                  style={{
                                      maxWidth: "50%",
                                      width: "100%",
                                  }}
                              >
                                  <label htmlFor="">Password</label>
                                  <input
                                      type="password"
                                      value={password}
                                      onChange={(e) => setpassword(e.target.value)}
                                      style={{
                                          width: "100%",
                                      }}
                                      placeholder="Enter new password"
                                      className="!shadow-none"
                                  />
                              </div>

                              <button className="confirmButtonPin">
                                  {loader ? <Loader /> : "Change Password"}
                              </button>
                          </div>
                      </div>
                  </form>
              )}
              {profiletab === 2 && (
                  <form className="right-side-account-details" onSubmit={changePassword}>
                      <div className="miid-side-details">
                          <SuccessHodler success={successholder} message={message} />
                          <FailHandler success={failhandler} message={message} />

                          <div
                              className="top-details-lg-mgph"
                              style={{
                                  gap: "32px",
                              }}
                          >
                              <div className="lower_layer_layout">
                                  <h2
                                      style={{
                                          padding: "0px",
                                          margin: "0px",
                                      }}
                                  >
                                      Notifications
                                  </h2>
                                  <span>Edit your notification preferences</span>
                              </div>

                              <div className="each_notify_col">
                                  <div className="main-each-layout">
                                      <span>Push notifications</span>
                                      <label class="switch">
                                          <input
                                              type="checkbox"
                                              checked={isPushednotificationupdate}
                                              onChange={() => {
                                                  changeUpdate();
                                                  setisPushednotificationupdate(
                                                      !isPushednotificationupdate
                                                  );
                                              }}
                                          />
                                          <span class="slider round"></span>
                                      </label>
                                  </div>
                                  <div className="main-each-layout">
                                      <span>Email updates</span>
                                      <label class="switch">
                                          <input
                                              type="checkbox"
                                              checked={isEmailupdate}
                                              onChange={() => {
                                                  changeUpdate();
                                                  setisEmailupdate(!isEmailupdate);
                                              }}
                                          />
                                          <span class="slider round"></span>
                                      </label>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </form>
              )}
              {profiletab === 3 && (
                  <form className="right-side-account-details" onSubmit={changePassword}>
                      <div className="miid-side-details">
                          <SuccessHodler success={successholder} message={message} />
                          <FailHandler success={failhandler} message={message} />

                          <div
                              className="top-details-lg-mgph"
                              style={{
                                  gap: "32px",
                              }}
                          >
                              <div className="lower_layer_layout">
                                  <h2
                                      style={{
                                          padding: "0px",
                                          margin: "0px",
                                      }}
                                  >
                                      Request statement
                                  </h2>
                                  <span>Request a bank statement for any of your wallets</span>
                              </div>

                              <div className="form_statement_lg">
                                  <div className="form_lg_statementlg">
                                      <div className="each-lg-form-doubled">
                                          <div
                                              className="each-profile-input"
                                              style={{
                                                  width: "100%",
                                              }}
                                          >
                                              <label htmlFor="">Issued</label>
                                              <input
                                                  type="date"
                                                  value={password}
                                                  onChange={(e) => setpassword(e.target.value)}
                                                  style={{
                                                      width: "100%",
                                                  }}
                                                  placeholder="Enter password"
                                              />
                                          </div>
                                          <div
                                              className="each-profile-input"
                                              style={{
                                                  width: "100%",
                                              }}
                                          >
                                              <label htmlFor="">Due</label>
                                              <input
                                                  type="Date"
                                                  value={password}
                                                  onChange={(e) => setpassword(e.target.value)}
                                                  style={{
                                                      width: "100%",
                                                  }}
                                                  placeholder="Enter password"
                                              />
                                          </div>
                                      </div>
                                      <div
                                          className="each-profile-input"
                                          style={{
                                              width: "100%",
                                          }}
                                      >
                                          <label htmlFor="">Wallet</label>
                                          <select type="text" placeholder="Choose wallet"></select>
                                      </div>
                                      <div
                                          className="each-profile-input"
                                          style={{
                                              width: "100%",
                                          }}
                                      >
                                          <label htmlFor="">Email</label>
                                          <input
                                              disabled
                                              type="email"
                                              value={selector.email}
                                              placeholder="Enter email"
                                          />
                                      </div>
                                  </div>
                                  <button className="confirmButtonPin">
                                      {loader ? <Loader /> : "Send Request"}
                                  </button>
                              </div>
                          </div>
                      </div>
                  </form>
              )}
          </div>
      </>
  );
};

export default Security;
