import React from "react";

const AuthNav = ({ logo, altText = "Logo", logoMargin = "ml-8", height = "h-[76px]" }) => {
    return (
        <header className={`w-full ${height}`}>
            <nav className="flex items-center">
                <img src={logo} alt={altText} className={`${logoMargin}`} />
            </nav>
        </header>
    );
};

export default AuthNav;
