import LoginHeader from "../../../component/loginheader";
import "../../../style/index.css";
import ArrowBack from "../../../component/arrowback";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import document from "../../../asset/images/document.png";
import { useEffect, useState } from "react";
import DirectorModalBvn from "../../../component/directorbvn";
import axios from "axios";
import marksuccess from "../../../asset/images/marksuccess.svg";
import Loader from "../../../component/loader";
import FailHandler from "../../../component/failhandler";
import { Circles } from "react-loader-spinner";
import Congratulations from "../../../component/congratulation";
import { handleRequestVerification, handleUpdateBusiness, handleUploadFile } from "api/fetchers";

const BusinessDocumentsLLC = () => {
    const navigate = useNavigate();
    const MAX_FILE_SIZE = 5 * 1024 * 1024;
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [fails, setfails] = useState("");

    const [fileUploadedOne, setFileUpladedOne] = useState(false);
    const [fileUploadedTwo, setFileUpladedTwo] = useState(false);
    const [fileUploadedThree, setFileUploadedThree] = useState(false);
    const [fileUploadedFour, setFileUploadedFour] = useState(false);
    const [fileUploadedFive, setFileUploadedFive] = useState(false);
    const [fileUploadedSix, setFileUploadedSix] = useState(false);
    const [fileUploadedEight, setFileUploadedEight] = useState(false);
    const [fileUploadedBvn, setfileUploadedBvn] = useState(false);
    const [director, setDirector] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [loader, setloader] = useState(false);
    const [loader1, setloader1] = useState(false);
    const [loader2, setloader2] = useState(false);
    const [loader3, setloader3] = useState(false);
    const [loader4, setloader4] = useState(false);
    const [loader5, setloader5] = useState(false);
    const [loader6, setloader6] = useState(false);
    const [loader8, setloader8] = useState(false);
    const [showcongrats, setshowcongrats] = useState(false);
    const [isUploaded1, setisUploaded1] = useState(false);
    const [isUploaded2, setisUploaded2] = useState(false);
    const [isUploaded3, setisUploaded3] = useState(false);

    const { state } = useLocation();
    const formData = new FormData();
    useEffect(() => {
        if (fails) {
            let timerr = setTimeout(() => {
                setfails(false);
            }, 3000);

            return () => {
                clearTimeout(timerr);
            };
        }
    });

    const showModalHandle = () => {
        setShowModal(true);
    };

    const handleFileUpload = async (
        e,
        fileKey,
        loaderSetter,
        uploadedSetter,
        setUpload = false
    ) => {
        const file = e.target.files[0];

        // Validate the file
        if (!file) {
            setErrorMessage("No file selected. Please choose a file to upload.");
            setfails(true);
            uploadedSetter(false);
            if (setUpload) setUpload(false);
            return;
        }

        if (file.size > MAX_FILE_SIZE) {
            setErrorMessage("File size exceeds the limit of 5MB.");
            setfails(true);
            uploadedSetter(false);
            if (setUpload) setUpload(false);
            return;
        }

        loaderSetter(true);
        setErrorMessage("");
        setfails(false);
        uploadedSetter(false);
        if (setUpload) setUpload(false);

        try {
            const response = await handleUploadFile(
                state.businessId,
                state.businessType,
                fileKey,
                file
            );

            console.log(response);
            uploadedSetter(true);
            if (setUpload) setUpload(true);
        } catch (error) {
            setErrorMessage(error.message || "An error occurred while uploading the file.");
            setfails(true);
            uploadedSetter(false);
            if (setUpload) setUpload(false);
        } finally {
            loaderSetter(false);
        }
    };

    const handleFileOne = (e) => handleFileUpload(e, "CAC_FORM_2", setloader1, setFileUpladedOne);

    const handleFileTwo = (e) => handleFileUpload(e, "CAC_FORM_2_1", setloader2, setFileUpladedTwo);

    const handleFileThree = (e) =>
        handleFileUpload(e, "CAC_FORM_3", setloader3, setFileUploadedThree);

    const handleFileFour = (e) =>
        handleFileUpload(e, "CAC_FORM_4", setloader4, setFileUploadedFour);

    const handleFileFive = (e) =>
        handleFileUpload(e, "CAC_FORM_7", setloader5, setFileUploadedFive);

    const handleFileSix = (e) =>
        handleFileUpload(
            e,
            "MEMORANDUM_AND_ARTICLE_OF_ASSOCIATION",
            setloader6,
            setFileUploadedSix,
            setisUploaded1
        );

    const handleFileEight = (e) =>
        handleFileUpload(e, "BOARD_RESOLUTION", setloader8, setFileUploadedEight, setisUploaded2);

    const gotoNextPage = () => {
        window.location.replace("/dashboard");
    };

    const goNextPage = async () => {
        if (isUploaded1 && isUploaded2 && isUploaded3) {
            setloader(true);

            try {
                const businessData = await handleUpdateBusiness(state.businessId);
                axios.defaults.headers.common["x-business-id"] = businessData.business._id;

                try {
                    await handleRequestVerification();

                    if (localStorage.getItem("default") !== null) {
                        localStorage.removeItem("default");
                    }
                    localStorage.setItem("hide", false);
                    setshowcongrats(true);
                } catch (verificationError) {
                    console.error(verificationError);
                    setErrorMessage(verificationError.message || "Verification request failed");
                    setfails(true);
                }
            } catch (updateError) {
                console.error(updateError);
                setErrorMessage(updateError.message || "Business update failed");
                setfails(true);
            } finally {
                setloader(false);
            }
        } else {
            setfails(true);
            setErrorMessage("Upload all the required documents");
        }
    };

    return (
        <div className="bg-gray-50 overflow-hidden pr-0  w-full mx-auto">
            <Helmet>
                <title>Business Document</title>
            </Helmet>

            <LoginHeader width={106} />
            <DirectorModalBvn
                state={state}
                show={showModal}
                setShowModal={setShowModal}
                director={director}
                setfileUploadedBvn={setfileUploadedBvn}
                setDirector={setDirector}
                type={state?.businessType}
                setisUploaded3={setisUploaded3}
            />
            <Congratulations
                show={showcongrats}
                setshow={setshowcongrats}
                goNextPage={gotoNextPage}
            />

            <div className="h-full flex justify-center items-center">
                <div className="relative max-w-[591px] form-general-layout">
                    <FailHandler success={fails} message={errorMessage} />
                    <div className="card-body flex flex-col gap-8">
                        <div className="flex justify-between items-center">
                            <ArrowBack />
                            <span
                                className="text-sm font-semibold text-gray-800 cursor-pointer leading-[19.6px]"
                                onClick={gotoNextPage}
                            >
                                Skip
                            </span>
                        </div>

                        <span className="block text-sm text-gray-500 leading-[19.6px] font-medium">
                            2 of 2
                        </span>

                        <div className="flex flex-col gap-1">
                            <div className="text-3xl font-semibold text-gray-900">
                                Upload business documents
                            </div>
                            <span className="text-base text-gray-500 leading-6 font-medium tracking-wide">
                                Provide your LLC documents
                            </span>
                        </div>

                        <div className="bg-white rounded-lg">
                            <div
                                className="position-relative "
                                style={{
                                    borderTop: "none",
                                    padding: "16px",
                                    borderRight: "none",
                                    borderLeft: "none",
                                    borderBottom: "1px solid #E7E9FB ",
                                    borderBottomLeftRadius: "0px",
                                    borderBottomRightRadius: "0px",
                                }}
                            >
                                <input
                                    type="file"
                                    accept=".png, .jpg, .jpeg, .pdf"
                                    name="file1"
                                    id="file1"
                                    onChange={handleFileOne}
                                    style={{
                                        position: "absolute",
                                        width: "100%",
                                        height: "50px",
                                        zIndex: 2,
                                        opacity: 0,
                                    }}
                                />
                                <div
                                    className="d-flex "
                                    style={{
                                        alignItems: "center",
                                        gap: "8px",
                                        cursor: "pointer",
                                        top: 0,
                                        width: "100%",
                                    }}
                                >
                                    <img src={document} alt="" />
                                    <div
                                        className="d-flex justify-content-between w-100"
                                        style={{ alignItems: "center" }}
                                    >
                                        <div className="flex flex-col">
                                            <div
                                                style={{
                                                    fontWeight: "600",
                                                    fontSize: "16px",
                                                    color: "#101828",
                                                    letterSpacing: "2%",
                                                    lineHeight: "24px",
                                                }}
                                            >
                                                CAC Form 2 (Optional)
                                            </div>
                                            <span
                                                style={{
                                                    display: "block",
                                                    fontSize: "12px",
                                                    color: "#667085 ",
                                                    lineHeight: "16.2px",
                                                    fontWeight: "500",
                                                    marginTop: "2px",
                                                }}
                                            >
                                                Statement of share capital and return of allotment
                                                of shares
                                            </span>
                                        </div>
                                        {loader1 === false ? (
                                            <div className="flex items-center">
                                                <div>
                                                    <p className="text-sm font-light text-gray-10">
                                                        JPEG, PNG, PDF
                                                    </p>
                                                    <p className="text-sm font-light text-gray-10">
                                                        File size: 5.0 MB
                                                    </p>
                                                </div>
                                                <span
                                                    className="material-icons"
                                                    style={{
                                                        color: "#98A2B3",
                                                    }}
                                                >
                                                    {fileUploadedOne ? (
                                                        <img src={marksuccess} alt="" />
                                                    ) : (
                                                        "chevron_right"
                                                    )}
                                                </span>
                                            </div>
                                        ) : (
                                            <Circles
                                                height="15"
                                                width="15"
                                                color="rgb(111, 0, 255)"
                                                ariaLabel="circles-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="position-relative"
                                style={{
                                    borderTop: "none",
                                    borderRight: "none",
                                    borderLeft: "none",
                                    borderBottom: "1px solid #E7E9FB ",
                                    borderBottomLeftRadius: "0px",
                                    borderBottomRightRadius: "0px",

                                    padding: "16px",
                                }}
                            >
                                <input
                                    type="file"
                                    accept=".png, .jpg, .jpeg, .pdf"
                                    name="file1"
                                    id="file1"
                                    onChange={handleFileTwo}
                                    style={{
                                        position: "absolute",
                                        width: "100%",
                                        height: "50px",
                                        zIndex: 2,
                                        opacity: 0,
                                    }}
                                />
                                <div
                                    className="d-flex "
                                    style={{
                                        alignItems: "center",
                                        cursor: "pointer",
                                        gap: "8px",
                                        top: 0,
                                        width: "100%",
                                    }}
                                >
                                    <img src={document} alt="" />
                                    <div
                                        className="d-flex justify-content-between w-100"
                                        style={{ alignItems: "center" }}
                                    >
                                        <div className="flex flex-col">
                                            <div
                                                style={{
                                                    fontWeight: "600",
                                                    fontSize: "16px",
                                                    color: "#101828",
                                                    letterSpacing: "2%",
                                                    lineHeight: "24px",
                                                }}
                                            >
                                                CAC Form 2.1 (Optional)
                                            </div>
                                            <span
                                                style={{
                                                    display: "block",
                                                    fontSize: "12px",
                                                    color: "#667085 ",
                                                    lineHeight: "16.2px",
                                                    fontWeight: "500",
                                                    marginTop: "2px",
                                                }}
                                                className="text-muted"
                                            >
                                                Particulars of secretary
                                            </span>
                                        </div>
                                        {loader2 === false ? (
                                            <div className="flex items-center">
                                                <div>
                                                    <p className="text-sm font-light text-gray-10">
                                                        JPEG, PNG, PDF
                                                    </p>
                                                    <p className="text-sm font-light text-gray-10">
                                                        File size: 5.0 MB
                                                    </p>
                                                </div>
                                                <span
                                                    className="material-icons"
                                                    style={{
                                                        color: "#98A2B3",
                                                    }}
                                                >
                                                    {fileUploadedTwo ? (
                                                        <img src={marksuccess} alt="" />
                                                    ) : (
                                                        "chevron_right"
                                                    )}
                                                </span>
                                            </div>
                                        ) : (
                                            <Circles
                                                height="15"
                                                width="15"
                                                color="rgb(111, 0, 255)"
                                                ariaLabel="circles-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="position-relative "
                                style={{
                                    borderTop: "none",
                                    padding: "16px",
                                    borderRight: "none",
                                    borderLeft: "none",
                                    borderBottom: "1px solid #E7E9FB ",
                                    borderBottomLeftRadius: "0px",
                                    borderBottomRightRadius: "0px",
                                }}
                            >
                                <input
                                    type="file"
                                    accept=".png, .jpg, .jpeg, .pdf"
                                    name="file1"
                                    id="file1"
                                    onChange={handleFileThree}
                                    style={{
                                        position: "absolute",
                                        width: "100%",
                                        height: "50px",
                                        zIndex: 2,
                                        opacity: 0,
                                    }}
                                />
                                <div
                                    className="d-flex "
                                    style={{
                                        alignItems: "center",
                                        cursor: "pointer",
                                        top: 0,
                                        width: "100%",
                                        gap: "8px",
                                    }}
                                >
                                    <img src={document} alt="" />
                                    <div
                                        className="d-flex justify-content-between w-100"
                                        style={{ alignItems: "center" }}
                                    >
                                        <div className="flex flex-col">
                                            <div
                                                style={{
                                                    fontWeight: "600",
                                                    fontSize: "16px",
                                                    color: "#101828",
                                                    letterSpacing: "2%",
                                                    lineHeight: "24px",
                                                }}
                                            >
                                                CAC Form 3 (Optional)
                                            </div>
                                            <span
                                                style={{
                                                    display: "block",
                                                    fontSize: "12px",
                                                    color: "#667085 ",
                                                    lineHeight: "16.2px",
                                                    fontWeight: "500",
                                                    marginTop: "2px",
                                                }}
                                            >
                                                Notice of registered addresses
                                            </span>
                                        </div>
                                        {loader3 === false ? (
                                            <div className="flex items-center">
                                                <div>
                                                    <p className="text-sm font-light text-gray-10">
                                                        JPEG, PNG, PDF
                                                    </p>
                                                    <p className="text-sm font-light text-gray-10">
                                                        File size: 5.0 MB
                                                    </p>
                                                </div>
                                                <span
                                                    className="material-icons"
                                                    style={{
                                                        color: "#98A2B3",
                                                    }}
                                                >
                                                    {fileUploadedThree ? (
                                                        <img src={marksuccess} alt="" />
                                                    ) : (
                                                        "chevron_right"
                                                    )}
                                                </span>
                                            </div>
                                        ) : (
                                            <Circles
                                                height="15"
                                                width="15"
                                                color="rgb(111, 0, 255)"
                                                ariaLabel="circles-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="relative !p-4 border-b border-[#E7E9FB] rounded-b-none">
                                <input
                                    type="file"
                                    accept=".png, .jpg, .jpeg, .pdf"
                                    name="file1"
                                    id="file1"
                                    onChange={handleFileFour}
                                    className="absolute w-full h-12 z-20 opacity-0"
                                />

                                <div className="flex items-center cursor-pointer gap-2 w-full">
                                    <img src={document} alt="" />
                                    <div className="flex justify-between w-full items-center">
                                        <div className="grid">
                                            <div className="!font-semibold !text-[16px] text-[#101828]">
                                                CAC Form 4 (Optional)
                                            </div>
                                            <span className="text-[12px] text-[#667085] font-medium">
                                                Declaration of compliance
                                            </span>
                                        </div>

                                        {loader4 === false ? (
                                            <div className="flex items-center">
                                                <div>
                                                    <p className="text-sm font-light text-gray-10">
                                                        JPEG, PNG, PDF
                                                    </p>
                                                    <p className="text-sm font-light text-gray-10">
                                                        File size: 5.0 MB
                                                    </p>
                                                </div>
                                                <span
                                                    className="material-icons"
                                                    style={{
                                                        color: "#98A2B3",
                                                    }}
                                                >
                                                    {fileUploadedFour ? (
                                                        <img src={marksuccess} alt="" />
                                                    ) : (
                                                        "chevron_right"
                                                    )}
                                                </span>
                                            </div>
                                        ) : (
                                            <Circles
                                                height="15"
                                                width="15"
                                                color="rgb(111, 0, 255)"
                                                ariaLabel="circles-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="relative !p-4 border-b border-[#E7E9FB]">
                                <input
                                    type="file"
                                    accept=".png, .jpg, .jpeg, .pdf"
                                    name="file1"
                                    id="file1"
                                    onChange={handleFileFive}
                                    className="absolute w-full h-[50px] z-10 opacity-0"
                                />
                                <div className="flex items-center cursor-pointer !gap-2 w-full">
                                    <img src={document} alt="" />
                                    <div className="flex justify-between items-center w-full">
                                        <div className="flex flex-col">
                                            <div className="font-semibold text-[16px] text-[#101828] -mt-1">
                                                CAC Form 7 (Optional)
                                            </div>
                                            <span className="text-[12px] text-[#667085] !font-medium">
                                                Particulars of directors
                                            </span>
                                        </div>
                                        {loader5 === false ? (
                                            <div className="flex items-center">
                                                <div>
                                                    <p className="text-sm font-light text-gray-500">
                                                        JPEG, PNG, PDF
                                                    </p>
                                                    <p className="text-sm font-light text-gray-500">
                                                        File size: 5.0 MB
                                                    </p>
                                                </div>
                                                <span className="material-icons text-[#98A2B3]">
                                                    {fileUploadedFive ? (
                                                        <img src={marksuccess} alt="" />
                                                    ) : (
                                                        "chevron_right"
                                                    )}
                                                </span>
                                            </div>
                                        ) : (
                                            <Circles
                                                height="15"
                                                width="15"
                                                color="rgb(111, 0, 255)"
                                                ariaLabel="circles-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="relative border-b border-[#E7E9FB] min-h-[70px] flex justify-center items-center !p-4">
                                <input
                                    type="file"
                                    accept=".png, .jpg, .jpeg, .pdf"
                                    name="file1"
                                    id="file1"
                                    onChange={handleFileSix}
                                    className="absolute w-full h-[50px] z-10 opacity-0"
                                />

                                <div className="flex items-center cursor-pointer w-full !gap-2">
                                    <img src={document} alt="" />
                                    <div className="flex justify-between items-center w-full">
                                        <div className="flex flex-col">
                                            <div className="font-semibold text-[16px] text-[#101828]">
                                                Memorandum & articles of association
                                            </div>
                                        </div>
                                        {loader6 === false ? (
                                            <div className="flex items-center">
                                                <div>
                                                    <p className="text-sm font-light text-gray-500">
                                                        JPEG, PNG, PDF
                                                    </p>
                                                    <p className="text-sm font-light text-gray-500">
                                                        File size: 5.0 MB
                                                    </p>
                                                </div>
                                                <span className="material-icons text-[#98A2B3]">
                                                    {fileUploadedSix ? (
                                                        <img src={marksuccess} alt="" />
                                                    ) : (
                                                        "chevron_right"
                                                    )}
                                                </span>
                                            </div>
                                        ) : (
                                            <Circles
                                                height="15"
                                                width="15"
                                                color="rgb(111, 0, 255)"
                                                ariaLabel="circles-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div
                                className="relative border-b border-[#E7E9FB] min-h-[70px] flex justify-center items-center !p-4 cursor-pointer"
                                onClick={showModalHandle}
                            >
                                <div className="flex items-center w-full !gap-2">
                                    <img src={document} alt="" />
                                    <div className="flex justify-between items-center w-full">
                                        <div className="flex flex-col">
                                            <div className="font-semibold text-[16px] text-[#101828]">
                                                BVN of the Directors of the Company
                                            </div>
                                        </div>
                                        <span className="material-icons text-[#98A2B3]">
                                            {fileUploadedBvn ? (
                                                <img src={marksuccess} alt="" />
                                            ) : (
                                                "chevron_right"
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="relative border-0 !p-4 min-h-[70px] flex justify-center items-center">
                                <input
                                    type="file"
                                    accept=".png, .jpg, .jpeg, .pdf"
                                    name="file1"
                                    id="file1"
                                    onChange={handleFileEight}
                                    className="absolute w-full h-[50px] z-10 opacity-0"
                                />
                                <div className="flex items-center cursor-pointer !gap-2 w-full">
                                    <img src={document} alt="" />
                                    <div className="flex justify-between items-center w-full">
                                        <div className="flex flex-col">
                                            <div className="font-semibold text-[16px] text-[#101828]">
                                                Board resolution to open an account with Tradevu
                                            </div>
                                        </div>
                                        {loader8 === false ? (
                                            <span className="material-icons text-[#98A2B3]">
                                                {fileUploadedEight ? (
                                                    <img src={marksuccess} alt="" />
                                                ) : (
                                                    "chevron_right"
                                                )}
                                            </span>
                                        ) : (
                                            <Circles
                                                height="15"
                                                width="15"
                                                color="rgb(111, 0, 255)"
                                                ariaLabel="circles-loading"
                                                visible={true}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button
                            disabled={loader}
                            className="mt-3 btn"
                            onClick={() => goNextPage()}
                            style={{
                                background: "#6F00FF",
                                color: "#fff",
                                fontSize: "16px",
                                lineHeight: "24px",
                                fontWeight: "600",
                                letterSpacing: "2%",
                                padding: "16px 24px 16px 24px",
                                height: "48px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                maxWidth: "279px",
                                width: "100%",
                            }}
                        >
                            {loader ? <Loader /> : "Continue"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BusinessDocumentsLLC;
