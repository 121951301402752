import { Helmet } from "react-helmet";
import DashHeader from "../component/dashheader";
import homefinance from "../asset/images/homefinance.svg";
import invoices from "../asset/images/InvoiceFactoring.svg";
import letterofcredit from "../asset/images/creditLine.svg";
import trade from "../asset/images/workingCapital.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import search from "../asset/images/search-normal.svg";
import ComponentLoader from "../component/componentloader";
import longinvoice from "../asset/images/flex/longinvoice.svg";
import axios from "axios";
import invoiceimg from "../asset/images/invoice.svg";
import calendar from "../asset/images/calendar-2.svg";
import FinanceRequestModalLoan from "../component/TransactionDetail2";
import dot from "../asset/images/simpledot.png";
import Notice from "../component/notice";
import incompleteprofile from "../asset/images/incomplete_profile.svg";
import reviewprofile from "../asset/images/ReviewProgress.svg";

import { useSelector } from "react-redux";
import currencyPairCheck from "../utilities/currencyPairCheck";
import AmountCheck from "../component/amountCheck";
import Navbar from "component/layout/navbar";

const FinanceHome = () => {
    const navigate = useNavigate();
    const [selectedIndex, setselectedIndex] = useState(-1);
    const [mydate, setmydate] = useState(new Date());
    const [invoice, setinvoices] = useState([]);
    const selectorbusiness = useSelector((state) => state.business);

    const [inputSearch, setinputsearch] = useState("");
    const [loaderset, setloaderset] = useState(true);
    const [tab, settab] = useState("all");
    const [maintab, setmaintab] = useState("Instruments");
    const [searchbank, setsearchbank] = useState("");
    const [show, sethsow] = useState(false);
    const [transaction, settransaction] = useState([]);
    const [shownotice, setshownotice] = useState(false);
    const [summary, setSummary] = useState([]);

    const businessIdHeaderConfig = {
        headers: { "x-business-id": selectorbusiness?._id },
    };

    const getfinance = (index) => {
        setselectedIndex(index);
        if (index === 0) {
            navigate("/finance/invoice");
        } else if (index === 1) {
            navigate("/trade_of_credit/home");
        }
    };

    const weekday = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
    let day = weekday[mydate.getDay()];

    const month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "december",
    ];
    useEffect(() => {
        const fetchaccount = async () => {
            await axios
                .get("/v1/finance/all", businessIdHeaderConfig)
                .then((res) => {
                    //console.log(res);
                    setinvoices(res.data.slice(0, 10));
                    setloaderset(false);
                })
                .catch((e) => {
                    console.log(e);
                    setloaderset(false);
                });
        };
        selectorbusiness?._d && fetchaccount();
    }, [selectorbusiness]);

    useEffect(() => {
        const fetchSummary = async () => {
            await axios
                .get("/v1/finance/summery", businessIdHeaderConfig)
                .then((res) => {
                    setSummary(res.data);
                    //  console.log(res);
                })
                .catch((e) => {
                    setSummary([]);
                    console.log(e);
                });
        };
        selectorbusiness?._id && fetchSummary();
    }, [selectorbusiness]);
    //console.log(invoice);

    const filteredData = invoice.filter(
        (item) =>
            (item.requestedAmount && item.requestedAmount.toString().includes(inputSearch)) ||
            (item.currency && item.currency.toLowerCase().includes(inputSearch.toLowerCase())) ||
            (item.tenure && item.tenure.toString().includes(inputSearch)) ||
            (item.user && item.user.toLowerCase().includes(inputSearch.toLowerCase())) ||
            (item.invoiceType &&
                item.invoiceType.toLowerCase().includes(inputSearch.toLowerCase())) ||
            (item.paymentStatus &&
                item.paymentStatus.toLowerCase().includes(inputSearch.toLowerCase())) ||
            (item.requestTitle &&
                item.requestTitle.toLowerCase().includes(inputSearch.toLowerCase())) ||
            (item.annualRevenue && item.annualRevenue.toString().includes(inputSearch)) ||
            (item.annualTurnOver && item.annualTurnOver.toString().includes(inputSearch)) ||
            (item.businessName && item.businessName.toString().includes(inputSearch)) ||
            (item.financeType && item.financeType.toString().includes(inputSearch)) ||
            (item.loanAmount && item.loanAmount.toString().includes(inputSearch))
    );

    const filterInvoice = filteredData.filter((item) =>
        item.status?.toLowerCase()?.includes(searchbank?.toLowerCase())
    );

    const filterInvoiceProcess = invoice.filter((item) => item.status?.match("PENDING"));
    const filterInvoiceComplte = invoice?.filter((item) => item.status?.match("COMPLETED"));
    const filterUnpaid = invoice?.filter((item) => item.paymentStatus?.match("UNPAID"));
    invoice.sort((a, b) => a.createdAt.localeCompare(b.createdAt, "es", { sensitivity: "base" }));
    let datass = filterInvoice.reduce((r, e) => {
        let alphabet = new Date(e.createdAt).toDateString();
        if (!r[alphabet])
            r[alphabet] = {
                alphabet,
                record: [],
            };

        r[alphabet].record.push(e);
        return r;
    }, {});
    let result = Object.values(datass);
    // console.log(result);

    useEffect(() => {
        const timer = setInterval(() => {
            setmydate(new Date());
        }, 60000);
        return () => {
            clearInterval(timer);
        };
    });
    let months = month[mydate.getMonth()];
    let year = mydate.getFullYear();
    let today = mydate.getDate();
    let hrs = mydate.getHours();
    let min = mydate.getMinutes();

    const finance = [
        {
            title: "Invoice Factoring",
            opt: "Sell your invoice for immediate cash",
            image: invoices,
        },
        // {
        //   title: "Credit Line",
        //   opt: "Access a credit line to pay partners.",
        //   image: letterofcredit,
        // },
        {
            title: "Working Capital",
            opt: "Get a quick loan for your transaction",
            image: trade,
        },
    ];

    return (
        <div className="h-100 w-100 ">
            <div
                className=""
                style={{
                    maxWidth: "2000px",
                    margin: "0px auto",
                }}
            >
                <Helmet>
                    <title>Tradevu — finance</title>
                </Helmet>

                <Navbar />

                <FinanceRequestModalLoan
                    setshownotice={setshownotice}
                    show={show}
                    setshow={sethsow}
                    data={transaction}
                />
                <Notice show={shownotice} setshow={setshownotice} data={transaction} />
            </div>
            <div
                style={{
                    height: "100%",
                    maxWidth: "2000px",
                    margin: "0px auto",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "48px",
                    marginTop: "40px",
                }}
            >
                <div
                    className=""
                    style={{
                        margin: "0px auto",

                        width: "100%",
                        padding: "0px 80px",
                    }}
                >
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "column",
                                    padding: "0px 32px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "8px",
                                    }}
                                >
                                    <h1
                                        style={{
                                            fontSize: "32px",
                                            lineHeight: "40px",
                                            color: "#344054",
                                            fontWeight: "500",
                                            padding: "0px",
                                            margin: "0px",
                                        }}
                                    >
                                        Finance
                                    </h1>
                                    <span
                                        style={{
                                            lineHeight: "24px",
                                            fontSize: "1rem",
                                            fontWeight: "400",
                                            letterSpacing: "2%",
                                            marginRight: "5px",
                                            color: "#101828",
                                        }}
                                    >
                                        Access capital using the following options
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 ">
                            <div
                                className="d-flex"
                                style={{
                                    flexDirection: "column",
                                    justifyContent: "end",
                                    alignItems: "end",
                                    paddingRight: "15px",
                                }}
                            >
                                <span
                                    style={{
                                        color: "#667085",
                                        lineHeight: "24px",
                                        fontWeight: "400",
                                        letterSpacing: "2%",
                                    }}
                                >
                                    {" "}
                                    {hrs}:{min}{" "}
                                </span>
                                <span
                                    style={{
                                        color: "#667085",
                                        lineHeight: "24px",
                                        fontWeight: "400",
                                        letterSpacing: "2%",
                                    }}
                                >
                                    {" "}
                                    {day},<span> {today}</span>
                                    <span> {months} </span>
                                    {year}{" "}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className=" "
                    style={{
                        margin: "0px auto",
                        paddingBottom: "10px",
                        width: "100%",
                        padding: "0px 80px 0px 80px",
                    }}
                >
                    <ul className="list_usage">
                        <li
                            style={{
                                borderBottom: maintab === "Instruments" && "3px solid #6F00FF",
                                fontWeight: maintab === "Instruments" && "700",
                            }}
                            onClick={() => setmaintab("Instruments")}
                        >
                            Credits
                        </li>
                        <li
                            onClick={() => setmaintab("History")}
                            style={{
                                borderBottom: maintab === "History" && "3px solid #6F00FF",
                                color: maintab === "History" && "#101828",
                                color: maintab === "History" && "700",
                            }}
                        >
                            History
                        </li>
                    </ul>
                    {maintab === "Instruments" && (
                        <div
                            className="card border-0"
                            style={{
                                minHeight: "704px",
                                borderRadius: "16px",
                            }}
                        >
                            <div
                                className="card-body"
                                style={{
                                    padding: "30px",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "64px",
                                }}
                            >
                                <div className="row mycolumn">
                                    {" "}
                                    {finance.map((finance, index) => (
                                        <div className="col-md-4 col-lg-4 " key={index}>
                                            <div
                                                className="card"
                                                onClick={() => getfinance(index)}
                                                style={{
                                                    cursor: "pointer",
                                                    padding: "8px",
                                                    borderRadius: "8px",
                                                    border:
                                                        selectedIndex === index
                                                            ? "1px solid #6F00FF"
                                                            : "1px solid #E7E9FB",
                                                }}
                                            >
                                                <div
                                                    className="card-body "
                                                    style={{
                                                        padding: "10px",
                                                        background:
                                                            selectedIndex === index
                                                                ? "#F4F0FF"
                                                                : "#fff",
                                                        borderRadius: "8px",
                                                    }}
                                                >
                                                    <div
                                                        className=" d-flex "
                                                        style={{ gap: "16px" }}
                                                    >
                                                        <img src={finance.image} alt="" />
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "space-between",
                                                                gap: "8px",
                                                                overflow: "hidden",
                                                            }}
                                                        >
                                                            <h1
                                                                style={{
                                                                    fontWeight: "700",
                                                                    fontSize: "18px",
                                                                    lineHeight: "24.3px",
                                                                    padding: "0px",
                                                                    margin: "0px",
                                                                    color: "#101828",
                                                                }}
                                                            >
                                                                {" "}
                                                                {finance.title}{" "}
                                                            </h1>
                                                            <span
                                                                style={{
                                                                    fontSize: "14px",
                                                                    lineHeight: "19.6px",
                                                                    fontWeight: "400",
                                                                    color: "#667085",
                                                                    letterSpacing: "-1%",
                                                                }}
                                                            >
                                                                {" "}
                                                                {finance.opt}{" "}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}{" "}
                                </div>
                                <div>
                                    {" "}
                                    {result.length === 0 && (
                                        <div className="no_invoice">
                                            <div className="no_mid_invoice">
                                                <h4 className="text">No finance requests yet</h4>
                                                <span className="material-icons">
                                                    chevron_right
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {result.length > 0 && (
                                        <div
                                            className="no_invoice"
                                            onClick={() => setmaintab("History")}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="no_mid_invoice_lg">
                                                <div className="md-invoice-lg">
                                                    <h4 className="text">
                                                        {invoice?.length} Request(s)
                                                    </h4>
                                                    <span>
                                                        {summary?.totalRequests} Received,{" "}
                                                        {summary?.processingRequests} Pending,{" "}
                                                    </span>
                                                </div>
                                                <span
                                                    className="material-icons"
                                                    style={{
                                                        color: "#0FBBE6",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    chevron_right
                                                </span>
                                            </div>
                                            <div
                                                className="no_mid_invoice_lg"
                                                style={{
                                                    background: "#EDFCFE",
                                                    borderRadius: "6px",
                                                    margin: "0px 5px",
                                                    display: "flex",
                                                    justifyContent: "start",
                                                    gap: "8px",
                                                }}
                                            >
                                                <img src={calendar} alt="" />
                                                <div className="md-invoice-lg">
                                                    <h4
                                                        className="text"
                                                        style={{ color: "#0E84AC" }}
                                                    >
                                                        {summary?.dueLoans} Due
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    )}{" "}
                                </div>
                            </div>
                        </div>
                    )}
                    {maintab === "History" && (
                        <>
                            <div
                                className="card border-0"
                                style={{
                                    minHeight: "704px",
                                    borderRadius: "16px",
                                }}
                            >
                                <div className="card-body" style={{ padding: "30px" }}>
                                    <div style={{ marginTop: "30px" }}>
                                        <div
                                            className="main-c-flex"
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "32px",
                                                width: "100%",
                                                maxWidth: "864px",
                                                margin: "0px auto",
                                            }}
                                        >
                                            <div
                                                className="tab-search"
                                                style={{
                                                    gap: "16px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        background: "#ffffff",
                                                        borderRadius: "8px",
                                                        padding: "4px",
                                                        display: "flex",
                                                        gap: "8px",
                                                        justifyContent: "flex-start",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            padding: "8px 16px 8px 16px",
                                                            background:
                                                                tab === "all" ? "#EBE4FF" : "",
                                                            textAlign: "center",
                                                            borderRadius: "4px",
                                                            cursor: "pointer",
                                                            color:
                                                                tab === "all"
                                                                    ? "#6F00FF"
                                                                    : "#667085",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontWeight: "500",
                                                        }}
                                                        onClick={() => {
                                                            settab("all");
                                                            setsearchbank("");
                                                        }}
                                                    >
                                                        All
                                                    </div>
                                                    <div
                                                        style={{
                                                            background:
                                                                tab === "Processing"
                                                                    ? "#EBE4FF"
                                                                    : "",
                                                            textAlign: "center",
                                                            borderRadius: "4px",
                                                            padding: "8px 16px 8px 16px",
                                                            cursor: "pointer",
                                                            color:
                                                                tab === "Processing"
                                                                    ? "#6F00FF"
                                                                    : "#667085",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            display: "flex",
                                                            fontWeight: "500",
                                                        }}
                                                        onClick={() => {
                                                            settab("Processing");
                                                            setsearchbank("Processing");
                                                        }}
                                                    >
                                                        Processing
                                                    </div>
                                                    <div
                                                        style={{
                                                            background:
                                                                tab === "Completed"
                                                                    ? "#EBE4FF"
                                                                    : "",

                                                            padding: "8px 16px 8px 16px",
                                                            textAlign: "center",
                                                            borderRadius: "4px",
                                                            cursor: "pointer",
                                                            color:
                                                                tab === "Completed"
                                                                    ? "#6F00FF"
                                                                    : "#667085",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            display: "flex",
                                                            fontWeight: "500",
                                                        }}
                                                        onClick={() => {
                                                            settab("Completed");
                                                            setsearchbank("Completed");
                                                        }}
                                                    >
                                                        Completed
                                                    </div>
                                                    <div
                                                        style={{
                                                            background:
                                                                tab === "Declined" ? "#EBE4FF" : "",

                                                            padding: "8px 16px 8px 16px",
                                                            textAlign: "center",
                                                            borderRadius: "4px",
                                                            cursor: "pointer",
                                                            color:
                                                                tab === "Declined"
                                                                    ? "#6F00FF"
                                                                    : "#667085",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            display: "flex",
                                                            fontWeight: "500",
                                                        }}
                                                        onClick={() => {
                                                            settab("Declined");
                                                            setsearchbank("Declined");
                                                        }}
                                                    >
                                                        Declined
                                                    </div>
                                                    <div
                                                        style={{
                                                            background:
                                                                tab === "Due" ? "#EBE4FF" : "",

                                                            padding: "8px 16px 8px 16px",
                                                            textAlign: "center",
                                                            borderRadius: "4px",
                                                            cursor: "pointer",
                                                            color:
                                                                tab === "Due"
                                                                    ? "#6F00FF"
                                                                    : "#667085",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            display: "flex",
                                                            fontWeight: "500",
                                                        }}
                                                        onClick={() => {
                                                            settab("Due");
                                                            setsearchbank("Due");
                                                        }}
                                                    >
                                                        Due
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        background: "#ECEFF3",
                                                        display: "flex",
                                                        gap: "8px",
                                                        height: "48px",
                                                        borderRadius: "8px",
                                                        padding: "0px 8px 0px 8px",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <img src={search} alt="" />
                                                    <input
                                                        type="text"
                                                        style={{
                                                            color: "#667085",
                                                            fontSize: "14px",
                                                            fontWeight: "400",
                                                            lineHeight: "19.6px",
                                                            width: "100%",
                                                            outline: "none",
                                                            background: "#ECEFF3",
                                                            border: "none",
                                                        }}
                                                        onChange={(e) => {
                                                            setinputsearch(e.target.value);
                                                        }}
                                                        placeholder="Search finance type, amount and more"
                                                    />
                                                </div>
                                            </div>
                                            {loaderset === false ? (
                                                <>
                                                    {" "}
                                                    {result.length === 0 && (
                                                        <div
                                                            className="no-flex-c"
                                                            style={{
                                                                height: "460px",
                                                                overflow: "auto",
                                                                padding: "16px 24px 24px 24px",
                                                                borderRadius: "16px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    alignItems: "center",
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    justifyContent: "center",
                                                                    gap: "24px",
                                                                }}
                                                            >
                                                                <img src={invoiceimg} alt="" />
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        gap: "8px",
                                                                    }}
                                                                >
                                                                    <h3
                                                                        style={{
                                                                            fontWeight: "700",
                                                                            color: "#101828",
                                                                            fontSize: "18px",
                                                                            lineHeight: "24.3px",
                                                                            textAlign: "center",
                                                                        }}
                                                                    >
                                                                        There are no invoices yet
                                                                    </h3>
                                                                    <span
                                                                        style={{
                                                                            color: "#667085",
                                                                            fontSize: "14px",
                                                                            lineHeight: "19.6px",
                                                                            fontWeight: "400",
                                                                            textAlign: "center",
                                                                            maxWidth: "229px",
                                                                        }}
                                                                    >
                                                                        Start adding your invoices
                                                                        to Tradevu to see them here.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {invoice.length >= 1 && (
                                                        <div style={{ height: "100%" }}>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    gap: "16px",
                                                                }}
                                                            >
                                                                {result
                                                                    ?.reverse()
                                                                    ?.map((invoce, index) => (
                                                                        <>
                                                                            <label
                                                                                key={index}
                                                                                htmlFor=""
                                                                                style={{
                                                                                    color: "#98A2B3",
                                                                                    fontSize:
                                                                                        "12px",
                                                                                    fontWeight:
                                                                                        "400",
                                                                                    lineHeight:
                                                                                        "16.2px",
                                                                                    textTransform:
                                                                                        "uppercase",
                                                                                }}
                                                                            >
                                                                                {new Date(
                                                                                    invoce.alphabet
                                                                                ).toDateString() ===
                                                                                new Date().toDateString()
                                                                                    ? "Today"
                                                                                    : new Date().getMonth() -
                                                                                          1 ===
                                                                                      new Date(
                                                                                          invoce.alphabet
                                                                                      ).getMonth()
                                                                                    ? "Last Month"
                                                                                    : new Date(
                                                                                          invoce.alphabet
                                                                                      ).toDateString()}{" "}
                                                                            </label>
                                                                            <div
                                                                                style={{
                                                                                    boxShadow:
                                                                                        " 0px 0px 0px 0.5px #E7E9FB",

                                                                                    borderRadius:
                                                                                        "8px",

                                                                                    padding: "16px",
                                                                                }}
                                                                            >
                                                                                {invoce.record
                                                                                    ?.reverse()
                                                                                    ?.map(
                                                                                        (
                                                                                            item,
                                                                                            indexs
                                                                                        ) => (
                                                                                            <>
                                                                                                <div
                                                                                                    className="border_lg_ur"
                                                                                                    key={
                                                                                                        indexs
                                                                                                    }
                                                                                                    style={{
                                                                                                        padding:
                                                                                                            "16px",
                                                                                                        display:
                                                                                                            "flex",
                                                                                                        alignItems:
                                                                                                            "center",
                                                                                                        justifyContent:
                                                                                                            "space-between",
                                                                                                        cursor: "pointer",
                                                                                                    }}
                                                                                                    onClick={() => {
                                                                                                        sethsow(
                                                                                                            true
                                                                                                        );
                                                                                                        settransaction(
                                                                                                            item
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        style={{
                                                                                                            display:
                                                                                                                "flex",
                                                                                                            gap: "8px",
                                                                                                        }}
                                                                                                    >
                                                                                                        <img
                                                                                                            src={
                                                                                                                item.financeType ===
                                                                                                                "tradeCredit"
                                                                                                                    ? trade
                                                                                                                    : item.financeType ===
                                                                                                                      "creditLine"
                                                                                                                    ? letterofcredit
                                                                                                                    : invoices
                                                                                                            }
                                                                                                            alt=""
                                                                                                        />{" "}
                                                                                                        <div
                                                                                                            style={{
                                                                                                                display:
                                                                                                                    "flex",
                                                                                                                flexDirection:
                                                                                                                    "column",
                                                                                                                gap: "4px",
                                                                                                            }}
                                                                                                        >
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    color: "#101828",
                                                                                                                    fontWeight:
                                                                                                                        "600",
                                                                                                                    fontSize:
                                                                                                                        "14px",
                                                                                                                    lineHeight:
                                                                                                                        "19.6px",
                                                                                                                }}
                                                                                                            >
                                                                                                                {item?.requestTitle ? (
                                                                                                                    item.requestTitle
                                                                                                                ) : item?.firstName ? (
                                                                                                                    <>
                                                                                                                        {
                                                                                                                            item?.firstName
                                                                                                                        }{" "}
                                                                                                                        {
                                                                                                                            item?.lastName
                                                                                                                        }
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    item.financeType ===
                                                                                                                        "tradeCredit" &&
                                                                                                                    "Working Capital"
                                                                                                                )}{" "}
                                                                                                            </span>
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    color: "#667085",
                                                                                                                    letterSpacing:
                                                                                                                        "2%",
                                                                                                                    fontWeight:
                                                                                                                        "400",
                                                                                                                    lineHeight:
                                                                                                                        "16.2px",
                                                                                                                    fontSize:
                                                                                                                        "14px",
                                                                                                                }}
                                                                                                            >
                                                                                                                {item.financeType ===
                                                                                                                "tradeCredit"
                                                                                                                    ? "Working Capital"
                                                                                                                    : item.financeType ===
                                                                                                                      "creditLine"
                                                                                                                    ? "Credit Line"
                                                                                                                    : "Finance"}{" "}
                                                                                                                <img
                                                                                                                    src={
                                                                                                                        dot
                                                                                                                    }
                                                                                                                    alt=""
                                                                                                                />{" "}
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        fontSize:
                                                                                                                            "14px",
                                                                                                                        color: "#667085",
                                                                                                                        letterSpacing:
                                                                                                                            "2%",
                                                                                                                        fontWeight:
                                                                                                                            "400",
                                                                                                                        lineHeight:
                                                                                                                            "16.2px",
                                                                                                                        color:
                                                                                                                            item?.status ===
                                                                                                                            "SUBMITTED"
                                                                                                                                ? "#F79009"
                                                                                                                                : item?.status ===
                                                                                                                                  "DUE"
                                                                                                                                ? "crimson"
                                                                                                                                : item?.status ===
                                                                                                                                      "COMPLETED" ||
                                                                                                                                  item?.status ===
                                                                                                                                      "APPROVED"
                                                                                                                                ? "#12B76A"
                                                                                                                                : item?.status ===
                                                                                                                                  "DECLINED"
                                                                                                                                ? "#F04438"
                                                                                                                                : item?.status ===
                                                                                                                                  "PROCESSING"
                                                                                                                                ? "#6F00FF"
                                                                                                                                : "#F79009",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {item?.status ===
                                                                                                                    "SUBMITTED"
                                                                                                                        ? "PENDING"
                                                                                                                        : item?.status}{" "}
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        style={{
                                                                                                            display:
                                                                                                                "flex",
                                                                                                            flexDirection:
                                                                                                                "column",
                                                                                                            gap: "4px",
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color: "#344054",
                                                                                                                fontWeight:
                                                                                                                    "600",
                                                                                                                fontSize:
                                                                                                                    "14px",
                                                                                                                lineHeight:
                                                                                                                    "19.6px",
                                                                                                                textAlign:
                                                                                                                    "right",
                                                                                                            }}
                                                                                                        >
                                                                                                            {currencyPairCheck(
                                                                                                                item.currency
                                                                                                            )}
                                                                                                            {AmountCheck(
                                                                                                                item.loanAmount !=
                                                                                                                    undefined
                                                                                                                    ? item.loanAmount
                                                                                                                    : item.requestedAmount
                                                                                                            )}{" "}
                                                                                                        </span>
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color: "#667085",
                                                                                                                letterSpacing:
                                                                                                                    "2%",
                                                                                                                fontWeight:
                                                                                                                    "400",
                                                                                                                lineHeight:
                                                                                                                    "16.2px",
                                                                                                            }}
                                                                                                        >
                                                                                                            {new Date(
                                                                                                                item.createdAt
                                                                                                            ).toLocaleTimeString()}{" "}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    )}{" "}
                                                                            </div>
                                                                        </>
                                                                    ))}{" "}
                                                            </div>
                                                        </div>
                                                    )}{" "}
                                                </>
                                            ) : (
                                                <ComponentLoader />
                                            )}{" "}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}{" "}
                </div>
            </div>
        </div>
    );
};

export default FinanceHome;
