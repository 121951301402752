import LoginHeader from "../../../component/loginheader";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import "../../../style/remove.css";
import searchIcon from "../../../asset/images/search-normal.svg";

import ArrowBack from "../../../component/arrowback";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Loaders from "../../../component/loader";
import { countrycodelist } from "../../../constants/countryList";
import SuccessHodler from "../../../component/successholder";
import FailHandler from "../../../component/failhandler";
import successmark from "../../../asset/images/Checkbox.svg";
import { handleRegisterUser, handleSendVerificationEmail } from "api/fetchers";

const PersonalInformation = () => {
    const [show, setShow] = useState(false);
    const [countrycode, setCountrycode] = useState("+234");
    const [isChecked, setisChecked] = useState(false);
    const [fname, setfName] = useState("");
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [lname, setlName] = useState("");
    const [phone, setphone] = useState("");
    const { state } = useLocation();
    const [loader, setLoader] = useState(false);
    const [countryflag, setCountryFlag] = useState(null);
    const [search, setSearch] = useState("");
    const [showfirstnamelabe, setshowFirstnamelabel] = useState(false);
    const [showphone, setshowphone] = useState(false);
    const [showlastnamelabel, setshowLastnamelabel] = useState(false);
    const [changecolorforlabelemail, setchangecolorforlabelemail] = useState(false);
    const [success, setsuccess] = useState(false);
    const [fail, setfail] = useState(false);
    const [message, setmessage] = useState("");
    const [changecolorforlabelpassword, setchangecolorforlabelpassword] = useState(false);
    const [changecolorforlabelnumber, setchangecolorforlabelnumber] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        setCountryFlag(countrycodelist[0].image);
    }, []);
    useEffect(() => {
        if (success || fail) {
            let timer = setTimeout(() => {
                setsuccess(false);
            }, 3000);
            return () => {
                clearTimeout(timer);
            };
        }
    });
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const checkclick = (data, index) => {
        setSelectedIndex(index);
        setCountrycode(data.num);
        setCountryFlag(data.image);
        isChecked ? setisChecked(false) : setisChecked(true);
        setShow(!show);
    };
    const filterCountry = countrycodelist.filter(
        (countrycodelists) =>
            countrycodelists.title.match(search) || countrycodelists.num.match(search)
    );

    const handleverify = async (e) => {
        e.preventDefault();
        setLoader(true);

        const data = {
            firstName: fname,
            lastName: lname,
            phoneNumber: countrycode + phone,
            password: state.password,
            email: state.email,
        };

        try {
            // Register user
            await handleRegisterUser(data);

            // Send verification email
            await handleSendVerificationEmail(state.email);

            // Navigate to verify email page
            navigate("/verify-email", {
                state: {
                    ...data,
                },
            });
        } catch (error) {
            setfail(true);
            setmessage(error?.message || "An error occurred. Please try again.");
        } finally {
            setLoader(false);
        }
    };

    return (
        <div className="bg-[#F6F8FA] pr-0 !max-w-[2000px] w-full !mx-auto">
            <Helmet>
                <title>Tradevu — register </title>
            </Helmet>
            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton className="!border-0"></Modal.Header>
                <Modal.Body className="!h-[calc(100vh-100px)]">
                    <div className="!h-full">
                        <h4 className="text-base font-medium">Choose country code</h4>
                        <div className="!mt-4 flex !gap-2 items-center !p-3 bg-[#ECEFF3] rounded-lg">
                            <img src={searchIcon} alt="Search Icon" width={24} height={24} />
                            <input
                                type="search"
                                className="border-0 bg-inherit !shadow-none !text-sm font-semibold !text-[#667085] w-full"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder="Search countries"
                            />
                        </div>
                        <div className="!my-3 card border-0 bg-white !overflow-auto !h-[calc(100vh-250px)] !mycardscroll">
                            {filterCountry.map((countrycode, index) => (
                                <div className="p-1 card-body" key={index}>
                                    <div
                                        className={`flex gap-3 p-2 items-center rounded-lg w-full ${
                                            selectedIndex === index ? "bg-[#F4F0FF]" : ""
                                        }`}
                                        onClick={() => checkclick(countrycode, index)}
                                    >
                                        <img
                                            src={countrycode.image}
                                            className="rounded-full"
                                            alt="Country Flag"
                                            width={20}
                                        />
                                        <div className="flex justify-between items-center w-full overflow-hidden">
                                            <div className="grid">
                                                <span className="text-base font-semibold leading-6 tracking-[2%]">
                                                    {countrycode.title}
                                                </span>
                                                <span className="text-sm text-[#667085] font-medium leading-[16.2px]">
                                                    {countrycode.num}
                                                </span>
                                            </div>
                                            {selectedIndex === index && (
                                                <img src={successmark} alt="Selected" />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <LoginHeader width={12} />

            <div className="p-2 justify-center items-center flex h-screen max-h-[900px]">
                <div className="!form-general-layout !relative">
                    <SuccessHodler success={success} message={message} />
                    <FailHandler success={fail} message={message} />
                    <div className="!card-body !flex !flex-col gap-8">
                        <div className="flex flex-col gap-4">
                            <ArrowBack />
                            <div className="flex flex-col gap-1">
                                <h3 className="!text-4xl font-semibold text-[#101828] p-0 m-0">
                                    Tell us a bit about you
                                </h3>
                                <span className="text-[#667085] text-base leading-6">
                                    Enter your personal information
                                </span>
                            </div>
                        </div>

                        <form action="" onSubmit={handleverify} className="my-3">
                            <div className="w-full relative flex flex-col gap-1 bg-[#ECEFF3] h-14 rounded-lg p-2 justify-center">
                                {showfirstnamelabe && (
                                    <label
                                        htmlFor="Email"
                                        className={`text-xs font-semibold leading-[16.2px] ${
                                            changecolorforlabelemail
                                                ? "text-[#6F00FF]"
                                                : "text-[#667085]"
                                        }`}
                                    >
                                        First name
                                    </label>
                                )}
                                <input
                                    type="text"
                                    className="w-full border-0 shadow-none outline-none !text-[#667085] text-base !bg-[#ECEFF3] rounded-lg placeholder-gray-400"
                                    value={fname}
                                    required
                                    onKeyPress={() => setchangecolorforlabelemail(true)}
                                    onBlur={() => {
                                        setchangecolorforlabelemail(false);
                                        setshowFirstnamelabel(false);
                                    }}
                                    onChange={(e) => setfName(e.target.value)}
                                    onFocus={() => setshowFirstnamelabel(true)}
                                    placeholder="First name"
                                />
                            </div>

                            <div className="mt-3 w-full relative flex flex-col gap-1 bg-[#ECEFF3] h-14 rounded-lg p-2 justify-center">
                                {showlastnamelabel && (
                                    <label
                                        htmlFor="Email"
                                        className={`text-xs font-semibold leading-[16.2px] ${
                                            changecolorforlabelpassword
                                                ? "text-[#6F00FF]"
                                                : "text-[#667085]"
                                        }`}
                                    >
                                        Last name
                                    </label>
                                )}
                                <input
                                    type="text"
                                    className="w-full border-0 shadow-none outline-none text-[#667085] text-base bg-[#ECEFF3] rounded-lg placeholder-gray-400"
                                    value={lname}
                                    required
                                    onKeyPress={() => setchangecolorforlabelpassword(true)}
                                    onBlur={() => {
                                        setchangecolorforlabelpassword(false);
                                        setshowLastnamelabel(false);
                                    }}
                                    onChange={(e) => setlName(e.target.value)}
                                    onFocus={() => setshowLastnamelabel(true)}
                                    placeholder="Last name"
                                />
                            </div>

                            <div className="flex gap-3 my-3 items-center">
                                <div className="flex items-center p-2 h-14 gap-2 rounded-lg bg-[#ECEFF3] col-3 relative">
                                    <img src={countryflag} alt="Country Flag" width={18} />
                                    <div
                                        className="bg-[#ECEFF3] text-base text-[#101828] cursor-pointer"
                                        onClick={handleShow}
                                        data-toggle="modal"
                                        data-target="#exampleModal"
                                    >
                                        {countrycode}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-1 justify-center h-14 w-full px-2 rounded-lg bg-[#ECEFF3] relative">
                                    {showphone && (
                                        <label
                                            htmlFor="Email"
                                            className={`text-xs font-semibold leading-[16.2px] ${
                                                changecolorforlabelnumber
                                                    ? "text-[#6F00FF]"
                                                    : "text-[#667085]"
                                            }`}
                                        >
                                            Phone number
                                        </label>
                                    )}
                                    <input
                                        type="tel"
                                        className="w-full border-0 shadow-none outline-none text-[#667085] text-base bg-[#ECEFF3] placeholder-gray-400"
                                        value={phone}
                                        required
                                        maxLength={10}
                                        minLength={10}
                                        onKeyDown={(e) => {
                                            if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                                                e.preventDefault();
                                            }
                                            setchangecolorforlabelnumber(true);
                                        }}
                                        onBlur={() => {
                                            setchangecolorforlabelnumber(false);
                                            setshowphone(false);
                                        }}
                                        onChange={(e) => {
                                            let inputValue = e.target.value;
                                            if (/^\d*$/.test(inputValue)) {
                                                if (inputValue.startsWith("0")) {
                                                    inputValue = inputValue.substring(1);
                                                }
                                                setphone(inputValue);
                                            }
                                        }}
                                        onFocus={() => setshowphone(true)}
                                        placeholder="e.g. 9012345678"
                                    />
                                </div>
                            </div>

                            <button
                                disabled={loader}
                                type="submit"
                                className={`!mt-5 flex justify-center items-center w-full !max-w-[270px] text-white text-base bg-[#6F00FF] rounded-lg !px-6 !py-4 ${
                                    loader
                                        ? "opacity-50 cursor-not-allowed"
                                        : "hover:bg-[#5800CC] cursor-pointer"
                                }`}
                            >
                                {loader ? <Loaders data={loader} /> : "Continue"}
                            </button>
                        </form>
                    </div>
                </div>{" "}
            </div>
        </div>
    );
};

export default PersonalInformation;
